<template>
	<div>
		<v-card class="mx-auto" outlined>
			<v-list-item three-line>
				<v-list-item-content>
					<v-list-item-title class="headline mb-1"
						>Insolvency Practitioner</v-list-item-title
					>
					<v-list-item-subtitle
						>List Businesses and Distressed Assets. Receive Interest from Interested Parties</v-list-item-subtitle
					>
				</v-list-item-content>

				<v-list-item-avatar tile size="50">
					<img
						src="@/assets/icons/seller_add post cropped.svg"
						alt="Register Insolvency Practitioner"
					/>
				</v-list-item-avatar>
			</v-list-item>

			<v-card-actions>
				<v-btn small color="info" :to="{ name: 'RegisterSeller' }"
					>Register</v-btn
				>
			</v-card-actions>
		</v-card>
	</div>
</template>


<script>
export default {
	name: "registerSellerCard"
};
</script>