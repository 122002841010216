<template>
	<div>
		<v-row>
			<v-col
				cols="12"
				md="6"
				xl="4"
				:key="expressionOfInterest.businessId"
				v-for="expressionOfInterest in expressions"
			>
				<expressionOfInterest :interest="expressionOfInterest" />
			</v-col>
		</v-row>
	</div>
</template>

<script>
import expressionOfInterest from "./expressionOfInterest.vue";

export default {
	name: "expressionOfInterestList",
	components: {
		expressionOfInterest
	},
	props: ["expressions"]
};
</script>