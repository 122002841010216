<template>
	<div v-if="dismissed == false">
		<v-card class="mx-auto" outlined>
			<v-list-item three-line>
				<v-list-item-content>
					<v-list-item-title class="headline mb-1"
						>Optional Criteria</v-list-item-title
					>
					<v-list-item-subtitle
						>You have not specified any optional criteria. Select from below to refine your matches.</v-list-item-subtitle
					>
				</v-list-item-content>
			</v-list-item>

			<v-card-actions>
				<v-btn small color="info" :to="{ name: 'AmendCategoryPreferences' }"
					>Categories</v-btn
				>
                <v-btn small color="info" :to="{ name: 'AmendKeywordPreferences' }"
					>Keywords</v-btn
				>
                <v-btn small color="info" :to="{ name: 'AmendAssetPreferences' }"
					>Assets</v-btn
				>
                <v-btn small color="secondary" v-on:click="dismiss"
					>Dismiss</v-btn
				>
			</v-card-actions>
		</v-card>
	</div>
</template>
<script>
export default {
    name: "noCriteriaSet",
    methods: {
        dismiss() {
            localStorage.setItem("showNoCriteria", false);
            this.dismissed = true;
        },
    },
    data() {
        return {
                dismissed: false
            }
        },
    
}
</script>