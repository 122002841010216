<template>
	<div>
		<v-hover>
			<template v-slot:default="{ hover }">
				<v-card
					max-width="450"
					class="mx-auto"
					outlined
					:elevation="hover ? 6 : 0"
				>
					<v-list-item three-line>
						<v-list-item-avatar tile size="50">
							<img src="@/assets/icons/misc_send.svg" />
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title>
								<strong>{{
									interest.activityHeading
								}}</strong></v-list-item-title
							>
							<v-list-item-subtitle>
								{{
									interest.primaryCategory
								}}</v-list-item-subtitle
							>
							<v-list-item-subtitle>
								Expressed
								{{
									interest.dateExpressed | formatDate
								}}</v-list-item-subtitle
							>
						</v-list-item-content>
					</v-list-item>
					<v-divider></v-divider>
					<v-card-actions>
						<v-btn
							block
							small
							color="info"
							:to="{
								name: 'PostDetail',
								params: { id: interest.businessId }
							}"
							>View Post Detail</v-btn
						>
					</v-card-actions>
				</v-card>
			</template>
		</v-hover>
	</div>
</template>

<script>
import moment from "moment";

export default {
	name: "interestList",
	props: ["interest"],
	methods: {
		goToDetail(postId) {
			this.$router.push({ name: "PostDetail", params: { id: postId } });
		}
	},
	filters: {
		formatDate(date) {
			return moment(date).format("DD MMMM YYYY");
		}
	}
};
</script>