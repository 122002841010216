<template>
	<div class="categoryPicker">
		<v-row>
			<div v-if="showSelected" class="ma-1 pa-2">
				<strong>Selected Categor{{ radio ? "y" : "ies" }}</strong
				><span> | </span>
				<a class="clearAll" @click="clearAll" v-if="!radio"
					>Clear All</a
				>
				<div :class="this.selected.length > 5 && 'selectedBox'">
					<div v-for="parent in labels" :key="parent.categoryId">
						<span
							v-for="child in parent.childCategories"
							:key="child.categoryId"
						>
							<v-chip
								v-if="radio && selected === child.categoryId"
								>{{ child.description }}</v-chip
							>
							<v-chip
								class="mx-1"
								small
								v-if="
									!radio &&
										selected.includes(child.categoryId)
								"
								@click:close="
									removeFromSelected(child.categoryId)
								"
								close
								>{{ child.description }}</v-chip
							>
						</span>
					</div>
				</div>
			</div>
		</v-row>

		<v-row>
			<div v-if="showParent" class="ma-1 pa-2">
				<strong>Parent Sectors</strong>
				<v-row>
					<v-col cols="12" md="6">
						<span
							v-for="parent in firstHalfOfLabels"
							:key="parent.categoryId"
						>
							<v-divider></v-divider>
							<span
								@click="
									open(
										parent.childCategories,
										parent.description
									)
								"
							>
								<a>{{ parent.description }}</a>
							</span>
						</span>
					</v-col>
					<v-col cols="12" md="6" class="my-0 py-0 py-md-1 my-md-1">
						<span
							v-for="parent in secondHalfOfLabels"
							:key="parent.categoryId"
						>
							<v-divider></v-divider>
							<span
								@click="
									open(
										parent.childCategories,
										parent.description
									)
								"
							>
								<a>{{ parent.description }}</a>
							</span>
						</span>
					</v-col>
				</v-row>
			</div>

			<div v-if="!showParent">
				<v-btn
					tile
					color="accent"
					@click="showParent = !showParent"
					text
					><v-icon>mdi-chevron-left</v-icon>Back</v-btn
				>
				<childCategory
					:preSelected="selected"
					:childCategories="catsToShow"
					v-model="selected"
					v-bind="$attrs"
					v-on="listeners"
					:parentName="parentName"
					:radio="radio"
					:exclude="exclude"
				/>
			</div>
		</v-row>
	</div>
</template>

<script>
import Repository from "@/store/Services/Repository.js";
import childCategory from "./childCategory.vue";

export default {
	name: "businessCategoryPicker",
	inheritAttrs: false,
	props: ["showSelected", "existing", "radio", "exclude"],
	components: {
		childCategory
	},
	data() {
		return {
			showParent: true,
			labels: null,
			selected: [],
			search: null,
			catsToShow: {},
			parentName: null
		};
	},
	async created() {
		await Repository.get("label/nestedcategories")
			.then(response => (this.labels = response.data.categoryLabels))
			.catch(e => e);

		this.selected = this.existing;
	},
	// This is responsible for an auto bug, but the component will not function properly without it
	updated() {
		this.listeners();
	},

	methods: {
		open(cats, parentName) {
			this.showParent = false;
			this.catsToShow = cats;
			this.parentName = parentName;
		},
		removeFromSelected(category) {
			this.selected.splice(this.selected.indexOf(category), 1);
		},
		clearAll() {
			if (this.selected !== null || this.selected.length > 0)
				this.selected.splice(0, this.selected.length);
		}
	},
	computed: {
		listeners() {
			return {
				...this.$listeners,
				input: this.$emit("input", this.selected)
			};
		},
		firstHalfOfLabels() {
			return (
				this.labels &&
				this.labels.slice(0, Math.ceil(this.labels.length / 2))
			);
		},
		secondHalfOfLabels() {
			return (
				this.labels &&
				this.labels.slice(
					Math.ceil(this.labels.length / 2),
					this.labels.length
				)
			);
		}
	}
};
</script>

<style lang="sass" scoped>
.categoryPicker
	text-align: justify

	.clearAll
		font-size: .9em
		color: #ec5151

	.selectedBox 
		max-height: 130px		
		overflow-y: scroll


</style>