var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [[_c('v-card',{staticClass:"card mx-auto",attrs:{"max-width":_vm.cardWidth,"elevation":hover ? 6 : 0,"tile":"","outlined":""}},[_c('v-list-item',{staticStyle:{"background":"#1e3354"},attrs:{"three-line":""}},[_c('v-list-item-avatar',{attrs:{"tile":"","size":"50"}},[_c('img',{attrs:{"src":require("@/assets/icons/widget.svg")}})]),_c('v-list-item-content',{staticStyle:{"overflow":"hidden"}},[_c('v-list-item-title',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.post.reference))]),_c('v-list-item-subtitle',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.sellerPostSubtitle))]),_c('v-list-item-subtitle',{staticStyle:{"color":"white"}},[_vm._v(" ID - "+_vm._s(_vm.post.businessId))])],1)],1),_c('v-list-item',[_vm._v(" Category"),_c('v-spacer'),(_vm.post.primaryCategory.length > 0)?_c('v-chip',{staticClass:"ma-2",staticStyle:{"overflow-x":"hidden"},attrs:{"small":""}},[_vm._v(_vm._s(_vm.post.primaryCategory[0].description))]):_vm._e()],1),_c('v-divider'),_c('v-list-item',[_vm._v(" Turnover"),_c('v-spacer'),_c('v-chip',{staticClass:"ma-2",attrs:{"small":""}},[_vm._v(_vm._s(_vm.post.turnover))])],1),_c('v-divider'),_c('v-list-item',[_vm._v(" Heading"),_c('v-spacer'),_c('v-chip',{staticClass:"ma-2",attrs:{"small":""}},[_vm._v(_vm._s(_vm.post.activityHeading))])],1),_c('v-divider'),_c('v-card-actions',[_c('v-flex',[_c('v-btn-toggle',{staticClass:"d-flex",attrs:{"tile":""}},[_c('v-btn',{attrs:{"width":"50%","block":_vm.post.siteStatus === 'For Sale',"to":{
									name: 'PostDetail',
									params: { id: _vm.post.businessId }
								},"small":"","color":"secondary"}},[_vm._v("View Detail")]),(_vm.post.siteStatus === 'Draft')?_c('v-btn',{attrs:{"width":"50%","to":{
									name: 'PostDraft',
									params: { id: _vm.post.businessId }
								},"small":"","color":"info"}},[_vm._v("Post Draft")]):_vm._e(),(_vm.post.siteStatus === 'Scheduled')?_c('v-btn',{attrs:{"width":"50%","to":{
									name: 'ExtendPost',
									params: { id: _vm.post.businessId }
								},"small":"","color":"info"}},[_vm._v("Modify End Date")]):_vm._e(),(
									_vm.post.siteStatus === 'Expired' ||
										_vm.post.siteStatus === 'Withdrawn'
								)?_c('v-btn',{attrs:{"width":"50%","to":{
									name: 'Repost',
									params: { id: _vm.post.businessId }
								},"small":"","color":"info"}},[_vm._v("Repost")]):_vm._e()],1)],1)],1)],1)]]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }