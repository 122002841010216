<template>
	<div class="container">
		<h2>Certificates</h2>
		
		<v-pagination
			v-model="page"
			:length="Math.max(numberOfPosts / itemsPerPage, 1)"
			color="accent"
		></v-pagination>

		<v-row justify="center">
			<v-col cols="auto">
				<v-select
					:items="postsFromXDaysAgoItems"
					label="Posts Created In The Last x Days"
					v-model="postsFromXDaysAgo"
				></v-select>
			</v-col>
			<v-col cols="auto">
				<v-select
					:items="pageSizes"
					label="Items per page"
					v-model="itemsPerPage"
				></v-select>
			</v-col>
			<v-col cols="auto">
				<v-select
					v-model="sortBy"
					:items="sortByItems"
					item-text="label"
					item-value="key"
					prepend-inner-icon="mdi-magnify"
					label="Sort By"
				></v-select>
			</v-col>
			<v-col cols="auto">
				<v-btn-toggle v-model="sortDesc" mandatory>
					<v-btn depressed :value="false">
						<v-icon>mdi-arrow-up</v-icon>
					</v-btn>
					<v-btn depressed :value="true">
						<v-icon>mdi-arrow-down</v-icon>
					</v-btn>
				</v-btn-toggle>
			</v-col>
		</v-row>
		<v-data-iterator
			:items="admin.certificateList"
			:items-per-page="itemsPerPage"
			:page="page"
			:sort-by="sortBy"
			:sort-desc="sortDesc"
			item-key="periodId"
			hide-default-footer
		>
			<template v-slot:default="props">
				<v-row>
					<v-col
						v-for="certificate in props.items"
						:key="certificate.period.periodId"
						cols="12"
						sm="6"
						lg="4"
						xl="3"
					>
						<v-card>
							<v-list dense>
								<v-list-item>
									<strong>Business ID</strong>
									<v-spacer></v-spacer>
									{{ certificate.period.invoice.businessId }}
								</v-list-item>
								<v-list-item>
									<strong>Post Start Date</strong>
									<v-spacer></v-spacer>
									{{ certificate.period.startDate | formatDate }}
								</v-list-item>
								<v-list-item>
									<strong>Post End Date</strong>
									<v-spacer></v-spacer>
									{{ certificate.period.endDate | formatDate }}
								</v-list-item>
								<v-list-item>
									<strong>No of interested parties notified by email:</strong>
									<v-spacer></v-spacer>
									{{ certificate.period.totalNoOfInterestedPartiesEmailed }}
								</v-list-item>
								<v-list-item>
									<strong>Number of expressions of interest received:</strong>
									<v-spacer></v-spacer>
									{{ certificate.expressedInterestsCount }}
								</v-list-item>
							</v-list>
							<v-card-actions>
								<v-btn
									color="secondary"
									:to="{
										name: 'CertificateOutput',
										params: {
											id: certificate.period.periodId
										}
									}"
									block
									>View Formatted Certificate</v-btn
								>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</template>
		</v-data-iterator>
		<v-pagination
			v-model="page"
			:length="Math.max(numberOfPosts / itemsPerPage, 1)"
			color="accent"
		></v-pagination>
	</div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
export default {
	name: "CertificateList",
	data() {
		return {
			page: 1,
			itemsPerPage: 25,
			pageSizes: [5, 10, 15, 25, 50],
			sortBy: "created",
			sortByItems: [
				{ label: "Created", key: "created" },
				{ label: "Post Start Date", key: "listingStartDate" },
				{ label: "Post End Date", key: "listingEndDate" },
				{ label: "Total Price", key: "totalPrice" },
				{ label: "Total Days Live", key: "totalDays" }
			],
			sortDesc: true,
			postsFromXDaysAgoItems: [1, 3, 7, 14, 28, 90, 365],
			postsFromXDaysAgo: 14
		};
	},

	async created() {
		await this.$store.dispatch(
			"retrieveCertificateList",
			this.postsFromXDaysAgo
		);
	},
	computed: {
		...mapState(["admin"]),
		numberOfPosts() {
			return this.admin.certificateList && this.admin.certificateList.length;
		}
	},
	filters: {
		formatDate(date) {
			return moment(date).format("DD MMMM YYYY");
		}
	},
	watch: {
		postsFromXDaysAgo(newVal) {
			this.$store.dispatch("retrieveCertificateList", newVal);
		}
	}
};
</script>