<template>
	<div class="container">
		<v-btn
			tile
			small
			color="primary"
			text
			:to="{
				name: 'PostDetail'
			}"
			><v-icon>mdi-chevron-left</v-icon> to post</v-btn
		>
		<h2>Amend Post Detail</h2>
		<v-form v-show="form !== {}">
			<v-row
				style="background: white; borderRadius: 3px"
				class="my-2 mx-1 pa-2"
			>
				<v-col cols="12" md="6">
					<v-text-field
						label="Reference"
						v-model="form.reference"
						:error-messages="
							!form.reference && 'Please enter a Reference'
						"
					></v-text-field>

					<v-text-field
						label="Activity Heading"
						v-model="form.activityHeading"
						:error-messages="
							!form.activityHeading &&
								'Please enter an Activity Heading'
						"
					></v-text-field>

					<v-select
						:items="newPostLabels.locationLabels"
						item-text="description"
						item-value="locationId"
						v-model="form.locations"
						:error-messages="
							form.locations.length === 0 &&
								'Please select at least one location'
						"
						label="Locations"
						multiple
						chips
					></v-select>

					<v-select
						:items="newPostLabels.appointmentStatusLabels"
						item-text="description"
						item-value="businessStatusId"
						v-model="form.businessStatusID"
						label="Appointment Status"
						chips
					></v-select>

					<v-list-item>
						<strong>Display Publicly?</strong>
						<v-spacer></v-spacer>

						<v-btn-toggle
							v-model="form.displayHome"
							mandatory
							:color="
								form.displayHome
									? 'info'
									: 'var(--v-error-base)'
							"
						>
							<v-btn small :value="false">
								No
							</v-btn>
							<v-btn small :value="true">
								Yes
							</v-btn>
						</v-btn-toggle>
					</v-list-item>
				</v-col>
				<v-col cols="12" md="6">
					<v-select
						:items="newPostLabels.tradingStatusLabels"
						item-text="description"
						item-value="tradingStatusId"
						v-model="form.tradingStatusID"
						label="Trading Status"
						chips
					></v-select>

					<v-select
						:items="newPostLabels.turnoverLabels"
						item-text="description"
						item-value="turnoverId"
						v-model="form.turnoverID"
						label="Turnover"
						chips
					></v-select>

					<v-select
						:items="newPostLabels.employeeFields"
						item-text="description"
						item-value="employeesId"
						v-model="form.employeeID"
						label="Employees"
						chips
					></v-select>
				</v-col>
			</v-row>
			<v-btn
				tile
				color="secondary"
				:loading="loading"
				@click.prevent="saveChanges"
				>Save Changes</v-btn
			>
		</v-form>

		<p v-if="successMessage !== null">{{ successMessage }}</p>
		<p v-if="errorMessage !== null">{{ errorMessage }}</p>
	</div>
</template>

<script>
import Repository from "@/store/Services/Repository.js";
import { mapGetters } from "vuex";

export default {
	name: "AmendPostDetail",
	metaInfo() {
		return {
			title: "IP-BID.com - Amend Posting Detail"
		};
	},
	data() {
		return {
			form: {
				reference: "",
				activityHeading: "",
				locations: [],
				businessStatusID: null,
				tradingStatusID: null,
				turnoverID: null,
				employeeID: null,
				displayHome: null
			},
			successMessage: null,
			errorMessage: null,
			loading: false,
			newPostLabels: {}
		};
	},
	async created() {
		await Repository.get("label/newpost").then(response => {
			this.newPostLabels = response.data;
		});

		if (
			this.loadedPost === null ||
			this.loadedPost.businessId !== this.$route.params.id
		) {
			await this.$store.dispatch(
				"retrievePostByID",
				this.$route.params.id
			);
		}

		//Initialise Form Values
		this.form.reference = this.loadedPost.reference;
		this.form.activityHeading = this.loadedPost.activityHeading;
		this.form.locations = this.loadedPost.locations.map(
			location => location.locationId
		);
		this.form.businessStatusID = this.loadedPost.businessStatusID;
		this.form.tradingStatusID = this.loadedPost.tradingStatusID;
		this.form.turnoverID = this.loadedPost.turnoverID;
		this.form.employeeID = this.loadedPost.employeeID;
		this.form.displayHome = this.loadedPost.displayHome;
	},
	computed: {
		//...mapState(["labels"]),
		...mapGetters(["loadedPost"]),
		formHasErrors() {
			return (
				this.form.locations.length === 0 ||
				!this.form.activityHeading ||
				!this.form.reference
			);
		}
	},
	methods: {
		async saveChanges() {
			if (!this.formHasErrors) {
				this.loading = true;

				const payload = {
					id: this.$route.params.id,
					form: this.form
				};

				await this.$store
					.dispatch("amendPostDetail", payload)
					.then(response => {
						response;
						this.$router.push({
							name: "PostDetail"
						});
					})
					.catch(e => (this.errorMessage = e));

				this.loading = false;
			}
		}
	}
};
</script>