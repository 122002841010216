<template>
	<div class="container">
		<v-row>
			<v-col cols="12" md="6">
				<form>
					<v-text-field
						v-if="successMessage === null"
						v-model="passwords.password"
						label="Password"
						:error-messages="passwordErrors"
						password
						:type="showPass ? 'text' : 'password'"
						:append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
						@click:append="showPass = !showPass"
						required
					></v-text-field>
					<v-text-field
						v-if="successMessage === null"
						v-model="passwords.confirmPassword"
						label="Confirm Password"
						:error-messages="confirmPasswordErrors"
						password
						:type="showConfirmPass ? 'text' : 'password'"
						:append-icon="
							showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'
						"
						@click:append="showConfirmPass = !showConfirmPass"
						required
					></v-text-field>
					<v-btn
						v-if="successMessage === null"
						color="secondary"
						tile
						:loading="loading"
						@click.prevent="resetPass"
						>submit</v-btn
					>
					<p v-if="errorMessage !== null">{{ errorMessage }}</p>
				</form>
			</v-col>
		</v-row>
		<savedAnimation
			:text="successMessage"
			:showTick="true"
			:triggered="triggerSaveText"
		/>

		<v-btn
			v-if="successMessage != null"
			tile
			color="secondary"
			:to="{ name: 'Login' }"
			>Login With New Password</v-btn
		>
	</div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import savedAnimation from "@/components/savedAnimation.vue";

const containsDigit = value => /\d/.test(value);
const containsUppercase = value => /[A-Z]/.test(value);
const containsLowercase = value => /[a-z]/.test(value);

export default {
	name: "ResetPass",
	mixins: [validationMixin],
	components: {
		savedAnimation
	},
	data() {
		return {
			passwords: {
				password: null,
				confirmPassword: null
			},
			loading: false,
			showPass: false,
			showConfirmPass: false,
			successMessage: null,
			errorMessage: null,
			triggerSaveText: false
		};
	},
	validations: {
		passwords: {
			password: {
				required,
				minLength: minLength(7),
				containsDigit,
				containsLowercase,
				containsUppercase
				//Add non alphanumeric validation
			},
			confirmPassword: {
				required,
				sameAsPassword: sameAs("password")
			}
		}
	},
	methods: {
		async resetPass() {
			this.loading = true;

			this.errorMessage = null;

			if (!this.$v.passwords.$invalid) {
				const payload = {
					passwords: this.passwords,
					emailParam: this.$route.query.email ?? "",
					tokenParam: this.$route.query.token ?? ""
				};
				await this.$store
					.dispatch("resetPassword", payload)
					.then(response => {
						this.successMessage = response.response;
						this.triggerSaveText = true;
					})
					.catch(e => (this.errorMessage = e));
			} else {
				this.$v.passwords.$touch();
			}

			this.loading = false;

			await new Promise(resolve => setTimeout(resolve, 2800)); //Length of Animation
			this.triggerSaveText = false;
		}
	},
	computed: {
		passwordErrors() {
			const errors = [];
			if (!this.$v.passwords.password.$dirty) return errors;
			!this.$v.passwords.password.required &&
				errors.push("Please Enter a Password");
			!this.$v.passwords.password.containsDigit &&
				errors.push("Password Must Contain a Digit");
			!this.$v.passwords.password.containsUppercase &&
				errors.push("Password Must Contain an Uppercase Character");
			!this.$v.passwords.password.containsLowercase &&
				errors.push("Password Must Contain an Lowercase Character");
			!this.$v.passwords.password.minLength &&
				errors.push("Password must contain at least 7 characters");
			return errors;
		},
		confirmPasswordErrors() {
			const errors = [];
			if (!this.$v.passwords.confirmPassword.$dirty) return errors;
			!this.$v.passwords.confirmPassword.required &&
				errors.push("Please Enter a Confirm Password");
			!this.$v.passwords.confirmPassword.sameAsPassword &&
				errors.push("Passwords must match");
			return errors;
		}
	}
};
</script>
