<template>
	<div class="container">
		<h2>Administrate Users</h2>
		<v-pagination
			class="ma-2"
			style="justifyContent: left"
			v-model="paginationSettings.page"
			:length="admin.userList.roundedPageCount"
			color="accent"
			@input="refreshData"
			total-visible="10"
		></v-pagination>
		<v-row>
			<v-col cols="auto" md="4">
				<v-text-field
					append-icon="mdi-magnify"
					label="Search this page"
					v-model="searchTerm"
				></v-text-field>
			</v-col>
			<v-col cols="3">
				<v-select
					:items="paginationSettings.pageSizeItems"
					v-model="paginationSettings.pageSize"
					label="Users Per Page"
				></v-select>
			</v-col>
			<v-col cols="auto">
				<v-card class="d-flex pa-2 ma-2" flat outlined>
					<span class="mx-2"><strong> Global Filters</strong></span>
					<v-divider vertical class="mx-2"></v-divider>
					<a
						@click="setFilterThenRefresh(false, true)"
						class="mx-2"
						:style="
							!filterSellers ? 'color: grey' : 'color: #86ae41'
						"
					>
						Sellers Only
					</a>
					<a
						@click="setFilterThenRefresh(true, false)"
						class="mx-2"
						:style="
							!filterBuyers ? 'color: grey' : 'color: #86ae41'
						"
					>
						Buyers Only
					</a>
				</v-card>
			</v-col>
		</v-row>

		<userTable
			:searchTerm="searchTerm"
			:page="paginationSettings.page"
			:pageSize="paginationSettings.pageSize"
			:data="admin.userList.usersList"
			:filterSellers="filterSellers"
			:filterBuyers="filterBuyers"
		/>

		<v-pagination
			class="py-8"
			style="justifyContent: left"
			v-model="paginationSettings.page"
			:length="admin.userList.roundedPageCount"
			color="accent"
			@input="refreshData"
			total-visible="10"
		></v-pagination>
	</div>
</template>

<script>
import userTable from "@/components/Admin Components/userTable.vue";
import { mapState } from "vuex";

export default {
	name: "AdminUsers",
	components: {
		userTable
	},
	data() {
		return {
			paginationSettings: {
				pageSizeItems: [10, 25, 50, 100, 250, 500],
				page: 1,
				pageSize: 50
			},
			searchTerm: "",
			filterSellers: true,
			filterBuyers: false,
			loading: false
		};
	},
	created() {
		const payload = {
			page: this.paginationSettings.page,
			pageSize: this.paginationSettings.pageSize,
			filterSellers: this.filterSellers,
			filterBuyers: this.filterBuyers
		};

		this.$store.dispatch("retrieveUsers", payload);
	},
	computed: {
		...mapState(["admin"]),
		computedPageSize() {
			return this.paginationSettings.pageSize;
		}
	},
	methods: {
		setFilterThenRefresh(setBuyer, setSeller) {
			this.filterBuyers = setBuyer;
			this.filterSellers = setSeller;

			this.refreshData();
		},
		async refreshData() {
			this.loading = true;

			const payload = {
				page: this.paginationSettings.page,
				pageSize: this.paginationSettings.pageSize,
				filterSellers: this.filterSellers,
				filterBuyers: this.filterBuyers
			};

			await this.$store
				.dispatch("retrieveUsers", payload)
				.then((this.loading = false));

			//this.loading = false;
		}
	},
	watch: {
		computedPageSize() {
			this.refreshData();
		}
	}
};
</script>
