<template>
	<div class="container">
		<v-btn
			tile
			small
			color="primary"
			text
			:to="{
				name: 'PostDetail'
			}"
			><v-icon>mdi-chevron-left</v-icon> to post</v-btn
		>
		<h2>Repost</h2>
		<v-row justify="space-between">
			<v-col cols="12" md="4">
				<!--
				<v-date-picker
					v-model="dateRange"
					range
					:min="
						dateRange[1] ? todaysDate : dateRange[0] || todaysDate
					"
				></v-date-picker> 
				
				-->

				<v-menu
					v-model="startMenu"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					min-width="290px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							:value="formattedDate(startDate)"
							label="Post Start Date"
							readonly
							color="accent"
							:append-icon="'mdi-pencil'"
							v-bind="attrs"
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker
						v-model="startDate"
						:min="todaysDate"
						:max="endDate"
						@input="startMenu = false"
					></v-date-picker>
				</v-menu>

				<v-menu
					v-model="endMenu"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					min-width="290px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							:value="formattedDate(endDate)"
							label="Post End Date"
							readonly
							color="accent"
							:append-icon="'mdi-pencil'"
							v-bind="attrs"
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker
						v-model="endDate"
						:min="startDate"
						@input="endMenu = false"
					></v-date-picker>
				</v-menu>

				<v-col cols="12">
					Would you like the invoice to be addressed to a different
					company name?
					<v-btn-toggle
						v-model="useCustomCompanyName"
						mandatory
						:color="
							useCustomCompanyName
								? 'info'
								: 'var(--v-error-base)'
						"
					>
						<v-btn small :value="false">
							No
						</v-btn>
						<v-btn small :value="true">
							Yes
						</v-btn>
					</v-btn-toggle>
				</v-col>
				<v-text-field
					v-model="userDetails.myAccountDetails.company"
					:disabled="!useCustomCompanyName"
					counter
					maxlength="50"
				></v-text-field> </v-col
			><v-col cols="12" md="6">
				<invoiceCalculator
					:dateRange="dateRange"
					:price="
						userDetails.myAccountDetails.customPriceRate || standardPriceRate
					"
				/>
			</v-col>
			<v-col cols="12" md="6">
				Reminder: Clause 11 of our T's & C's. By confirming a Posting on IP-BID, the User agrees to pay the appropriate fees.
			</v-col> 
		</v-row>



		<v-btn
			tile
			color="secondary"
			:disabled="!formValid"
			@click="saveChanges"
			>Repost</v-btn
		>
		<p v-if="errorMessage !== null">{{ errorMessage }}</p>
	</div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import invoiceCalculator from "@/components/Seller Components/invoiceCalculator.vue";
import moment from "moment";

// Helpers
const isEmptyOrSpaces = str => {
	return str === null || str.match(/^ *$/) !== null;
};

export default {
	name: "Repost",
	components: {
		invoiceCalculator
	},
	data() {
		return {
			startDate: null,
			endDate: null,
			startMenu: false,
			endMenu: false,
			errorMessage: null,
			useCustomCompanyName: false,
			userDetails: {}
		};
	},
	async created() {
		if (
			this.loadedPost === null ||
			this.loadedPost.businessId !== this.$route.params.id
		) {
			await this.$store.dispatch(
				"retrievePostByID",
				this.$route.params.id
			);
		}
	},
	mounted() {
		this.$store.dispatch("retrieveAccountDetails").then(response => {
			this.userDetails = response;
		});

		const date = new Date();
		this.startDate = date.toISOString().slice(0, 10);

		const endDate = date.setDate(date.getDate() + 27);
		this.endDate = new Date(endDate).toISOString().slice(0, 10);
	},
	computed: {
		...mapGetters(["loadedPost", "standardPriceRate"]),
		...mapState(["allAuth"]),
		todaysDate() {
			return new Date().toISOString().slice(0, 10);
		},
		formValid() {
			const foundationalCheck =
				this.dateRange[0] !== null && this.dateRange[1] !== null;

			if (this.useCustomCompanyName) {
				return (
					foundationalCheck &&
					!isEmptyOrSpaces(this.userDetails.myAccountDetails.company)
				);
			} else {
				return foundationalCheck;
			}
		},
		dateRange() {
			return [this.startDate, this.endDate];
		}
	},
	methods: {
		async saveChanges() {
			this.errorMessage = null;

			const payload = {
				id: this.$route.params.id,
				dates: this.dateRange,
				useCustomCompanyName: this.useCustomCompanyName,
				company: this.userDetails.myAccountDetails.company
			};

			await this.$store
				.dispatch("repostPost", payload)
				.then(response => {
					response;
					this.$router.push({
						name: "PostDetail",
						params: { id: this.$route.params.id }
					});
				})
				.catch(e => {
					this.errorMessage = e;
				});
		},
		formattedDate(date) {
			return moment(date).format("DD MMMM YYYY");
		}
	}
};
</script>