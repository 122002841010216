<template>
	<div class="container">
		<v-btn
			tile
			small
			color="primary"
			text
			:to="{
				name: 'PostDetail'
			}"
			><v-icon>mdi-chevron-left</v-icon> to post</v-btn
		>
		<h2>Email Post</h2>
		<p>Send this Post's details to up to five people.</p>
		<v-row
			style="background: white; borderRadius: 3px"
			class="my-2 mx-1 pa-4"
		>
			<v-col cols="12" md="7">
				<v-row>
					<v-col cols="12">
						<v-text-field
							color="accent"
							filled
							v-model="currentEmail"
							label="Email Address"
							:error-messages="emailErrors"
							type="email"
							append-icon="mdi-plus"
							@click:append="addEmail"
							:disabled="sendList.length === 5"
						></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-textarea
							color="accent"
							filled
							label="Message"
							rows="4"
							v-model="message"
							:error-messages="messageError"
						></v-textarea>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" md="5">
				<h3>Send List</h3>
				<p v-if="sendList.length === 0">List is empty</p>
				<div v-for="(email, index) in sendList" :key="`email-${index}`">
					<v-btn text @click="removeEmail(index)"
						><v-icon color="grey">mdi-close-circle</v-icon></v-btn
					>
					{{ email }}
				</div>
			</v-col>
		</v-row>

		<v-btn
			tile
			color="secondary"
			@click="sendEmail"
			:loading="loading"
			:disabled="triggerSaveText"
			>Send Email</v-btn
		>
		<savedAnimation
			:text="successMessage"
			:showTick="true"
			:triggered="triggerSaveText"
		/>
		<p v-if="errorMessage !== null">{{ errorMessage }}</p>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email, maxLength } from "vuelidate/lib/validators";
import savedAnimation from "@/components/savedAnimation.vue";

export default {
	name: "EmailPost",
	components: {
		savedAnimation
	},
	mixins: [validationMixin],
	data() {
		return {
			currentEmail: "",
			sendList: [],
			maxNumberOfEmails: 5,
			message: "IP-BID.com posting for review", //Default Text
			loading: false,
			successMessage: null,
			errorMessage: null,
			triggerSaveText: false
		};
	},
	validations() {
		return {
			currentEmail: { email },
			message: { required },
			sendList: { required, maxLength: maxLength(this.maxNumberOfEmails) }
		};
	},
	computed: {
		...mapState(["allAuth"]),

		//Validation
		emailErrors() {
			const errors = [];
			if (!this.$v.currentEmail.$dirty) return errors;
			!this.$v.currentEmail.email && errors.push("Email should be valid");
			!this.$v.sendList.required && errors.push("Send List empty");
			!this.$v.sendList.maxLength &&
				errors.push("Send List cannot contain more than five people");
			return errors;
		},
		messageError() {
			const errors = [];
			if (!this.$v.message.$dirty) return errors;
			!this.$v.message.required && errors.push("Message is required");
			return errors;
		}
	},
	methods: {
		addEmail() {
			//Validate email that is added to the Send List
			if (
				!this.$v.currentEmail.$invalid &&
				this.currentEmail !== "" &&
				this.sendList.length < this.maxNumberOfEmails
			) {
				this.sendList.push(this.currentEmail);
				this.currentEmail = "";
			} else {
				this.$v.currentEmail.$touch();
			}
		},
		//Remove From Send List
		removeEmail(index) {
			this.sendList.splice(index, 1);
		},
		async sendEmail() {
			this.errorMessage = null;

			if (!this.$v.$invalid) {
				this.loading = true;
				const payload = {
					id: this.$route.params.id,
					sendList: this.sendList,
					Message: this.message
				};
				await this.$store
					.dispatch("emailPost", payload)
					.then(response => {
						this.sendList = [];
						this.successMessage = response.response;
						this.triggerSaveText = true;
						this.$v.$reset();
					})
					.catch(e => (this.errorMessage = e));
				this.loading = false;
			} else {
				this.$v.$touch();
			}

			await new Promise(resolve => setTimeout(resolve, 2800)); //Length of Animation
			this.triggerSaveText = false;
		}
	}
};
</script>