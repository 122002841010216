<template>
	<div>
		<v-hover v-slot:default="{ hover }">
			<template>
				<v-card
					:max-width="cardWidth"
					class="card mx-auto"
					:elevation="hover ? 6 : 0"
					tile
					outlined
				>
					<v-list-item three-line style="background:#1e3354;">
						<v-list-item-avatar tile size="50">
							<img src="@/assets/icons/widget.svg" />
						</v-list-item-avatar>

						<v-list-item-content style="overflow: hidden">
							<v-list-item-title style="color: white">
								{{ post.reference }}</v-list-item-title
							>
							<v-list-item-subtitle style="color: white">
								{{ sellerPostSubtitle }}</v-list-item-subtitle
							>
							<v-list-item-subtitle style="color: white">
								ID - {{ post.businessId }}</v-list-item-subtitle
							>
						</v-list-item-content>
					</v-list-item>
					<v-list-item>
						Category<v-spacer></v-spacer>
						<v-chip
							style="overflow-x: hidden"
							class="ma-2"
							small
							v-if="post.primaryCategory.length > 0"
							>{{ post.primaryCategory[0].description }}</v-chip
						>
					</v-list-item>
					<v-divider></v-divider>
					<v-list-item>
						Turnover<v-spacer></v-spacer>
						<v-chip class="ma-2" small>{{ post.turnover }}</v-chip>
					</v-list-item>
					<v-divider></v-divider>
					<v-list-item>
						Heading<v-spacer></v-spacer>
						<v-chip class="ma-2" small>{{
							post.activityHeading
						}}</v-chip>
					</v-list-item>

					<v-divider></v-divider>
					<v-card-actions>
						<v-flex>
							<v-btn-toggle tile class="d-flex">
								<v-btn
									width="50%"
									:block="post.siteStatus === 'For Sale'"
									:to="{
										name: 'PostDetail',
										params: { id: post.businessId }
									}"
									small
									color="secondary"
									>View Detail</v-btn
								>
								<v-btn
									width="50%"
									:to="{
										name: 'PostDraft',
										params: { id: post.businessId }
									}"
									v-if="post.siteStatus === 'Draft'"
									small
									color="info"
									>Post Draft</v-btn
								>
								<v-btn
									width="50%"
									:to="{
										name: 'ExtendPost',
										params: { id: post.businessId }
									}"
									v-if="post.siteStatus === 'Scheduled'"
									small
									color="info"
									>Modify End Date</v-btn
								>
								<v-btn
									width="50%"
									:to="{
										name: 'Repost',
										params: { id: post.businessId }
									}"
									v-if="
										post.siteStatus === 'Expired' ||
											post.siteStatus === 'Withdrawn'
									"
									small
									color="info"
									>Repost</v-btn
								>
							</v-btn-toggle>
						</v-flex>
					</v-card-actions>
				</v-card>
			</template>
		</v-hover>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
	name: "sellerPostCard",
	props: ["post", "cardWidth"],
	data() {
		return {};
	},
	computed: {
		...mapGetters(["isLoggedIn"]),
		sellerPostSubtitle() {
			let subtitle;
			switch (this.post.siteStatus) {
				case "For Sale":
					subtitle =
						"Live " +
						this.formatDate(this.post.activeStartDate[0]) +
						" - " +
						this.formatDate(this.post.activeEndDate[0]);
					break;
				case "Scheduled":
					subtitle =
						"Live " +
						this.formatDate(this.post.activeStartDate[0]) +
						" - " +
						this.formatDate(this.post.activeEndDate[0]);
					break;
				case "Withdrawn":
					subtitle = "Withdrawn";
					break;
				case "Expired":
					subtitle =
						"Expired " +
						this.formatDate(this.post.activeEndDate[0]);
					break;
				case "Draft":
					subtitle =
						"Created " + this.formatDate(this.post.creationDate);
					break;
			}
			return subtitle;
		}
	},
	methods: {
		formatDate(date) {
			return moment(date).format("DD MMMM YYYY");
		}
	}
};
</script>

