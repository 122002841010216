<template>
	<div class="container py-12 my-12" v-if="loaded" style="fontSize: 0.9em">
		<v-speed-dial
			fixed
			bottom
			right
			style="bottom: 50px"
			class="hideOnPrint"
		>
			<template v-slot:activator>
				<v-btn color="info" fab onClick="window.print()">
					<v-icon>mdi-printer</v-icon>
				</v-btn>
			</template>
		</v-speed-dial>
		<v-row justify="space-around">
			<v-col cols="auto">
				<v-row> <strong>Invoiced To</strong></v-row>
				<v-row>
					{{ invoiceDetail.invoice.forenames }}
					{{ invoiceDetail.invoice.surname }}
				</v-row>
				<v-row>
					{{ invoiceDetail.invoice.company }}
				</v-row>
				<v-row>
					{{ invoiceDetail.invoice.addressLine1 }}
				</v-row>
				<v-row v-if="invoiceDetail.invoice.addressLine2">
					{{ invoiceDetail.invoice.addressLine2 }}
				</v-row>
				<v-row v-if="invoiceDetail.invoice.addressLine3">
					{{ invoiceDetail.invoice.addressLine3 }}
				</v-row>
				<v-row>
					{{ invoiceDetail.invoice.townCity }}
				</v-row>
				<v-row>
					{{ invoiceDetail.invoice.county }}
				</v-row>
				<v-row>
					{{ invoiceDetail.invoice.postcode }}
				</v-row>
			</v-col>
			<v-col cols="auto">
				<v-row> <strong>Invoice ID</strong></v-row>
				<v-row>
					<p>
						{{ invoiceDetail.invoice.invoiceId }}
					</p></v-row
				>
				<v-row> <strong>Post ID and Reference</strong></v-row>
				<v-row>
					<p>
						{{ invoiceDetail.invoice.businessId }} -
						{{ invoiceDetail.invoice.reference }}
					</p></v-row
				>
			</v-col>
			<v-col cols="auto">
				<v-row> <strong>Invoice Date and Tax Point</strong></v-row>
				<v-row
					><p>
						{{ invoiceDetail.invoice.createdDate | formatDate }}
					</p></v-row
				>
				<v-row><strong>Invoice Due Date</strong></v-row>
				<v-row>
					<p>
						{{
							(Date.parse(invoiceDetail.invoice.createdDate) +
								14 * 24 * 60 ** 2 * 1000)
								| formatDate
						}}
					</p>
				</v-row>
				<v-row>
					<strong>VAT Registration No.</strong>
				</v-row>
				<v-row>
					<p>GB 937 7352 90</p>
				</v-row>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-simple-table>
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-left">Description</th>
								<th class="text-left">Unit Cost</th>
								<th class="text-left">Qty</th>
								<th class="text-left">Amount</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>First 28 Days</td>
								<td>
									£
									{{
										invoiceDetail.invoice.priceRate.toFixed(
											2
										)
									}}
								</td>
								<td>
									{{
										invoiceDetail.calculatedInformation
											.first28Q
									}}
								</td>
								<td>
									£
									{{
										invoiceDetail.calculatedInformation.first28Amount.toFixed(
											2
										)
									}}
								</td>
							</tr>
							<tr
								v-if="
									invoiceDetail.calculatedInformation
										.further28Q > 0
								"
							>
								<td>Further 28 Days</td>
								<td>
									£
									{{
										invoiceDetail.invoice.priceRate.toFixed(
											2
										)
									}}
								</td>
								<td>
									{{
										invoiceDetail.calculatedInformation
											.further28Q
									}}
								</td>
								<td>
									£
									{{
										invoiceDetail.calculatedInformation.further28Amount.toFixed(
											2
										)
									}}
								</td>
							</tr>
							<tr></tr>
						</tbody>
						<tbody>
							<tr>
								<td></td>
								<td></td>
								<td>Subtotal</td>
								<td>
									£
									{{
										invoiceDetail.calculatedInformation.subTotal.toFixed(
											2
										)
									}}
								</td>
							</tr>
							<tr>
								<td></td>
								<td></td>
								<td>VAT @ 20%</td>
								<td>
									£
									{{
										invoiceDetail.calculatedInformation.vatAmount.toFixed(
											2
										)
									}}
								</td>
							</tr>
							<tr>
								<td></td>
								<td></td>
								<td><strong>Total</strong></td>
								<td>
									<strong
										>£
										{{
											invoiceDetail.calculatedInformation.totalPrice.toFixed(
												2
											)
										}}</strong
									>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-col>
		</v-row>
		<v-col cols="12" class="px-12">
			<v-row>
				<v-col cols="12">
					Terms of Payment: 14 Days from date of invoice
				</v-col>
				<v-col cols="12">
					<strong
						>Please remember to quote invoice number when paying
						electronically
					</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="auto" md="auto">
					Payments to:
				</v-col>
				<v-col cols="auto">
					<p>
						IP-Bid Limited <br />The Control Tower<br />
						Witchcraft Way<br />
						Rackheath<br />
						Norwich<br />
						NR13 6GA
					</p>
				</v-col>
				<v-col cols="auto"> Electronic Payments:<br /> </v-col>
				<v-col>
					<p>
						HSBC <br />
						Sortcode: 40-20-08 <br />
						Acc No: 51415867 <br />Acc Name: IP Bid Ltd
					</p>
					<p></p>
				</v-col>
			</v-row>
		</v-col>
		<v-footer
			tile
			outlined
			fixed
			padless
			class="text-center"
			style="background; #eaf5fb; color: black; border-top: 0px"
		>
			<v-row style="fontSize: 0.8em"
				><v-col
					><span>IP-BID Limited</span
					><v-divider vertical class="mx-3"></v-divider>
					<span
						>The Control Tower, Witchcraft Way, Rackheath, Norwich
						NR13 6GA</span
					><v-divider vertical class="mx-3"></v-divider>
					<span>01603 561204</span
					><v-divider vertical class="mx-3"></v-divider>
					<span>finance@ip-bid.com</span>
				</v-col></v-row
			>
		</v-footer>
	</div>
</template>

<script>
import Repository from "@/store/Services/Repository.js";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
	name: "InvoiceOutput",
	data() {
		return {
			invoiceDetail: {},
			loaded: false
		};
	},
	async mounted() {
		await Repository.get("seller/invoice?id=" + this.$route.params.id, {
			headers: { Authorization: `Bearer ${this.token}` }
		}).then(response => {
			this.invoiceDetail = response.data;
			this.loaded = true;
		});
	},
	computed: {
		...mapGetters(["token"])
	},
	filters: {
		formatDate(date) {
			return moment(date).format("DD MMMM YYYY");
		}
	}
};
</script>
