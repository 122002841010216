var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"loading":_vm.loading,"hide-default-footer":"","page":_vm.page,"items-per-page":_vm.pageSize,"headers":_vm.headers,"items":_vm.data,"item-key":"userId","search":_vm.searchTerm,"custom-sort":_vm.customSort,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.lastLoginDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.lastLoginDate))+" ")]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdDate))+" ")]}},{key:"item.expressionCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.businessExpressedInterests.length)+" ")]}},{key:"item.businessCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.businessPosting.length)+" ")]}},{key:"item.capacityId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.capacityId !== null ? "Seller" : "Buyer")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.viewUser(item.userId)}}},[_vm._v(" mdi-account-search ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }