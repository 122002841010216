import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store/index.js'

//visitor views
import Login from "../views/Visitor/Login.vue";
import ForgotPass from "../views/Visitor/Forgot Pass.vue";
import ResetPass from "../views/Visitor/Reset Pass.vue";
import RegisterDirectory from "../views/Visitor/Register Directory.vue";
import RegisterSeller from "../views/Visitor/Register Seller.vue";
import RegisterBuyer from "../views/Visitor/Register Buyer.vue";
import RegistrationSuccess from "../views/Visitor/Successful Registration.vue";
import ConfirmEmail from "../views/Visitor/Confirm Email.vue";
import Help from "../views/Visitor/FAQs.vue";
import ContactUs from "../views/Visitor/Contact Us.vue";
import TCs from "../views/Visitor/TCs.vue";
import PrivacyPolicy from "../views/Visitor/Privacy Policy.vue";

//Everyone
import ConditionalHome from "../views/Conditional Home.vue";
import NotFound from "../views/Not Found.vue";
import AmendAccountDetails from "../views/All Auth/Amend Account Details.vue"
import AmendAddressDetails from "../views/All Auth/Amend Address Details.vue"

//Buyer
import PostingInbox from "../views/Buyer/Posting Inbox.vue";
import postDetail from "../components/postDetail.vue";
import ExpressedInterest from "../views/Buyer/Expressed Interest.vue";
import ExpressInterest from "../views/Buyer/Express Interest.vue";
import AmendAssetPreferences from "../views/Buyer/Amend Preference Views/Assets.vue";
import AmendCategoryPreferences from "../views/Buyer/Amend Preference Views/Categories.vue";
import AmendKeywordPreferences from "../views/Buyer/Amend Preference Views/Keywords.vue";
import AmendLocationSizePreferences from "../views/Buyer/Amend Preference Views/Location Size.vue";
//import PostsByField from "../views/Buyer/Posts By Field.vue";

//Seller
import AddPost from "../views/Seller/Add Post.vue";
import ViewInterest from "../views/Seller/Interest List.vue";
import InterestDetail from "../views/Seller/Interest Detail.vue";
import LivePosts from "../views/Seller/Live Posts.vue";
import PostingArchive from "../views/Seller/Posting Archive.vue";
import DraftPosts from "../views/Seller/Draft Posts.vue";
import ScheduledPosts from "../views/Seller/Scheduled Posts.vue";
import AmendPostAssets from "../views/Seller/Post Actions/Amend Post Assets.vue";
import AmendPostCategories from "../views/Seller/Post Actions/Amend Post Additional Categories.vue";
import AmendPostPrimaryCategory from "../views/Seller/Post Actions/Amend Post Primary Category.vue";
import AmendPostDetail from "../views/Seller/Post Actions/Amend Post Detail.vue";
import AmendPostDescription from "../views/Seller/Post Actions/Amend Post Description.vue";
import EmailPost from "../views/Seller/Post Actions/Email Post.vue";
import PostDraft from "../views/Seller/Post Actions/Post Draft.vue";
import ExtendPost from "../views/Seller/Post Actions/Extend Post.vue";
//import ModifyPostDates from "../views/Seller/Post Actions/Modify Dates.vue"; Deprecated
import WithdrawPost from "../views/Seller/Post Actions/Withdraw Post.vue";
import Repost from "../views/Seller/Post Actions/Repost.vue";
import InvoiceOutput from "../views/Seller/Invoice Output.vue";
import UserInvoices from "../views/Seller/User Invoices.vue";
import CertificateOutput from "../views/Seller/Certificate Output.vue";
import UserCertificates from "../views/Seller/User Certificates.vue";

//Admin
import AdminPosts from "../views/Admin/Admin Posts.vue"
import AdminUsers from "../views/Admin/Admin Users.vue"
import InvoiceList from "../views/Admin/Invoice List.vue"
import CertificateList from "../views/Admin/Certificate List.vue"
import UserDetail from "../views/Admin/User Detail.vue"
import Dashboards from "../views/Admin/Dashboards.vue"


Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "ConditionalHome",
		component: ConditionalHome
	},
	{
		path: "/login",
		name: "Login",
		component: Login
	},
	{
		path: "/register/",
		name: "RegisterDirectory",
		component: RegisterDirectory
	},
	{
		path: "/register/interestedparty",
		name: "RegisterBuyer",
		component: RegisterBuyer
	},
	{
		path: "/register/insolvencypractitioner",
		name: "RegisterSeller",
		component: RegisterSeller
	},
	{
		path: "/register/success",
		name: "RegistrationSuccess",
		component: RegistrationSuccess
	},
	{
		path: "/forgotpass",
		name: "ForgotPass",
		component: ForgotPass
	},
	{
		path: "/resetpass",
		name: "ResetPass",
		component: ResetPass
	},
	{
		path: "/help/faqs",
		name: "Help",
		component: Help
	},
	{
		path: "/help/contactus",
		name: "ContactUs",
		component: ContactUs
	},
	{
		path: "/terms-and-conditions",
		name: "TCs",
		component: TCs
	},
	{
		path: "/privacy-policy",
		name: "PrivacyPolicy",
		component: PrivacyPolicy
	},
	{
		path: "/confirm-email",
		name: "ConfirmEmail",
		component: ConfirmEmail
	},
	{
		path: "/not-found",
		name: "NotFound",
		component: NotFound
	},
	//ALL AUTH
	{
		path: "/user/account-details",
		name: "AmendAccountDetails",
		component: AmendAccountDetails,
		meta: { requiresLogin: true }
	},
	{
		path: "/user/account-addressphone",
		name: "AmendAddressDetails",
		component: AmendAddressDetails,
		meta: { requiresLogin: true }
	},
	{
		path: "/post/:id",
		name: "PostDetail",
		component: postDetail,
		meta: { requiresLogin: true }
	},
	//BUYER
	{
		path: "/posting-inbox/",
		name: "PostingInbox",
		component: PostingInbox,
		meta: { requiresLogin: true, roleRequired: 'Interested Party' }
	},
	{
		path: "/expressed-interest/",
		name: "ExpressedInterest",
		component: ExpressedInterest,
		meta: { requiresLogin: true, roleRequired: 'Interested Party' }
	},
	{
		path: "/post/:id/express-interest/",
		name: "ExpressInterest",
		component: ExpressInterest,
		meta: { requiresLogin: true, roleRequired: 'Interested Party' }
	},
	{
		path: "/user/assets",
		name: "AmendAssetPreferences",
		component: AmendAssetPreferences,
		meta: { requiresLogin: true, roleRequired: 'Interested Party' }
	},
	{
		path: "/user/categories",
		name: "AmendCategoryPreferences",
		component: AmendCategoryPreferences,
		meta: { requiresLogin: true, roleRequired: 'Interested Party' }
	},
	{
		path: "/user/keywords",
		name: "AmendKeywordPreferences",
		component: AmendKeywordPreferences,
		meta: { requiresLogin: true, roleRequired: 'Interested Party' }
	},
	{
		path: "/user/location-size",
		name: "AmendLocationSizePreferences",
		component: AmendLocationSizePreferences,
		meta: { requiresLogin: true, roleRequired: 'Interested Party' }
	},
	/* not MVP
	{
		path: "/posts/:field/",
		name: "PostsByField",
		component: PostsByField,
		meta: { requiresLogin: true, roleRequired: 'Interested Party' }
	},
	*/
	//SELLER
	{
		path: "/user/add-post",
		name: "AddPost",
		component: AddPost,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},
	{
		path: "/user/view-interest",
		name: "ViewInterest",
		component: ViewInterest,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},
	{
		path: "/user/view-interest/:id",
		name: "InterestDetail",
		component: InterestDetail,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},
	{
		path: "/user/live-posts",
		name: "LivePosts",
		component: LivePosts,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},
	{
		path: "/user/posting-archive",
		name: "PostingArchive",
		component: PostingArchive,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},
	{
		path: "/user/draft-posts",
		name: "DraftPosts",
		component: DraftPosts,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},
	{
		path: "/user/scheduled-posts",
		name: "ScheduledPosts",
		component: ScheduledPosts,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},
	{
		path: "/post/:id/amend-detail",
		name: "AmendPostDetail",
		component: AmendPostDetail,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},
	{
		path: "/post/:id/amend-additional-categories",
		name: "AmendPostCategories",
		component: AmendPostCategories,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},
	{
		path: "/post/:id/amend-primary-category",
		name: "AmendPostPrimaryCategory",
		component: AmendPostPrimaryCategory,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},
	{
		path: "/post/:id/amend-description",
		name: "AmendPostDescription",
		component: AmendPostDescription,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},
	{
		path: "/post/:id/amend-assets",
		name: "AmendPostAssets",
		component: AmendPostAssets,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},
	{
		path: "/post/:id/email-post",
		name: "EmailPost",
		component: EmailPost,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},
	{
		path: "/post/:id/post-draft",
		name: "PostDraft",
		component: PostDraft,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},
	{
		path: "/post/post-new",
		name: "PostWithoutDraft",
		component: PostDraft,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},
	{
		path: "/post/:id/extend",
		name: "ExtendPost",
		component: ExtendPost,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},
	/* Deprecated
	{
		path: "/post/:id/modify-dates",
		name: "ModifyPostDates",
		component: ModifyPostDates,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},
	*/
	{
		path: "/post/:id/withdraw",
		name: "WithdrawPost",
		component: WithdrawPost,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},
	{
		path: "/post/:id/repost",
		name: "Repost",
		component: Repost,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},
	{
		path: "/user/invoice/:id",
		name: "InvoiceOutput",
		component: InvoiceOutput,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},
	{
		path: "/user/invoices",
		name: "UserInvoices",
		component: UserInvoices,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},
	{
		path: "/user/certificate/:id",
		name: "CertificateOutput",
		component: CertificateOutput,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},
	{
		path: "/user/certificates",
		name: "UserCertificates",
		component: UserCertificates,
		meta: { requiresLogin: true, roleRequired: 'Insolvency Practitioner' }
	},

	//ADMIN
	{
		path: "/admin/posts",
		name: "AdminPosts",
		component: AdminPosts,
		meta: { requiresLogin: true, roleRequired: 'Admin' }
	},
	{
		path: "/admin/users",
		name: "AdminUsers",
		component: AdminUsers,
		meta: { requiresLogin: true, roleRequired: 'Admin' }
	},
	{
		path: "/admin/invoices",
		name: "InvoiceList",
		component: InvoiceList,
		meta: { requiresLogin: true, roleRequired: 'Admin' }
	},
	{
		path: "/admin/certificates",
		name: "CertificateList",
		component: CertificateList,
		meta: { requiresLogin: true, roleRequired: 'Admin' }
	},
	{
		path: "/admin/user/:id",
		name: "UserDetail",
		component: UserDetail,
		meta: { requiresLogin: true, roleRequired: 'Admin' }
	},
	{
		path: "/admin/dashboards",
		name: "Dashboards",
		component: Dashboards,
		meta: { requiresLogin: true, roleRequired: 'Admin' }
	},
];


const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		to; from; savedPosition;
		return { x: 0, y: 0 }
	}
});

router.beforeEach((to, from, next) => {
	if (!store.getters.tokenInDate && store.getters.token) {
		store.dispatch('refreshToken')
			.then(response => {
				response;
				if (to.matched.some(record => record.meta.requiresLogin)) {
					if (to.matched.some(record => record.meta.roleRequired === 'Interested Party')) {
						if (store.getters.role !== 'Interested Party') next({ name: 'ConditionalHome' });
						else next();
					}
					else if (to.matched.some(record => record.meta.roleRequired === 'Insolvency Practitioner')) {
						if (store.getters.role !== 'Insolvency Practitioner' && store.getters.role !== 'Admin') next({ name: 'ConditionalHome' });
						else next();
					}

					else if (to.matched.some(record => record.meta.roleRequired === 'Admin')) {
						if (store.getters.role !== 'Admin') next({ name: 'ConditionalHome' });
						else next();
					}
					else next();
				}
				next();
			})
			.catch(e => {
				e;
				if (to.matched.some(record => record.meta.requiresLogin)) {
					next({ name: 'Login', query: { name: to.name, id: to.params.id, reason: 'expired' } });
				} else {
					next();
				}
			})
	}
	else if (to.matched.some(record => record.meta.requiresLogin)) {
		store.getters.role === "Insolvency Practitioner" &&
			!store.getters.notificationsLoaded &&
			store.dispatch("retrieveNotificationCount");

		if (!store.getters.isLoggedIn) {
			next({ name: 'Login', query: { name: to.name, id: to.params.id } });
		}
		else if (to.matched.some(record => record.meta.roleRequired === 'Interested Party')) {
			if (store.getters.role !== 'Interested Party') next({ name: 'ConditionalHome' });
			else next();
		}
		else if (to.matched.some(record => record.meta.roleRequired === 'Insolvency Practitioner')) {
			if (store.getters.role !== 'Insolvency Practitioner' && store.getters.role !== 'Admin') next({ name: 'ConditionalHome' });
			else next();
		}

		else if (to.matched.some(record => record.meta.roleRequired === 'Admin')) {
			if (store.getters.role !== 'Admin') next({ name: 'ConditionalHome' });
			else next();
		}
		else next();
	}
	else if (!to.matched.length) {
		next({ name: 'NotFound' });
	}
	else {
		next();
	}
})


export default router;
