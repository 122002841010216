<template>
	<div class="container">
		<h2>Amend My Preferences</h2>
		<preferenceNavItems :active="'Keywords'" />
		<v-row
			style="background: white; borderRadius: 3px"
			class="my-2 mx-1 pa-4"
		>
			<v-textarea
				:loading="dataLoading"
				filled
				rows="4"
				v-model="keywords"
				:maxlength="TextCriteriaLimit"
			></v-textarea>
		</v-row>
		<v-btn
			:disabled="triggerSaveText"
			tile
			color="secondary"
			:loading="loading"
			@click.prevent="saveChanges"
			>Save Changes</v-btn
		>

		<savedAnimation
			:text="successMessage"
			:showTick="true"
			:triggered="triggerSaveText"
		/>
		<p v-if="errorMessage !== null">{{ errorMessage }}</p>
	</div>
</template>

<script>
import savedAnimation from "@/components/savedAnimation.vue";
import preferenceNavItems from "@/components/Buyer Components/preferenceNavItems.vue";
import Repository from "@/store/Services/Repository.js";
import { mapGetters } from "vuex";

export default {
	name: "AmendKeywordPreferences",
	components: {
		preferenceNavItems,
		savedAnimation
	},
	data() {
		return {
			keywords: "",
			apiError: null,
			successMessage: null,
			errorMessage: null,
			dataLoading: false,
			loading: false,
			triggerSaveText: false,
			TextCriteriaLimit: 1000
		};
	},
	async created() {
		this.dataLoading = true;

		await Repository.get("buyer/keywords", {
			headers: { Authorization: `Bearer ${this.token}` }
		})
			.then(response => {
				this.keywords = response.data.keywords;
			})
			.catch(e => {
				this.apiError = e;
			});

		this.dataLoading = false;
	},
	watch: {
		Keywords() {}
	},
	computed: {
		...mapGetters(["token"])
	},
	methods: {
		async saveChanges() {
			this.loading = true;

			this.successMessage = null;
			this.errorMessage = null;

			await Repository.put(
				"buyer/keywords",
				{ Keywords: this.keywords },
				{
					headers: { Authorization: `Bearer ${this.token}` }
				}
			)
				.then(response => {
					this.successMessage = response.data.response;
					this.triggerSaveText = true;
				})
				.catch(e => {
					this.errorMessage = e.response.data.response;
				});

			this.loading = false;

			await new Promise(resolve => setTimeout(resolve, 2800)); //Length of Animation
			this.triggerSaveText = false;
		}
	}
};
</script>