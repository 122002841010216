<template>
	<div>
		<v-hover v-slot:default="{ hover }">
			<v-alert
				border="left"
				:color="data.viewedByInsolvency ? 'dark-grey' : 'secondary'"
				:elevation="hover ? 6 : 0"
				:dense="!data.viewedByInsolvency"
				text
			>
				<v-row class="mx-0 pa-0">
					<v-col cols="12" sm="7" style="padding: 4px">
						<v-list-item-avatar
							v-show="!data.viewedByInsolvency"
							size="40"
							class="pa-0 ma-0"
						>
							<img src="@/assets/icons/misc_notifications.svg" />
						</v-list-item-avatar>
						<span
							>Interest for
							<strong>{{ data.reference }}</strong> from
							<strong>{{
								data.forenames + " " + data.surname
							}}</strong
							>, expressed
							<strong>{{
								data.createdDate | formatDate
							}}</strong></span
						>
					</v-col>
					<v-spacer></v-spacer>
					<v-col cols="auto" style="padding: 4px">
						<v-btn-toggle>
							<v-btn
								:to="{
									name: 'InterestDetail',
									params: { id: data.expressionId }
								}"
								small
								tile
								:color="
									data.viewedByInsolvency
										? 'dark-grey'
										: 'secondary'
								"
								>View Interest</v-btn
							>
							<v-btn
								:to="{
									name: 'PostDetail',
									params: { id: data.businessId }
								}"
								tile
								small
								:color="
									data.viewedByInsolvency
										? 'dark-grey'
										: 'secondary'
								"
								>View Post</v-btn
							>
						</v-btn-toggle>
					</v-col>
				</v-row>
			</v-alert>
		</v-hover>
	</div>
</template>


<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
	name: "interestCard",
	props: ["data"],
	computed: {
		...mapState(["seller"])
	},
	filters: {
		formatDate(date) {
			return moment(date).fromNow();
		}
	}
};
</script>