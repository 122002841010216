<template>
	<div class="container">
		<h2>Invoices</h2>
		<span
			>£ {{ estTotalRevenue.toFixed(2) }} Estimated Revenue from
			{{ numberOfPosts }} Posts, Created in the Last
			{{ postsFromXDaysAgo }} Days.</span
		>
		<v-pagination
			v-model="page"
			:length="Math.max(numberOfPosts / itemsPerPage, 1)"
			color="accent"
		></v-pagination>

		<v-row justify="center">
			<v-col cols="auto">
				<v-select
					:items="postsFromXDaysAgoItems"
					label="Posts Created In The Last x Days"
					v-model="postsFromXDaysAgo"
				></v-select>
			</v-col>
			<v-col cols="auto">
				<v-select
					:items="pageSizes"
					label="Items per page"
					v-model="itemsPerPage"
				></v-select>
			</v-col>
			<v-col cols="auto">
				<v-select
					v-model="sortBy"
					:items="sortByItems"
					item-text="label"
					item-value="key"
					prepend-inner-icon="mdi-magnify"
					label="Sort By"
				></v-select>
			</v-col>
			<v-col cols="auto">
				<v-btn-toggle v-model="sortDesc" mandatory>
					<v-btn depressed :value="false">
						<v-icon>mdi-arrow-up</v-icon>
					</v-btn>
					<v-btn depressed :value="true">
						<v-icon>mdi-arrow-down</v-icon>
					</v-btn>
				</v-btn-toggle>
			</v-col>
		</v-row>
		<v-data-iterator
			:items="admin.invoiceList"
			:items-per-page="itemsPerPage"
			:page="page"
			:sort-by="sortBy"
			:sort-desc="sortDesc"
			item-key="periodId"
			hide-default-footer
		>
			<template v-slot:default="props">
				<v-row>
					<v-col
						v-for="invoice in props.items"
						:key="invoice.invoiceId"
						cols="12"
						sm="6"
						lg="4"
						xl="3"
					>
						<v-card>
							<v-list dense>
								<v-list-item>
									<strong>Invoice ID</strong>
									<v-spacer></v-spacer>
									{{ invoice.invoiceId }}
								</v-list-item>
								<v-list-item>
									<strong>Post Start Date</strong>
									<v-spacer></v-spacer>
									{{ invoice.startDate | formatDate }}
								</v-list-item>
								<v-list-item>
									<strong>Post End Date</strong>
									<v-spacer></v-spacer>
									{{ invoice.endDate | formatDate }}
								</v-list-item>
								<v-list-item>
									<strong>Total Days Live</strong>
									<v-spacer></v-spacer>
									{{ invoice.totalDays }}
								</v-list-item>
								<v-list-item>
									<strong>First 28 Days</strong>
									<v-spacer></v-spacer>
									{{ invoice.first28Q }} x £
									{{ invoice.first28Amount.toFixed(2) }}
								</v-list-item>
								<v-list-item>
									<strong>Further 28 Days</strong>
									<v-spacer></v-spacer>
									{{ invoice.further28Q }} x £
									{{ invoice.first28Amount.toFixed(2) }}
								</v-list-item>
								<v-list-item>
									<strong>VAT @ {{ invoice.vat }}</strong>
									<v-spacer></v-spacer>
									£ {{ invoice.vatAmount.toFixed(2) }}
								</v-list-item>
								<v-list-item>
									<strong>Total Price</strong>
									<v-spacer></v-spacer>
									£ {{ invoice.totalPrice.toFixed(2) }}
								</v-list-item>
							</v-list>
							<v-card-actions>
								<v-btn
									color="secondary"
									:to="{
										name: 'InvoiceOutput',
										params: {
											id: invoice.invoiceId
										}
									}"
									block
									>View Formatted Invoice</v-btn
								>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</template>
		</v-data-iterator>
		<v-pagination
			v-model="page"
			:length="Math.max(numberOfPosts / itemsPerPage, 1)"
			color="accent"
		></v-pagination>
	</div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
export default {
	name: "InvoiceList",
	data() {
		return {
			page: 1,
			itemsPerPage: 50,
			pageSizes: [5, 10, 15, 25, 50],
			sortBy: "created",
			sortByItems: [
				{ label: "Created", key: "created" },
				{ label: "Post Start Date", key: "listingStartDate" },
				{ label: "Post End Date", key: "listingEndDate" },
				{ label: "Total Price", key: "totalPrice" },
				{ label: "Total Days Live", key: "totalDays" }
			],
			sortDesc: true,
			postsFromXDaysAgoItems: [1, 3, 7, 14, 28, 90, 365],
			postsFromXDaysAgo: 28
		};
	},

	async created() {
		await this.$store.dispatch(
			"retrieveInvoiceList",
			this.postsFromXDaysAgo
		);
	},
	computed: {
		...mapState(["admin"]),
		numberOfPosts() {
			return this.admin.invoiceList && this.admin.invoiceList.length;
		},
		estTotalRevenue() {
			let estRev = 0;
			this.admin.invoiceList.forEach(
				invoice => (estRev += invoice.totalPrice)
			);
			return estRev;
		}
	},
	filters: {
		formatDate(date) {
			return moment(date).format("DD MMMM YYYY");
		}
	},
	watch: {
		postsFromXDaysAgo(newVal) {
			this.$store.dispatch("retrieveInvoiceList", newVal);
		}
	}
};
</script>