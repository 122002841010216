<template>
	<div class="container">
		<v-row>
			<v-col cols="12" class="pa-0 ma-0"> </v-col>
			<v-col cols="12" class="py-0 my-0">
				<h2>
					Interest in
					{{ interestDetail.business.reference }}
					from {{ userDetail.forenames }} {{ userDetail.surname }}
				</h2>
			</v-col>
			<v-col cols="12" class="py-0 my-0">
				<p>Expressed {{ interestDetail.createdDate | formatDate }}</p>
			</v-col>
			<v-btn
				tile
				small
				color="secondary"
				:to="{
					name: 'PostDetail',
					params: { id: interestDetail.businessId }
				}"
				class="px-2 mx-2"
				>View Post Detail</v-btn
			>
		</v-row>

		<v-row
			style="background: white; borderRadius: 3px"
			class="my-2 mx-1 pa-2"
		>
			<v-col cols="12">
				<v-list-item>
					<h3>Profile</h3>
				</v-list-item>
				<v-divider></v-divider>
				<v-list-item>
					<span> {{ interestDetail.profile }}</span>
				</v-list-item>
			</v-col>
			<v-col cols="12" md="6">
				<v-list-item>
					<h3>Interested Party Details</h3>
				</v-list-item>
				<v-divider></v-divider>
				<v-list-item>
					<strong>Name</strong>
					<v-spacer></v-spacer>{{ userDetail.forenames }}
					{{ userDetail.surname }}
				</v-list-item>
				<v-list-item>
					<strong>Company</strong><v-spacer></v-spacer
					>{{ userDetail.company }}
				</v-list-item>
				<v-list-item>
					<strong>Position</strong><v-spacer></v-spacer
					>{{ userDetail.position }}
				</v-list-item>
				<v-list-item>
					<strong>Email</strong><v-spacer></v-spacer
					>{{ userDetail.emailAddress1 }}
				</v-list-item>
				<v-list-item>
					<strong>Alternate Email</strong><v-spacer></v-spacer
					>{{ userDetail.emailAddress2 }}
				</v-list-item>
				<v-list-item>
					<strong>Telephone</strong><v-spacer></v-spacer
					>{{ addressDetail.telephone }}
				</v-list-item>
				<v-list-item>
					<strong>Mobile</strong><v-spacer></v-spacer
					>{{ addressDetail.mobile }}
				</v-list-item>
			</v-col>
			<v-col cols="12" md="6">
				<v-list-item>
					<h3>Address</h3>
				</v-list-item>
				<v-divider></v-divider>
				<v-list-item>
					<strong>Address Line 1</strong><v-spacer></v-spacer
					>{{ addressDetail.addressLine1 }}
				</v-list-item>
				<v-list-item>
					<strong>Address Line 2</strong><v-spacer></v-spacer
					>{{ addressDetail.addressLine2 }}
				</v-list-item>
				<v-list-item>
					<strong>Address Line 3</strong><v-spacer></v-spacer
					>{{ addressDetail.addressLine3 }}
				</v-list-item>
				<v-list-item>
					<strong>Town/City</strong><v-spacer></v-spacer
					>{{ addressDetail.townCity }}
				</v-list-item>
				<v-list-item>
					<strong>County</strong><v-spacer></v-spacer
					>{{ addressDetail.county }}
				</v-list-item>
				<v-list-item>
					<strong>Post Code</strong><v-spacer></v-spacer
					>{{ addressDetail.postcode }}
				</v-list-item>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
	name: "InterestDetail",
	async created() {
		await this.$store.dispatch(
			"retrieveInterestByID",
			this.$route.params.id
		);
		this.$store.dispatch("retrieveNotificationCount");
	},
	computed: {
		...mapState(["seller"]),
		addressDetail() {
			return (
				this.seller.interestDetail &&
				this.seller.interestDetail.addressDetails
			);
		},
		interestDetail() {
			return (
				this.seller.interestDetail &&
				this.seller.interestDetail.interest
			);
		},
		userDetail() {
			return (
				this.seller.interestDetail &&
				this.seller.interestDetail.interestedUserDetails
			);
		}
	},
	filters: {
		formatDate(date) {
			return moment(date).format("DD MMMM YYYY");
		}
	}
};
</script>
