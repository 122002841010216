import Repository from "../Services/Repository.js";
//import axios from "axios";

export default {
    state: {
        labels: {},
        labelsObject: {},
        listOfLabels: [],
        newPostLabels: {},
        registerLabels: {}
    },
    getters: {
        locationSizeLabels(state) {
            return state.labelsObject;
        },
        newPostLabels(state) {
            return state.newPostLabels !== null ? state.newPostLabels : {};
        }
    },
    mutations: {
        SET_LABELS(state, labels) {
            state.labels = labels;
        },
        SET_LABELS_OBJECT(state, labelObject) {
            state.labelsObject = labelObject;
        },
        SET_POST_LABELS(state, labels) {
            state.newPostLabels = labels;
        },
        SET_REGISTER_LABELS(state, labels) {
            state.registerLabels = labels;
        },
        APPEND_LABELS(state, labels) {
            state.listOfLabels.push(labels);
        },
        RESET_LABELS(state) {
            state.listOfLabels = [];
        }
    },
    actions: {
        retrieveSingleLabelSet(context, label) {
            Repository.get('label/' + label)
                .then(response => context.commit('SET_LABELS', response.data))
                .catch(e => context.commit('SET_ERROR', e))
        },
        addToLabelList(context, label) {
            Repository.get('label/' + label)
                .then(response => context.commit('APPEND_LABELS', response.data))
                .catch(e => context.commit('SET_ERROR', e))
        },
        resetLabelList(context) {
            context.commit('RESET_LABELS');
        },
        retrieveNewPostLabels(context) {
            Repository.get('label/newpost')
                .then(
                    response => {
                        context.commit("SET_POST_LABELS", response.data);
                    });
        },
        retrieveRegisterLabels(context, registerBuyer) {
            const registerMode = registerBuyer ? "true" : "false";

            Repository.get("label/register?registerBuyer=" + registerMode)
                .then(response => {
                    context.commit("SET_REGISTER_LABELS", response.data);
                })
                .catch(e => context.commit("SET_ERROR", e));
        },
        retrieveLocationSizeLabels: async function (context) {

            const locationSizeFields = ['employee', 'location', 'turnover'];
            let labelObject = {};

            for (let i = 0; i < locationSizeFields.length; i++) {
                await Repository.get('label/' + locationSizeFields[i])
                    .then(response => {
                        labelObject[locationSizeFields[i]] = response.data
                    })
            }

            if (Object.keys(labelObject).length !== 0) {
                context.commit('SET_LABELS_OBJECT', labelObject);
            }
        }
    }
};
