import Vue from "vue";
import Vuex from "vuex";

//Modules
import admin from "./modules/admin";
import allAuth from "./modules/allAuth";
import auth from "./modules/auth";
import buyer from "./modules/buyer";
import labels from "./modules/labels";
import seller from "./modules/seller";
import visitor from "./modules/visitor";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    admin,
    allAuth,
    auth,
    buyer,
    labels,
    seller,
    visitor
  }
});
