import Repository from "../Services/Repository.js";
import jwt_decode from "jwt-decode";

export default {
	state: {
		token: localStorage.getItem("access_token") || null,
		refreshToken: localStorage.getItem("refresh_token") || null,
		responseMessage: null,
		error: null
	},
	getters: {
		token(state) {
			return state.token;
		},
		tokenInDate(state) {
			if (state.token == null) return false;
			const expiry = jwt_decode(state.token)["exp"];
			return expiry * 1000 > Date.now();
		},
		isLoggedIn(state, getters) {
			return state.token !== null && getters.tokenInDate;
		},
		role(state, getters) {
			if (getters.isLoggedIn) {
				return jwt_decode(state.token)[
					"http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
				];
			} else {
				return null;
			}
		},
		userId(state, getters) {
			if (getters.isLoggedIn) {
				return jwt_decode(state.token)[
					"sub"
				][0];
			} else {
				return null;
			}
		}
	},

	mutations: {
		SET_TOKEN(state, token) {
			state.token = token;
		},
		SET_REFRESH_TOKEN(state, token) {
			state.refreshToken = token;
		},
		SET_ERROR(state, e) {
			state.error = e;
		},
		SET_RESPONSE_MESSAGE(state, msg) {
			state.responseMessage = msg;
		}
	},
	actions: {
		async login(context, credentials) {
			return new Promise((resolve, reject) => {
				Repository.post("login", {
					Email: credentials.Username,
					Password: credentials.Password
				})
					.then(response => {
						const token = response.data.token;
						const refreshToken = response.data.refreshToken;

						context.commit("SET_TOKEN", token);
						context.commit("SET_REFRESH_TOKEN", refreshToken);
						localStorage.setItem("access_token", token);
						localStorage.setItem("refresh_token", refreshToken);

						resolve(response);
					})
					.catch(e => {
						reject(e);
					});
			});
		},

		async refreshToken(context) {
			const accessToken = localStorage.getItem("access_token");
			const refreshToken = localStorage.getItem("refresh_token");

			return new Promise((resolve, reject) => {
				Repository.post("refresh-token", {}, {
					headers: {
						accessToken, refreshToken
					}
				})
					.then(response => {
						const token = response.data.token;
						const refreshToken = response.data.refreshToken;

						context.commit("SET_TOKEN", token);
						context.commit("SET_REFRESH_TOKEN", refreshToken);
						localStorage.setItem("access_token", token);
						localStorage.setItem("refresh_token", refreshToken);

						resolve(response);
					})
					.catch(e => {
						localStorage.removeItem("access_token");
						localStorage.removeItem("refresh_token");
						context.commit("SET_TOKEN", null);
						context.commit("SET_REFRESH_TOKEN", null);
						reject(e);
					});
			});
		},

		async logOut(context) {
			if (context.getters.isLoggedIn) {
				const token = localStorage.getItem("access_token");

				return new Promise((resolve, reject) => {
					Repository.post('logout', {}, { headers: { Authorization: `Bearer ${token}` } })
						.then(response => {
							localStorage.removeItem("access_token");
							localStorage.removeItem("refresh_token");
							context.commit("SET_TOKEN", null);
							context.commit("SET_REFRESH_TOKEN", null);
							resolve(response);
						})
						.catch(e => {
							reject(e.response.data.response);
						})
				})
			}

		},

		async sendForgotPassLink(context, email) {
			return new Promise((resolve, reject) => {
				Repository.post("unauth/forgotpassword", {
					Email: email
				})
					.then(response => {
						context.commit("SET_RESPONSE_MESSAGE", response.data);
						resolve(response.data)
					})
					.catch(e => {
						context.commit("SET_ERROR", e);
						reject(e.response.data.response)
					});
			});
		},

		async resetPassword(context, payload) {
			return new Promise((resolve, reject) => {
				Repository.post(
					"unauth/resetpassword?email=" +
					payload.emailParam +
					"&token=" +
					payload.tokenParam,
					{
						Password: payload.passwords.password,
						ConfirmPassword: payload.passwords.confirmPassword
					}
				)
					.then(response => {
						context.commit("SET_RESPONSE_MESSAGE", response.data);
						resolve(response.data);
					})
					.catch(e => {
						context.commit("SET_ERROR", e.response.data.response);
						reject(e.response.data.response);
					});
			});
		},

		async registerSeller(context, form) {
			return new Promise((resolve, reject) => {
				Repository.post("register-insolvencypractitioner", {
					Email: form.stepOne.email,
					Email2: form.stepOne.secondaryEmail,
					Password: form.stepFour.password,
					Title: form.stepOne.titleID,
					Forenames: form.stepOne.forenames,
					Surname: form.stepOne.surname,
					Position: form.stepOne.position,
					CompanyName: form.stepOne.company,
					AddressLine1: form.stepTwo.addressLine1,
					AddressLine2: form.stepTwo.addressLine2,
					AddressLine3: form.stepTwo.addressLine3,
					TownCity: form.stepTwo.townCity,
					County: form.stepTwo.county,
					Postcode: form.stepTwo.postcode,
					Country: form.stepTwo.country,
					Telephone: form.stepOne.telephone,
					CapacityID: form.stepThree.capacityID,
					Mobile: form.stepOne.mobile,
					IPName: form.stepThree.IPName
				})
					.then(response => {
						context.commit("SET_RESPONSE_MESSAGE", response.data);
						resolve(response.data);
					})
					.catch(e => {
						context.commit("SET_ERROR", e);
						reject(e);
					});
			});
		},

		async registerBuyer(context, form) {
			return new Promise((resolve, reject) => {
				Repository.post("register-interestedparty", {
					Email: form.stepOne.email,
					Email2: form.stepOne.secondaryEmail,
					Password: form.stepSeven.password,
					Title: form.stepOne.titleID,
					Forenames: form.stepOne.forenames,
					Surname: form.stepOne.surname,
					Position: form.stepOne.position,
					CompanyName: form.stepOne.company,
					Function: form.stepOne.functionID,
					FunctionOther: form.stepOne.functionOther,
					AddressLine1: form.stepTwo.addressLine1,
					AddressLine2: form.stepTwo.addressLine2,
					AddressLine3: form.stepTwo.addressLine3,
					TownCity: form.stepTwo.townCity,
					County: form.stepTwo.county,
					Postcode: form.stepTwo.postcode,
					Country: form.stepTwo.country,
					Telephone: form.stepOne.telephone,
					Mobile: form.stepOne.mobile,
					Turnover: form.stepThree.turnovers,
					Employees: form.stepThree.employees,
					Location: form.stepThree.locations,
					Categories: form.stepFour.categories,
					KeyAssets: form.stepSix.assets,
					Keywords: form.stepFive.keywords
				})
					.then(response => {
						resolve(response.data);
					})
					.catch(e => {
						context.commit("SET_ERROR", e);
						reject(e);
					});
			});
		},
		async confirmEmail(context, payload) {
			return new Promise((resolve, reject) => {
				Repository.post("confirmemail?userId=" + payload.userId + "&token=" + payload.token)
					.then(response => {
						resolve(response.data);
					})
					.catch(e => {
						context.commit("SET_ERROR", e.response.data.response);
						reject(e.response.data.response);
					});
			});
		}
	}
};
