<template>
	<div class="container">
		<v-row>
			<v-snackbar
				v-model="snackbar"
				top
				timeout="5000"
				color="darkgrey"
				text
				v-if="$route.query.reason === 'expired'"
			>
				Your session has expired. Please reauthenticate.

				<template>
					<v-btn fab dark text @click="snackbar = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</template>
			</v-snackbar>

			<v-col cols="12" md="6">
				<h2>Login</h2>
				<form action="#" @submit.prevent="login">
					<div class="form-control">
						<v-text-field
							label="Email"
							type="email"
							v-model="email"
							:loading="loading"
							:error-messages="
								email === '' && attemptMade
									? 'Please Enter Email'
									: null
							"
							color="accent"
						/>
					</div>

					<div class="form-control mb-more">
						<v-text-field
							type="password"
							label="Password"
							v-model="password"
							:error-messages="
								password === '' && attemptMade
									? 'Please Enter Password'
									: null
							"
							color="accent"
						/>
					</div>
					<p v-if="errorMessage !== null">
						<span v-html="errorMessage"></span>
					</p>

					<div class="form-control">
						<v-btn
							type="submit"
							class="btn-submit"
							tile
							color="secondary"
							:loading="loading"
						>
							Login
						</v-btn>
					</div>
				</form>
				<p class="py-4">
					<router-link :to="{ name: 'ForgotPass' }"
						>Forgot Password?</router-link
					>
				</p>
			</v-col>
			<v-col cols="12" md="6">
				<h2>Don't Have an Account?</h2>
				<v-row>
					<v-col md="12">
						<registerBuyerCard />
					</v-col>
				</v-row>
				<v-row>
					<v-col md="12">
						<registerSellerCard />
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

import registerBuyerCard from "@/components/registerBuyerCard.vue";
import registerSellerCard from "@/components/registerSellerCard.vue";

export default {
	name: "Login",
	metaInfo() {
		return {
			title: "IP-BID.com - Login"
		};
	},
	components: {
		registerBuyerCard,
		registerSellerCard
	},
	data() {
		return {
			email: "",
			password: "",
			errorMessage: null,
			loading: false,
			snackbar: true,
			items: [
				{ title: "interestedparty", label: "Interested Party" },
				{
					title: "insolvencypractitioner",
					label: "Insolvency Practitioner"
				}
			],
			attemptMade: false
		};
	},
	mounted() {
		//redirect if already logged in
		if (this.isLoggedIn) {
			if (Object.keys(this.$route.query).includes("name")) {
				this.$router.push({
					name: this.$route.query.name,
					params: { id: this.$route.query.id }
				});
			} else {
				this.$router.push({ name: "ConditionalHome" });
			}
		}
	},
	computed: {
		...mapGetters(["isLoggedIn"])
	},
	methods: {
		async login() {
			this.loading = true;
			if (this.email !== "" && this.password !== "") {
				await this.$store
					.dispatch("login", {
						Username: this.email,
						Password: this.password
					})
					.then(response => {
						//Check for login redirect, if no redirect return user to home page
						if (Object.keys(this.$route.query).includes("name")) {
							this.$router.push({
								name: this.$route.query.name,
								params: { id: this.$route.query.id }
							});
						} else {
							this.$router.push({ name: "ConditionalHome" });
						}
						response;
					})
					.catch(e => {
						this.password = "";
						this.errorMessage = e.response.data.response;
						console.log(e);
					});
			}

			this.loading = false;
			this.attemptMade = true;
		}
	}
};
</script>
