<template>
	<div class="container">
		<h2>Help</h2>
		<v-card class="d-inline-flex flex-wrap pa-2" flat outlined>
			<router-link
				class="mx-3"
				style="color: lightgrey"
				:to="{ name: 'Help' }"
				>FAQs</router-link
			>
			<router-link
				:to="{ name: 'ContactUs' }"
				class="mx-3"
				style="color: #0083a6"
				>Contact Us</router-link
			>
		</v-card>
		<v-row
			style="background: white; borderRadius: 3px"
			class="my-2 mx-1 pa-4"
		>
			<v-col cols="12" md="9">
				<p>
					Feel free to contact us with feedback, comments or queries
					regarding IP-BID.com and our online service. We will aim to
					respond as quickly as possible but please bear with us if
					your message requires research at our end.
				</p>

				<v-text-field
					label="From (Name)"
					filled
					dense
					:error-messages="fromNameErrors"
					v-model="form.fromName"
				></v-text-field>
				<v-text-field
					label="Reply to Email"
					filled
					type="email"
					dense
					:error-messages="emailErrors"
					v-model="form.replyToEmail"
				></v-text-field>

				<v-select
					clearable
					:items="visitor.contactUsLabels.contactUsFields"
					filled
					item-text="description"
					item-value="description"
					:error-messages="regardingErrors"
					v-model="form.regarding"
					label="Regarding Topic"
					required
				></v-select>

				<v-textarea
					filled
					label="Message"
					:error-messages="messageErrors"
					rows="3"
					v-model="form.message"
				></v-textarea>
				<p>{{ error }}</p>

				<recaptcha @validate="validate" @expired="expireRecaptcha" />
				<v-btn
					tile
					color="secondary"
					@click="sendForm"
					:loading="loading"
					:disabled="!validateRecaptcha"
					>Send</v-btn
				>

				<savedAnimation
					:text="successMessage"
					:showTick="true"
					:triggered="triggerSaveText"
				/>
			</v-col>
			<v-col cols="12" md="3">
				<h3>Contact Info</h3>
				<strong>Address</strong>
				<p style="fontSize: 0.9em">
					IP-BID Limited <br />
					The Control Tower <br />
					Witchcraft Way <br />
					Rackheath <br />
					NR13 6GA <br />
					United Kingdom
				</p>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import savedAnimation from "@/components/savedAnimation.vue";

import recaptcha from "@/components/recaptcha.vue";

//Form Validation Helpers
const createRequiredErrors = (field, fieldString) => {
	const errors = [];
	if (!field.$dirty) return errors;
	!field.required && errors.push(fieldString + " is required");
	return errors;
};

export default {
	name: "ContactUs",
	metaInfo() {
		return {
			title:
				"Pre Pack Administration, Business Assets for Sale, UK Online Insolvency Marketplace: IP-BID.com"
		};
	},
	mixins: [validationMixin],
	components: {
		savedAnimation,
		recaptcha
	},
	data() {
		return {
			items: ["Other", "Feedback"],
			form: {
				fromName: null,
				replyToEmail: null,
				regarding: null,
				message: null
			},
			loading: false,
			error: null,
			successMessage: null,
			triggerSaveText: false,
			validateRecaptcha: false
		};
	},
	created() {
		this.$store.dispatch("retrieveContactUsLabels");
	},
	validations: {
		form: {
			fromName: { required },
			replyToEmail: { required, email },
			regarding: { required },
			message: { required }
		}
	},
	computed: {
		...mapState(["visitor"]),
		fromNameErrors() {
			return createRequiredErrors(this.$v.form.fromName, "From Name");
		},
		emailErrors() {
			const errors = [];
			if (!this.$v.form.replyToEmail.$dirty) return errors;
			!this.$v.form.replyToEmail.required &&
				errors.push("Please Enter an Email");
			!this.$v.form.replyToEmail.email &&
				errors.push("Email should be valid");
			return errors;
		},
		regardingErrors() {
			return createRequiredErrors(this.$v.form.regarding, "Regarding");
		},
		messageErrors() {
			return createRequiredErrors(this.$v.form.message, "Message");
		}
	},
	methods: {
		async sendForm() {
			if (!this.$v.form.$invalid) {
				this.loading = true;
				await this.$store
					.dispatch("sendContactUsForm", this.form)
					.then(response => {
						this.successMessage = response.response;
						this.triggerSaveText = true;
					})
					.catch(e => (this.error = e));

				this.loading = false;
				await new Promise(resolve => setTimeout(resolve, 2800)); //Length of Animation
				this.triggerSaveText = false;
			} else {
				this.$v.form.$touch();
			}
		},
		validate(success) {
			this.validateRecaptcha = success;
		},
		expireRecaptcha(){
			this.validateRecaptcha = false;
		}
	}
};
</script>
