<template>
	<div class="container" style="textAlign: justify">
		<h2>Privacy Policy</h2>

		<h3>General Statement</h3>
		<p>
			We collect only the basic personal details required to provide the
			service for which you have registered and to process your
			transactions. We will not release your name, address, email address
			or any other information about our registered users and customers to
			any outside party. We will not trade, resell, sell or redistribute
			information that you provide to us to any other companies,
			organisations or individuals.
		</p>
		<h3>Information we collect</h3>
		<p>
			Identifiable information is collected when you register or fill in a
			form on The IP-BID.com website, or when you send an email to
			IP-BID.com. The information that is provided by you will only be
			used for the purpose for which it is provided, unless you are
			informed otherwise at the time of giving the information. The
			identifiable information we collect via this website may include:
			Any personal details you submit, such as name, address, email
			address, etc. Your IP address (this is your computer's individual
			identification number), which is automatically logged by our web
			server. Any business details you submit in order to perform services
			requested by you, including details of business opportunities being
			marketed by Insolvency Practitioners. Your preferences and search
			criteria in order to perform services requested by you. Details of
			emails sent and received as part of IP-BID.com’s service offering.
			Information in the form of cookies are sent to and stored on your
			computer. Cookies are used to identify you when you visit our site
			and are used to remember details such as usernames, passwords and
			your preferences. We do not use cookies to monitor your general
			activity on the Internet. If you wish, you can disable cookies on
			your Internet browser, though this may affect the service you
			receive from IP-BID.com. For further information about cookies,
			including how to delete them, please visit www.aboutcookies.org.
		</p>
		<h3>How we use your information</h3>
		<p>
			Any personal information we collect from this website will be used
			in accordance with the Data Protection Act 1998 and other applicable
			laws. The details we collect may be used:
		</p>
		<p>
		<ol>
			<li>
				To process your registration, process orders and to provide
				customer services or user support.
			</li>
			<li>
				To send you email alerts and notifications as part of the
				services requested by you.
			</li>
			<li>
				To communicate with you about your registration and services you
				have requested from us.
			</li>
			<li>
				To comply with legal requirements. We may be required in certain
				instances to pass information to relevant legal authorities.
			</li>
			<li>
				To send you information occasionally on new services and updates
				from IP-BID.com. In such cases you will be offered the option to
				opt in or out of such communications.
			</li>
			<li>
				To process and sort data, and monitor usage for administrative
				purposes. We may need to use companies associated with
				IP-BID.com to carry out some of these activities. Rest assured
				that no third parties will be allowed to use your personal
				information for their own purposes.
			</li>
		</ol>
		</p>
		<h3>Policy amendments</h3>
		<p>
			We may update this privacy policy from time-to-time by posting a new
			version on our website. You should check this page occasionally to
			ensure you are happy with any changes.
		</p>
		<h3>Your rights</h3>
		<p>
			Please let us know if the personal information that we hold about
			you needs to be corrected or updated. This can be done in the My
			Details section of the website. You can also terminate your
			registration with IP-BID.com at any time by using the Contact Us
			facility. Please note that we may need to keep some personal data
			for a limited period of time in order to process orders or to comply
			with customer service or after sales obligations. You also have the
			right to request a copy of any personal information we hold about
			you. To do this, simply write to us at the address below, enclosing
			a cheque for £10 payable to IP-BID.com to cover our administration
			costs. You can also use this address to communicate with us about
			this Privacy Policy or you can use the Contact Us facility.
		</p>
	</div>
</template>


<script>
export default {
	name: "PrivacyPolicy",
	metaInfo() {
		return {
			title: "IP-BID.com - Privacy Policy"
		};
	}
};
</script>