<template>
	<div class="container">
		<v-btn
			tile
			small
			color="primary"
			text
			:to="{
				name: 'PostDetail'
			}"
			><v-icon>mdi-chevron-left</v-icon> to post</v-btn
		>
		<h2>Amend Post Assets</h2>
		<v-row
			style="background: white; borderRadius: 3px"
			class="my-2 mx-1 pa-4"
		>
			<v-col cols="12" md="6">
				<v-select
					:items="labels.labels.assetLabels"
					item-text="description"
					item-value="assetId"
					v-model="assets"
					label="Assets"
					multiple
					chips
				></v-select>
			</v-col>
		</v-row>
		<v-btn
			tile
			color="secondary"
			@click.prevent="saveChanges"
			:loading="loading"
			>Save Changes</v-btn
		>
		<p v-if="errorMessage !== null">{{ errorMessage }}</p>
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
	name: "AmendPostAssets",
	metaInfo() {
		return {
			title: "IP-BID.com - Amend Posting Assets"
		};
	},
	components: {
		//		sellerPostActionNav
	},
	data() {
		return {
			assets: {},
			successMessage: null,
			errorMessage: null,
			loading: false
		};
	},
	async created() {
		await this.$store.dispatch("retrieveSingleLabelSet", "assets");

		if (
			this.loadedPost === null ||
			this.loadedPost.businessId !== this.$route.params.id
		) {
			await this.$store.dispatch(
				"retrievePostByID",
				this.$route.params.id
			);
		}

		this.assets = this.loadedPost.assets.map(asset => asset.assetId);
	},
	computed: {
		...mapState(["labels"]),
		...mapGetters(["loadedPost"])
	},
	methods: {
		async saveChanges() {
			this.loading = true;

			const payload = {
				id: this.$route.params.id,
				assets: this.assets
			};

			await this.$store
				.dispatch("amendPostAssets", payload)
				.then(response => {
					response;
					this.$router.push({
						name: "PostDetail"
					});
				})
				.catch(e => (this.errorMessage = e));

			this.loading = false;
		}
	}
};
</script>