<template>
	<div class="container">
		<v-row>
			<v-col cols="12" md="6">
				<h3>Send a password reset link to your email address</h3>
				<form action="#" @submit.prevent="forgotPass">
					<v-text-field
						label="Email"
						type="email"
						color="accent"
						filled
						v-model="email"
						:error-messages="
							email === '' && attemptMade
								? 'Please enter an email'
								: null
						"
					></v-text-field>
					<v-btn
						type="submit"
						tile
						color="secondary"
						:loading="loading"
						>Send Email</v-btn
					>
				</form>
				<div class="py-2">
					<v-icon style="color: grey; fontSize: 0.85em"
						>mdi-alert-circle-outline</v-icon
					>
					<span style="color: grey; fontSize: 0.85em">
						If you're struggling to find your password reset email,
						please check your email's spam or junk inbox.
					</span>
				</div>
				<savedAnimation
					:text="successMessage"
					:showTick="true"
					:triggered="triggerSaveText"
				/>
				<p v-if="errorMessage !== null">{{ errorMessage }}</p>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import savedAnimation from "@/components/savedAnimation.vue";

export default {
	name: "ForgotPass",
	metaInfo(){
		return{
			title: "IP-BID.com - Reset Password"
		}
	},
	components: {
		savedAnimation
	},
	data() {
		return {
			email: "",
			attemptMade: false,
			loading: false,
			errorMessage: null,
			successMessage: null,
			triggerSaveText: false
		};
	},
	methods: {
		async forgotPass() {
			this.attemptMade = true;
			if (this.email) {
				this.loading = true;
				if (this.email !== "") {
					await this.$store
						.dispatch("sendForgotPassLink", this.email)
						.then(response => {
							this.successMessage = response.response;
							this.triggerSaveText = true;
							this.email = "";
							this.attemptMade = false;
						})
						.catch(e => (this.errorMessage = e));
				}

				this.loading = false;

				await new Promise(resolve => setTimeout(resolve, 2800)); //Length of Animation
				this.triggerSaveText = false;
			}
		}
	}
};
</script>
