<template>
	<div>
		<v-data-table
			:loading="loading"
			hide-default-footer
			:page.sync="page"
			:items-per-page="pageSize"
			:headers="headers"
			:items="data"
			item-key="businessId"
			:sort-by.sync="sortBy"
			:sort-desc.sync="sortDesc"
			:search="searchTerm"
		>
			<template v-slot:item.creationDate="{ item }">
				{{ item.creationDate | formatDate }}
			</template>

			<template v-slot:item.displayHome="{ item }">
				<v-simple-checkbox
					color="secondary"
					v-model="item.displayHome"
					disabled
				></v-simple-checkbox>
			</template>

			<template v-slot:item.adminApproved="{ item }">
				<v-simple-checkbox
					color="secondary"
					v-model="item.adminApproved"
					@input="adminApprove(item.businessId, item.adminApproved)"
				></v-simple-checkbox>
			</template>

			<template v-slot:item.actions="{ item }">
				<v-icon medium class="mr-2" @click="viewPost(item.businessId)">
					mdi-eye
				</v-icon>
				<v-icon medium class="mr-2" @click="viewUser(item.createdBy)">
					mdi-account-search
				</v-icon>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import moment from "moment";

export default {
	name: "businessTable",
	props: ["data", "page", "pageSize", "searchTerm"],
	data() {
		return {
			dialog: false,
			search: null,
			sortBy: "Created",
			sortDesc: true,
			loading: false
		};
	},
	computed: {
		headers() {
			let headers = [
				{ text: "Actions", value: "actions", sortable: false },
				{ text: "ID", value: "businessId" },
				{
					text: "Activity Heading",
					value: "activityHeading"
				},
				{ text: "Reference", value: "reference" },
				{ text: "Site Status", value: "siteStatus.description" },
				{ text: "Created", value: "creationDate" },
				{ text: "Display Home", value: "displayHome" },
				{ text: "Admin Approved", value: "adminApproved" }
			];

			return headers;
		}
	},
	filters: {
		formatDate(date) {
			return moment(date).format("DD/MM/YY");
		}
	},
	methods: {
		viewPost(id) {
			window.open("/post/" + id);
		},
		viewUser(id) {
			window.open("/admin/user/" + id);
		},
		async adminApprove(id, isApproved) {
			this.loading = true;

			if (isApproved) {
				await this.$store.dispatch("approvePost", id);
			} else {
				await this.$store.dispatch("unapprovePost", id);
			}

			this.loading = false;
		}
	}
};
</script>