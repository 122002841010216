<template>
	<div class="container">
		<v-btn
			tile
			small
			color="primary"
			text
			:to="{
				name: 'PostDetail'
			}"
			><v-icon>mdi-chevron-left</v-icon> to post</v-btn
		>
		<h2>Amend Post Description</h2>
		<v-row
			style="background: white; borderRadius: 3px"
			class="my-2 mx-1 pa-4"
		>
			<v-col cols="12" md="8">
				<v-textarea
					color="accent"
					filled
					label="Description"
					rows="4"
					:loading="loading"
					v-model="description"
				></v-textarea>
			</v-col>
		</v-row>
		<v-btn
			tile
			color="secondary"
			:loading="loading"
			@click.prevent="saveChanges"
			>Save Changes</v-btn
		>
		<p v-if="errorMessage !== null">{{ errorMessage }}</p>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "AmendPostDescription",
	metaInfo() {
		return {
			title: "IP-BID.com - Amend Posting Description"
		};
	},
	components: {},
	data() {
		return {
			description: {},
			successMessage: null,
			errorMessage: null,
			loading: false
		};
	},
	async created() {
		this.loading = true;
		if (
			this.loadedPost === null ||
			this.loadedPost.businessId !== this.$route.params.id
		) {
			await this.$store.dispatch(
				"retrievePostByID",
				this.$route.params.id
			);
		}

		this.description = this.loadedPost.description;
		this.loading = false;
	},
	computed: {
		...mapGetters(["loadedPost"])
	},
	methods: {
		async saveChanges() {
			const payload = {
				id: this.$route.params.id,
				description: this.description
			};

			await this.$store
				.dispatch("amendPostDescription", payload)
				.then(response => {
					response;
					this.$router.push({
						name: "PostDetail"
					});
				})
				.catch(e => (this.errorMessage = e.response.data.response));
		}
	}
};
</script>