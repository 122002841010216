<template>
	<div>
		<div v-if="role" class="container">
			<!-- Following section will generate all role's home pages based on main pages as defined in the all auth store -->
			<h2>Home</h2>
			<v-row>
				<v-col
					v-for="item in role === 'Interested Party'
						? allAuth.buyerItems
						: role === 'Insolvency Practitioner'
						? allAuth.sellerItems
						: allAuth.adminItems"
					cols="12"
					md="6"
					:key="item.title"
				>
					<v-card class="mx-auto" outlined>
						<v-list-item three-line>
							<v-list-item-content>
								<v-list-item-title class="headline mb-1">{{
									item.title
								}}</v-list-item-title>
								<v-list-item-subtitle>{{
									item.description
								}}</v-list-item-subtitle>
							</v-list-item-content>

							<v-list-item-avatar tile size="80">
								<v-img :src="item.icon"></v-img>
							</v-list-item-avatar>
						</v-list-item>

						<!-- href prop is used exclusively to redirect to SendGrid or external Website -->
						<v-card-actions>
							<v-btn
								color="info"
								small
								:to="item.routeName && { name: item.routeName }"
								:href="
									item.externalLink &&
										'https://' + item.externalLink
								"
								>Go</v-btn
							>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</div>
		<VisitorHome v-else />
	</div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
	name: "ConditionalHome",
	components: {
		//components that may not necessarily be loaded in all cases should be lazy loaded
		VisitorHome: () => import("../views/Visitor/Visitor Home.vue")
	},
	mounted() {
		//Preload buyer's posting inbox
		this.role === "Interested Party" &&
			this.$store.dispatch("retrievePostsByCriteria");
	},
	computed: {
		...mapGetters(["role"]),
		...mapState(["allAuth"])
	}
};
</script>
