<template>
	<div class="container">
		<v-btn
			tile
			small
			color="primary"
			text
			:to="{
				name: 'PostDetail'
			}"
			><v-icon>mdi-chevron-left</v-icon> to post</v-btn
		>
		<h2>Modify Post's End Date</h2>
		<p style="fontSize: 0.85em">
			Please note: when changing to a new End Date, the new date must be
			within 28 days of the current Start Date. Please consider withdrawing and reposting if a longer period is required.
		</p>
		<v-row>
			<v-col cols="12" md="4">
				<v-date-picker
					v-model="newEndDate"
					:min="currentStartDate"
				></v-date-picker> </v-col
			><v-col cols="12" md="6">
				<invoiceCalculator
					:dateRange="dateRange"
					:price="
						userDetails.myAccountDetails.customPriceRate ||
							standardPriceRate
					"
				/>
			</v-col>
		</v-row>

		<v-btn tile color="secondary" :loading="loading" @click="saveChanges"
			>Save Changes</v-btn
		>
		<p v-if="errorMessage !== null">{{ errorMessage }}</p>
	</div>
</template>

<script>
import invoiceCalculator from "@/components/Seller Components/invoiceCalculator.vue";
import { mapGetters } from "vuex";

export default {
	name: "ExtendPost",
	components: {
		invoiceCalculator
	},
	data() {
		return {
			newEndDate: null,
			errorMessage: null,
			userDetails: {}
		};
	},
	async created() {
		if (
			this.loadedPost === null ||
			this.loadedPost.businessId !== this.$route.params.id
		) {
			await this.$store.dispatch(
				"retrievePostByID",
				this.$route.params.id
			);
		}
	},
	async mounted() {
		await this.$store.dispatch("retrieveAccountDetails").then(response => {
			this.userDetails = response;
		});

		this.newEndDate = this.currentEndDate.slice(0, 10);
	},
	computed: {
		...mapGetters(["loadedPost", "standardPriceRate"]),
		currentStartDate() {
			return this.loadedPost && this.loadedPost.activeStartDate[0];
		},
		currentEndDate() {
			return this.loadedPost && this.loadedPost.activeEndDate[0];
		},
		dateRange() {
			const startDate = this.currentStartDate;
			const endDate = this.newEndDate;

			return [startDate, endDate];
		}
	},
	methods: {
		async saveChanges() {
			const payload = {
				id: this.$route.params.id,
				newEndDate: this.newEndDate
			};

			await this.$store
				.dispatch("extendPost", payload)
				.then(response => {
					response;
					this.$router.push({
						name: "PostDetail",
						params: { id: this.$route.params.id }
					});
				})
				.catch(e => {
					this.errorMessage = e;
				});
		}
	}
};
</script>