<template>
	<div class="container">
		<v-btn
			tile
			small
			color="primary"
			text
			:to="{
				name: 'PostDetail'
			}"
			><v-icon>mdi-chevron-left</v-icon> to post</v-btn
		>
		<h2>Amend Post Primary Category</h2>
		<v-btn
			tile
			color="secondary"
			:loading="loading"
			@click.prevent="saveChanges"
			>Save Changes</v-btn
		>
		<p v-if="errorMessage !== null">{{ errorMessage }}</p>
		<v-row
			style="background: white; borderRadius: 3px"
			class="my-2 mx-1 pa-4"
		>
			<businessCategoryPicker
				:showSelected="true"
				:existing="existingCategory || 0"
				:radio="true"
				v-model="primaryCategory"
			/>
		</v-row>
	</div>
</template>

<script>
import businessCategoryPicker from "@/components/businessCategoryPicker.vue";

import { mapGetters } from "vuex";

export default {
	name: "AmendPostPrimaryCategory",
	components: {
		businessCategoryPicker
	},
	data() {
		return {
			primaryCategory: null,
			loading: false,
			errorMessage: null
		};
	},
	async created() {
		if (
			this.loadedPost === null ||
			this.loadedPost.businessId !== this.$route.params.id
		) {
			await this.$store.dispatch(
				"retrievePostByID",
				this.$route.params.id
			);
		}
	},
	computed: {
		...mapGetters(["loadedPost"]),
		existingCategory() {
			return this.loadedPost.primaryCategory.map(
				cat => cat.categoryId
			)[0];
		},
		additionalCategories() {
			return this.loadedPost.categories.map(cat => cat.categoryId);
		}
	},
	methods: {
		async saveChanges() {
			this.loading = true;

			const payload = {
				id: this.$route.params.id,
				category: this.primaryCategory
			};

			await this.$store
				.dispatch("amendPostCategory", payload)
				.then(response => {
					response;
					this.$router.push({
						name: "PostDetail"
					});
				})
				.catch(e => (this.errorMessage = e));

			this.loading = false;
		}
	}
};
</script>