<template>
	<div class="container">
		<h2>Amend My Preferences</h2>
		<preferenceNavItems :active="'LocationSize'" />
		<v-row
			style="background: white; borderRadius: 3px"
			class="my-2 mx-1 pa-4"
		>
			<v-col cols="12" md="6">
				<v-select
					:items="labels.locationLabels"
					item-text="description"
					item-value="locationId"
					label="Location"
					multiple
					v-model="form.selectedLocatons"
					chips
				></v-select>
			</v-col>
			<v-col cols="12" md="6">
				<v-select
					:items="labels.employeeLabels"
					item-text="description"
					item-value="employeesId"
					label="Employees"
					multiple
					v-model="form.selectedEmployees"
					chips
				></v-select>
			</v-col>
			<v-col cols="12" md="6">
				<v-select
					:items="labels.turnoverLabels"
					item-text="description"
					item-value="turnoverId"
					label="Turnovers"
					multiple
					v-model="form.selectedTurnovers"
					chips
				></v-select>
			</v-col>
		</v-row>
		<v-btn
			tile
			color="secondary"
			:loading="loading"
			@click="saveChanges"
			:disabled="triggerSaveText"
			>Save Changes</v-btn
		>
		<p style="color: var(--v-error-base)" v-if="errorMessage !== null">
			{{ errorMessage }}
		</p>
		<savedAnimation
			:text="successMessage"
			:showTick="true"
			:triggered="triggerSaveText"
		/>
	</div>
</template>

<script>
import savedAnimation from "@/components/savedAnimation.vue";
import preferenceNavItems from "@/components/Buyer Components/preferenceNavItems.vue";
import Repository from "@/store/Services/Repository.js";

import { mapGetters } from "vuex";

export default {
	name: "AmendLocationSizePreferences",
	components: {
		preferenceNavItems,
		savedAnimation
	},
	data() {
		return {
			form: {
				selectedLocatons: [],
				selectedEmployees: [],
				selectedTurnovers: []
			},
			labels: {
				locationLabels: [],
				employeeLabels: [],
				turnoverLabels: []
			},
			apiError: null,
			successMessage: null,
			errorMessage: null,
			loading: false,
			triggerSaveText: false
		};
	},
	async created() {
		await Repository.get("label/location-size")
			.then(response => {
				this.labels.locationLabels = response.data.locationLabels;
				this.labels.employeeLabels = response.data.employeeLabels;
				this.labels.turnoverLabels = response.data.turnoverLabels;
			})
			.catch(e => (this.errorMessage = e));

		Repository.get("buyer/location-size-preferences", {
			headers: { Authorization: `Bearer ${this.token}` }
		})
			.then(response => {
				this.form.selectedLocatons = response.data.locations;
				this.form.selectedEmployees = response.data.employees;
				this.form.selectedTurnovers = response.data.turnover;
			})
			.catch(e => {
				this.apiError = e;
			});
	},
	computed: {
		...mapGetters(["token"])
	},
	methods: {
		async saveChanges() {
			this.loading = true;
			this.successMessage = null;
			this.errorMessage = null;
			let emptyFields = [];

			Object.keys(this.form).forEach(key => {
				if (this.form[key].length === 0) emptyFields.push(key);
			});

			if (emptyFields.length === 0) {
				await Repository.put(
					"buyer/location-size-preferences",
					{
						Turnovers: this.form.selectedTurnovers,
						Employees: this.form.selectedEmployees,
						Locations: this.form.selectedLocatons
					},
					{
						headers: { Authorization: `Bearer ${this.token}` }
					}
				)
					.then(response => {
						this.successMessage = response.data.response;
						this.triggerSaveText = true;
					})
					.catch(e => {
						this.errorMessage = e.response.data.response;
					});
			} else {
				this.errorMessage = "Could Not Save Due to Empty Fields";
			}
			this.loading = false;

			await new Promise(resolve => setTimeout(resolve, 2800)); //Length of Animation
			this.triggerSaveText = false;
		}
	}
};
</script>