<template>
	<div class="container">
		<v-btn
			v-if="isPreExistingDraft"
			tile
			small
			color="primary"
			text
			:to="{
				name: 'PostDetail'
			}"
			><v-icon>mdi-chevron-left</v-icon> to post</v-btn
		>
		<h2>{{ isPreExistingDraft ? "Post Draft" : "Post New" }}</h2>
		<v-row justify="space-between">
			<v-col cols="12" md="4">
				<v-menu
					v-model="startMenu"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					min-width="290px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							:value="formattedDate(startDate)"
							label="Post Start Date"
							readonly
							:append-icon="'mdi-pencil'"
							color="accent"
							v-bind="attrs"
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker
						v-model="startDate"
						:min="todaysDate"
						:max="endDate"
						@input="startMenu = false"
					></v-date-picker>
				</v-menu>

				<v-menu
					v-model="endMenu"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					min-width="290px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							:value="formattedDate(endDate)"
							label="Post End Date"
							readonly
							:append-icon="'mdi-pencil'"
							color="accent"
							v-bind="attrs"
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker
						v-model="endDate"
						:min="startDate"
						@input="endMenu = false"
						:show-current="startDate && date28daysFromStartDate"
					></v-date-picker>
				</v-menu>

				<v-col cols="12">
					Would you like the invoice to be addressed to a different
					company name?
					<v-btn-toggle
						v-model="useCustomCompanyName"
						mandatory
						:color="
							useCustomCompanyName
								? 'info'
								: 'var(--v-error-base)'
						"
					>
						<v-btn small :value="false">
							No
						</v-btn>
						<v-btn small :value="true">
							Yes
						</v-btn>
					</v-btn-toggle>
				</v-col>

				<v-text-field
					label="Company Name on Invoice"
					v-model="userDetails.myAccountDetails.company"
					:disabled="!useCustomCompanyName"
					counter
					maxlength="50"
				></v-text-field> </v-col
			><v-col cols="12" md="6">
				<invoiceCalculator
					:dateRange="dateRange"
					:price="
						userDetails.myAccountDetails.customPriceRate ||
							standardPriceRate
					"
				/>
			</v-col>
			<v-col cols="12" md="6">
				Reminder: Clause 11 of our T's & C's. By confirming a Posting on IP-BID, the User agrees to pay the appropriate fees.
			</v-col> 
		</v-row>
	



		<v-btn
			tile
			color="secondary"
			:disabled="!formValid"
			@click="postNew"
			v-if="!isPreExistingDraft"
			:loading="loading"
			>{{ postOverSchedule ? "Post Now" : "Schedule" }}</v-btn
		>

		<v-btn
			tile
			color="secondary"
			:disabled="!formValid"
			@click="postDraft"
			v-if="isPreExistingDraft"
			:loading="loading"
			>{{ postOverSchedule ? "Post Now" : "Schedule" }}</v-btn
		>
		{{ tryAgain }}

		<p v-if="errorMessage !== null">{{ errorMessage }}</p>
	</div>
</template>

<script>
import invoiceCalculator from "@/components/Seller Components/invoiceCalculator.vue";

import moment from "moment";

import { mapState, mapGetters } from "vuex";

// Helpers
const isEmptyOrSpaces = str => {
	return str === null || str.match(/^ *$/) !== null;
};

export default {
	name: "PostDraft",
	components: {
		invoiceCalculator
	},
	data() {
		return {
			startDate: null,
			endDate: null,
			startMenu: false,
			endMenu: false,
			postId: null,
			errorMessage: null,
			loading: false,
			useCustomCompanyName: false,
			userDetails: {}
		};
	},
	async mounted() {
		this.$store.dispatch("clearTryAgainMessage");

		await this.$store.dispatch("retrieveAccountDetails").then(response => {
			this.userDetails = response;
		});

		const date = new Date();
		this.startDate = date.toISOString().slice(0, 10);

		const endDate = date.setDate(date.getDate() + 27);
		this.endDate = new Date(endDate).toISOString().slice(0, 10);
	},
	computed: {
		...mapGetters(["standardPriceRate", "tryAgain"]),
		...mapState(["seller", "allAuth"]),
		todaysDate() {
			return new Date().toISOString().slice(0, 10);
		},
		postOverSchedule() {
			return this.dateRange[0] === this.todaysDate;
		},
		isPreExistingDraft() {
			return this.$route.params.id > 0;
		},
		formValid() {
			const foundationalCheck =
				this.dateRange[0] !== null && this.dateRange[1] !== null;

			if (this.useCustomCompanyName) {
				return (
					foundationalCheck &&
					!isEmptyOrSpaces(this.userDetails.myAccountDetails.company)
				);
			} else {
				return foundationalCheck;
			}
		},
		dateRange() {
			return [this.startDate, this.endDate];
		},
		date28daysFromStartDate() {
			let date28daysFromStartDate =
				Date.parse(this.startDate) + 1000 * 60 ** 2 * 24 * 27;
			date28daysFromStartDate = new Date(date28daysFromStartDate)
				.toISOString()
				.slice(0, 10);

			return date28daysFromStartDate;
		}
	},
	methods: {
		formattedDate(date) {
			return moment(date).format("DD MMMM YYYY");
		},
		async postNew() {
			this.loading = true;
			this.errorMessage = null;

			let postId;
			await this.$store
				.dispatch("createDraft", this.seller.newPost)
				.then(response => {
					postId = response.businessId;
				})
				.catch(e => {
					this.errorMessage = e;
				});

			if (this.errorMessage === null) {
				await this.$store
					.dispatch("postDraft", {
						id: postId,
						dates: this.dateRange,
						useCustomCompanyName: this.useCustomCompanyName,
						company: this.userDetails.myAccountDetails.company
					})
					.then(response => {
						response;
						this.$router.push({
							name: "PostDetail",
							params: { id: postId }
						});
					})
					.catch(e => (this.errorMessage = e));
			}

			this.loading = false;
		},
		async postDraft() {
			this.loading = true;

			await this.$store
				.dispatch("postDraft", {
					id: this.$route.params.id,
					dates: this.dateRange,
					useCustomCompanyName: this.useCustomCompanyName,
					company: this.userDetails.myAccountDetails.company
				})
				.then(response => {
					response;
					this.$router.push({
						name: "PostDetail",
						params: { id: this.$route.params.id }
					});
				})
				.catch(e => (this.errorMessage = e));

			this.loading = false;
		}
	}
};
</script>

<style scoped>
.my-datepicker >>> .my-datepicker_calendar {
	width: 100%;
	height: 330px;
}
</style>