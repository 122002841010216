<template>
	<div class="container">
		<h2>Posts in a Draft Stage</h2>
		<postCardList
			:posts="posts"
			:seller="true"
			:loading="loading"
			defaultSort="Created Date"
			:showSort="true"
			:sortByOptions="[
				'Created Date',
				'Reference',
				'Turnover',
				'Heading'
			]"
		/>
	</div>
</template>

<script>
import postCardList from "@/components/postCardList.vue";
import { mapGetters } from "vuex";
import Repository from "@/store/Services/Repository.js";

export default {
	name: "DraftPosts",
	metaInfo() {
		return {
			title: "IP-BID.com - My Draft Posts"
		};
	},
	components: {
		postCardList
	},
	data() {
		return {
			loading: false,
			posts: []
		};
	},
	created() {
		this.loading = true;
	},
	async mounted() {
		await Repository.get("seller/posts?status=7", {
			headers: { Authorization: `Bearer ${this.token}` }
		}).then(response => {
			this.posts = response.data;
		});
		this.loading = false;
	},
	computed: {
		...mapGetters(["token"])
	}
};
</script>
