<template>
	<div>
		<v-row v-if="loading">
			<v-col cols="12" md="6" xl="4">
				<v-skeleton-loader
					ref="skeleton"
					type="image, card-heading"
					tile
				></v-skeleton-loader>
			</v-col>
			<v-col cols="12" md="6" xl="4">
				<v-skeleton-loader
					ref="skeleton"
					type="image, card-heading"
					tile
				></v-skeleton-loader>
			</v-col>
			<v-col cols="12" md="6" xl="4">
				<v-skeleton-loader
					ref="skeleton"
					type="image, card-heading"
					tile
				></v-skeleton-loader>
			</v-col>
			<v-col cols="12" md="6" xl="4">
				<v-skeleton-loader
					ref="skeleton"
					type="image, card-heading"
					tile
				></v-skeleton-loader>
			</v-col>
		</v-row>
		<v-row v-if="showSort && posts.length > 0" class="py-0 my-0">
			<v-col cols="auto">
				<v-btn-toggle v-model="descending" mandatory>
					<v-btn
						depressed
						:value="false"
						:color="!descending ? 'info' : 'grey'"
					>
						<v-icon color="white">mdi-arrow-up</v-icon>
					</v-btn>
					<v-btn
						depressed
						:value="true"
						:color="descending ? 'info' : 'grey'"
					>
						<v-icon color="white">mdi-arrow-down</v-icon>
					</v-btn>
				</v-btn-toggle>
			</v-col>
			<v-col cols="auto">
				<v-select
					:items="sortByOptions"
					v-model="defaultSort"
					label="Sort By"
					color="accent"
					filled
				></v-select>
			</v-col>
		</v-row>
		<v-row>
			<v-col v-if="!loading && !(posts.length > 0)">
				<p>
					Looks like there's nothing here.
					{{
						!seller
							? "Try expanding your preference settings under the account menu."
							: ""
					}}
				</p>
			</v-col>

			<v-row>
				<v-col
					cols="12"
					md="6"
					xl="4"
					:key="post.businessId"
					v-for="post in sortedPosts"
				>
					<buyerPostCard
						:post="post"
						v-if="!seller"
						:cardWidth="cardWidth"
						class="postCard"
					/>
					<sellerPostCard
						:cardWidth="cardWidth"
						:post="post"
						v-if="seller"
					/>
				</v-col>
			</v-row>
		</v-row>
	</div>
</template>

<script>
import buyerPostCard from "./Buyer Components/buyerPostCard.vue";
import sellerPostCard from "./Seller Components/sellerPostCard.vue";
//import { mapGetters } from "vuex";

export default {
	name: "postCardList",
	components: {
		buyerPostCard,
		sellerPostCard
	},
	props: {
		posts: {
			type: Array,
			required: true
		},
		defaultSort: {
			type: String,
			required: true
		},
		seller: {
			type: Boolean
		},
		loading: {
			type: Boolean
		},
		showSort: {
			type: Boolean
		},
		sortByOptions: {
			type: Array
		}
	},
	data() {
		return {
			descending: true,
			windowWidth: window.innerWidth
		};
	},
	mounted() {
		window.onresize = () => {
			this.windowWidth = window.innerWidth;
		};
	},
	computed: {
		//...mapGetters(["postListEmpty"]),
		cardWidth() {
			let width;
			if (this.windowWidth <= 460 && this.windowWidth >= 400) {
				width = 380;
			} else if (this.windowWidth < 400) {
				width = 290;
			} else {
				width = 450;
			}
			return width;
		},
		sortedPosts() {
			const postList = [...this.posts];

			const before = this.descending ? -1 : 1;
			const after = this.descending ? 1 : -1;
			switch (this.defaultSort) {
				case "Start Date":
					return postList.sort((a, b) =>
						Date.parse(a.activeStartDate[0]) >
						Date.parse(b.activeStartDate[0])
							? before
							: after
					);
				case "End Date":
					return postList.sort((a, b) =>
						Date.parse(a.activeEndDate[0]) >
						Date.parse(b.activeEndDate[0])
							? before
							: after
					);
				case "Created Date":
					return postList.sort((a, b) =>
						Date.parse(a.creationDate) > Date.parse(b.creationDate)
							? before
							: after
					);
				case "Reference":
					return postList.sort((a, b) =>
						a.reference.toUpperCase() > b.reference.toUpperCase()
							? before
							: after
					);
				case "Heading":
					return postList.sort((a, b) =>
						a.activityHeading.toUpperCase() >
						b.activityHeading.toUpperCase()
							? before
							: after
					);
				case "Turnover":
					return postList.sort((a, b) =>
						a.turnoverID > b.turnoverID ? before : after
					);
			}
			return [];
		}
	}
};
</script>