<template>
	<div class="container">
		<h2>Help</h2>
		<v-card class="d-inline-flex flex-wrap pa-2" flat outlined>
			<router-link
				class="mx-3"
				style="color: #0083a6"
				:to="{ name: 'Help' }"
				>FAQs</router-link
			>
			<router-link
				:to="{ name: 'ContactUs' }"
				class="mx-3"
				style="color: lightgrey"
				>Contact Us</router-link
			>
		</v-card>
		<v-row
			style="background: white; borderRadius: 3px"
			class="my-2 mx-1 pa-4"
			justify="space-between"
		>
			<v-col
				cols="12"
				md="6"
				:order="item.id"
				v-for="item in questionsAnswers"
				:key="item.id"
			>
				<h3>{{ item.Q }}?</h3>
				<p>{{ item.A }}</p>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: "Help",
	metaInfo() {
		return {
			title: "IP-BID.com - FAQs"
		};
	},
	data() {
		return {
			questionsAnswers: [
				{
					id: 1,
					Q: "What is IP-BID.com",
					A:
						"IP-BID.com is the UK's leading online Insolvency Marketplace, matching buyers and sellers of insolvent businesses and distressed assets.",
				},
				{
					id: 3,
					Q: "How does IP-BID.com work",
					A:
						"The UK’s leading insolvency practitioners post their insolvent businesses and assets for sale on the site, which then automatically matches a particular business for sale with potential buyers. These business opportunities can include companies in administration and pre-packs, as well as companies in receivership or liquidation with significant business assets for sale.",
				},
				{
					id: 5,
					Q: "Who can register",
					A:
						"If you are a potential buyer of distressed businesses and assets, you can register as an Interested Party to access the latest postings from leading UK Insolvency Practitioners. If you want to post a distressed business for sale, you can register now as an Insolvency Practitioner or as an IP’s representative. Registration is free.",
				},
				{
					id: 7,
					Q: "Who can advertise",
					A:
						"Insolvency Practitioners or persons acting on behalf of Insolvency Practitioners can post on IP-BID.com.",
				},
				{
					id: 9,
					Q: "How much does it cost",
					A:
						"IP-BID.com is free for potential buyers. To post an advert on IP-BID.com, the cost is £295 (plus VAT) for 28 days.",
				},
				{
					id: 2,
					Q: "Why can't I see an advert",
					A:
						"If you are an Interested Party, an advertisement may not be appearing in your posting inbox for the following reasons: your preferences may not match the advert in question, or the advert may have been expired, sold or withdrawn.",
				},
				{
					id: 4,
					Q: "Why can't I see who is the Insolvency Practitioner is",
					A: "For confidentiality, the Insolvency Practitioner can decide to include their contact details within the advert, or not, as they choose.",
				},
				{
					id: 6,
					Q:
						"Why haven't I had a response from my expression of interest",
					A:
						"Once an expression of interest has been sent, it is up to the Insolvency Practitioner (or person acting on their behalf) to respond.",
				},
				{
					id: 8,
					Q: "Why can't I find a specific business category",
					A:
						"IP-BID.com uses the Standard Industrial Classification (SIC) for business categories. If you are struggling to indentify a category you have in mind, please use the Contact Us facility.",
				},
				{
					id: 10,
					Q: "How do I unsubscribe",
					A:
						"A link to unsuscribe can be found in the footer of all emails received from IP-BID.com. If you are having trouble locating this link or wish to unsubscribe without viewing an email, please use the Contact Us facility.",
				}
			]
		};
	}
};
</script>
