<template>
	<div>
		<v-data-table
			:loading="loading"
			hide-default-footer
			:page.sync="page"
			:items-per-page="pageSize"
			:headers="headers"
			:items="data"
			item-key="userId"
			:search="searchTerm"
			:custom-sort="customSort"
			:sort-by.sync="sortBy"
			:sort-desc.sync="sortDesc"
		>
			<template v-slot:item.lastLoginDate="{ item }">
				{{ item.lastLoginDate | formatDate }}
			</template>

			<template v-slot:item.createdDate="{ item }">
				{{ item.createdDate | formatDate }}
			</template>

			<template v-slot:item.expressionCount="{ item }">
				{{ item.businessExpressedInterests.length }}
			</template>

			<template v-slot:item.businessCount="{ item }">
				{{ item.businessPosting.length }}
			</template>

			<template v-slot:item.capacityId="{ item }">
				{{ item.capacityId !== null ? "Seller" : "Buyer" }}
			</template>

			<template v-slot:item.capacityId="{ item }">
				{{ item.capacityId !== null ? "Seller" : "Buyer" }}
			</template>

			<template v-slot:item.actions="{ item }">
				<v-icon medium class="mr-2" @click="viewUser(item.userId)">
					mdi-account-search
				</v-icon>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import moment from "moment";

export default {
	name: "userTable",
	props: [
		"data",
		"page",
		"pageSize",
		"searchTerm",
		"filterBuyers",
		"filterSellers"
	],
	data() {
		return {
			loading: false,
			search: null,
			dialog: false,
			sortBy: "lastLoginDate",
			sortDesc: true
		};
	},
	computed: {
		headers() {
			let headers = [
				{ text: "Actions", value: "actions", sortable: false },
				{
					text: "Email",
					align: "start",
					value: "emailAddress1"
				},
				{ text: "Last Logged In", value: "lastLoginDate" },
				{ text: "Forename", value: "forenames" },
				{ text: "Surname", value: "surname" },
				{ text: "Company", value: "company" },
				{ text: "Created", value: "createdDate" }
			];

			if (this.filterBuyers) {
				headers.push({
					text: "Expressions",
					value: "expressionCount"
				});
			} else if (this.filterSellers) {
				headers.push({ text: "Businesses", value: "businessCount" });
			}

			return headers;
		}
	},
	filters: {
		formatDate(date) {
			return moment(date).format("DD/MM/YY");
		}
	},
	methods: {
		customSort(items, index, isDescending) {
			const before = isDescending[0] ? 1 : -1;
			const after = before * -1;

			items.sort((a, b) => {
				if (index[0] === "expressionCount") {
					return a.businessExpressedInterests.length >
						b.businessExpressedInterests.length
						? before
						: after;
				} else if (index[0] === "businessCount") {
					return a.businessPosting.length > b.businessPosting.length
						? before
						: after;
				} else if (index[0] === "emailAddress1") {
					return a.emailAddress1 > b.emailAddress1 ? before : after;
				} else if (index[0] === "lastLoginDate") {
					return a.lastLoginDate > b.lastLoginDate ? before : after;
				} else if (index[0] === "forenames") {
					return a.forenames > b.forenames ? before : after;
				} else if (index[0] === "surname") {
					return a.surname > b.surname ? before : after;
				} else if (index[0] === "company") {
					return a.company > b.company ? before : after;
				} else if (index[0] === "company") {
					return a.company > b.company ? before : after;
				} else if (index[0] === "createdDate") {
					return a.createdDate > b.createdDate ? before : after;
				}
			});

			return items;
		},
		viewUser(id) {
			window.open("/admin/user/" + id);
		}
	}
};
</script>