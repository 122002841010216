<template>
	<div class="container">
		<p>
			You have successfully completed registration and created an account.
			We need you to verify your email address. An email has been sent to
			your email address with a link for you to confirm. If you are having
			trouble locating the email or confirming your email address, please
			use the 'Contact Us' facility.
		</p>
	</div>
</template>

<script>
export default {
	name: "RegistrationSuccess"
};
</script>