   <template>
	<VueRecaptcha
		sitekey="6LflB7IZAAAAAINQQDlPOKfDG7MYWLLO8W8EQKqM"
		:loadRecaptchaScript="true"
		@verify="validate"
        @expired="expireRecaptcha"
	/>
</template>


<script>
import VueRecaptcha from "vue-recaptcha";
import Validation from "@/store/Services/recaptchaValidation";

export default {
	components: { VueRecaptcha },
	methods: {
		validate(response) {
			Validation.validate({ Response: response })
				.then(result => {
                    this.$emit("validate", true);
                    result;
				})
				.catch(error => {
                    error;
                });
        },
        expireRecaptcha(){
            this.$emit("expired");
        }
	}
};
</script>