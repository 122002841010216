<template>
	<div class="my-4">
		<v-card class="d-inline-flex flex-wrap pa-2" flat outlined>
			<router-link
				class="mx-3"
				:style="
					active === 'LocationSize'
						? 'color: #0083a6'
						: 'color: lightgrey'
				"
				:to="{ name: 'AmendLocationSizePreferences' }"
				>Location Size</router-link
			>
			<router-link
				:to="{ name: 'AmendCategoryPreferences' }"
				class="mx-3"
				:style="
					active === 'Categories'
						? 'color: #0083a6'
						: 'color: lightgrey'
				"
				>Categories</router-link
			>
			<router-link
				:to="{ name: 'AmendKeywordPreferences' }"
				class="mx-3"
				:style="
					active === 'Keywords'
						? 'color: #0083a6'
						: 'color: lightgrey'
				"
				>Keywords</router-link
			>
			<router-link
				:to="{ name: 'AmendAssetPreferences' }"
				class="mx-3"
				:style="
					active === 'Assets' ? 'color: #0083a6' : 'color: lightgrey'
				"
				>Assets</router-link
			>
		</v-card>
	</div>
</template>

<script>
export default {
	name: "preferenceNavItems",
	metaInfo() {
		return {
			title: "IP-BID.com - My Preferences"
		};
	},
	props: ["active"]
};
</script>