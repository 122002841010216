<template>
	<v-app>
		<div id="app">
			<v-app-bar
				fixed
				flat
				id="appBar"
				class="pa-1 unfixOnPrint"
				:min-height="isMobile ? 58 : 70"
			>
				<v-list-item style="padding: 0;"
					><router-link :to="{ name: 'ConditionalHome' }">
						<img
							:width="isMobile ? '150px' : '240px'"
							src="@/assets/icons/IP-BID.com.svg"
							alt="IP-BID"
						/>
					</router-link>
					<h4 class="pa-3 my-3 mx-6 d-none d-md-inline">
						The UK's Leading <br class="d-lg-none" />
						<span style="color: #0083a6"
							>Insolvency Marketplace</span
						>
					</h4>
				</v-list-item>
				<v-spacer></v-spacer>
				<v-list-item
					style="flex: 0 0 0; padding: 0px 1vw 0px 1vw;"
					:to="{ name: 'Login' }"
					v-if="!isLoggedIn"
				>
					<span class="d-none d-sm-flex"> <a>Login</a></span>
					<v-avatar size="40" contain alt="Login"
						><img
							src="@/assets/icons/everyone_my account.svg"
							alt="Login"
					/></v-avatar>
				</v-list-item>

				<myAccountMenu v-if="isLoggedIn" class="hideOnPrint" />

				<v-divider
					vertical
					class="mx-1 hideOnPrint"
					v-if="role != 'Admin'"
				></v-divider>

				<v-list-item
					style="flex: 0 0 0; padding: 0px 1vw 0px 1vw;"
					:to="{ name: 'Help' }"
					v-if="role != 'Admin'"
					class="hideOnPrint"
				>
					<span class="d-none d-sm-flex"> <a>Help</a></span>
					<v-avatar size="40" contain alt="Help"
						><img src="@/assets/icons/misc_help.svg" alt="Help"
					/></v-avatar>
				</v-list-item>

				<v-divider vertical class="mx-1 hideOnPrint"></v-divider>

				<v-list-item
					style="flex: 0 0 0%; min-width: 160px"
					class="d-none d-md-flex"
				>
					<clock />
				</v-list-item>

				<v-list-item
					style="flex: 0 0 0%; padding: 0"
					v-if="isLoggedIn"
					class="d-flex d-lg-none"
				>
					<v-app-bar-nav-icon
						class="hideOnPrint"
						@click.stop="drawer = !drawer"
					></v-app-bar-nav-icon>
				</v-list-item>
			</v-app-bar>

			<v-navigation-drawer v-model="drawer" fixed temporary right>
				<sideNav :isMobile="true" />
				<template v-slot:append>
					<div class="pa-5">
						<v-btn block color="secondary" @click="logout" tile
							>Logout</v-btn
						>
					</div>
				</template>
			</v-navigation-drawer>

			<sideNav
				v-if="isLoggedIn"
				class="d-none d-lg-block"
				:isMobile="false"
			/>

			<router-view
				class="routerView"
				:class="isInternetExplorer && 'IEStyles'"
			/>

			<!-- v-show to hide footer appearing on Invoices and Certificates -->
			<v-footer
				absolute
				padless
				color="primary"
				class="footer"
				:style="isInternetExplorer && 'backgroundColor: #1e3354'"
				v-show="$route.name !== 'InvoiceOutput' && $route.name !== 'CertificateOutput'"
			>
				<div class="container">
					<v-container fluid>
						<v-row>
							<!-- Temporarily Removed While the More Information Text is Undecided
							<v-col cols="12" md="8">
								<h3 style="color: #eee !important">
									More Information
								</h3>
								<p>
									Molorecea nonecabor aligenim estem nobit
									fugiatia sit omnimo tem autas derovidenis
									explication reicili quuntem rest, suntiore
									seque plaut quod eossiminctio cuptiunt es et
									iligenimi
								</p>
							</v-col>
							-->
							<v-col cols="12" md="4">
								<h3 style="color: #eee !important">
									More Links
								</h3>
								<ul style="padding-left: 0">
									<li class="footerLink">
										<router-link
											:to="{ name: 'PrivacyPolicy' }"
											>Privacy Policy</router-link
										>
									</li>
									<li class="footerLink">
										<router-link :to="{ name: 'ContactUs' }"
											>Contact Information</router-link
										>
									</li>
									<li class="footerLink">
										<router-link :to="{ name: 'TCs' }"
											>Terms and Conditions</router-link
										>
									</li>
								</ul>
							</v-col>
						</v-row>
					</v-container>
				</div>
			</v-footer>
		</div>
	</v-app>
</template>


<script>
import { mapGetters } from "vuex";
import clock from "./components/Visitor Components/clock.vue";

export default {
	components: {
		//Lazy Load Components that may not necessarily need to be loaded
		sideNav: () => import("./components/sideNav.vue"),
		myAccountMenu: () => import("./components/myAccountMenu.vue"),
		clock
	},
	metaInfo() {
		return {
			title:
				"Insolvent Businesses, Businesses for Sale, UK Online Insolvency Marketplace: IP-BID.com"
		};
	},
	data() {
		return {
			drawer: null,
			windowWidth: window.innerWidth
		};
	},
	mounted() {
		window.onresize = () => {
			this.windowWidth = window.innerWidth;
		};
	},
	computed: {
		...mapGetters(["isLoggedIn", "role", "notificationsLoaded"]),
		isMobile() {
			return this.windowWidth < 960;
		},
		isInternetExplorer() {
			return /*@cc_on!@*/ false || !!document.documentMode;
		}
	},
	methods: {
		logout() {
			this.$store.dispatch("logOut").then(response => {
				this.$router.push({ name: "ConditionalHome" });
				response;
			});

			this.drawer = false;
		}
	}
};
</script>


<!-- Global (Not Scoped) Styles -->
<style lang="sass">
$mobile: 960px

#app
	font-family: "Roboto", sans-serif
	background: #eaf5fb

	::selection
		background: #eeba36
	::-moz-selection
		background: #eeba36

	#appBar
		background-color: white
		border-bottom: 1px solid rgba(58, 63, 75, 0.3)

	.routerView
		//Styles to Prevent Footer Overlap
		@media screen and (min-width: $mobile)
			margin-bottom: 225px
			margin-top: 70px

		@media screen and (max-width: $mobile)
			margin-bottom: 350px
			margin-top: 58px

	h1, h2, h3, h4, h5
		color: #1e3354

	ul
		list-style-type: none
	a
		text-decoration: none

	a
		font-weight: bold
		color: primary

	&.router-link-exact-active
		color: accent

	footer
		padding: 5px
		margin-top: 50px
		font-size: 0.9em
		color: white
		border-top: 1px solid

	.footerLink a
		color: white
		font-weight: 400
		transition: 0.25s

	.footerLink a:hover
		color: #eeba36
		transition: 0.25s

	.IEStyles
		.primary
			background-color: #1e3354
			color: white

		.secondary
			background-color: #0083a6
			color: white

		.accent
			background-color: #eeba36

		.info
			background-color: #86ae41
			color: white

	@media (min-width: 1900px)
		.container
			max-width: 1600px

	@media (min-width: 1260px) and (max-width: 1400px)
		.container
			max-width: 1050px

	@media print
		.hideOnPrint
			visibility: hidden

		.unfixOnPrint
			position: relative

		.row
			margin-right: 0px

		.py-12 
			padding-top: 0px !important
		
		.my-12
			margin-top: 0px !important

		.spacer
			padding: 12px
</style>