<template>
	<div class="container">
		<noCriteriaSet v-if="this.buyer.showNoCriteriaSet"
		/>
		<h2>Your Posting Inbox</h2>
		<postCardList
			:posts="buyer.postList"
			:seller="false"
			defaultSort="Start Date"
			:loading="buyer.postsLoading"
			:showSort="true"
			:sortByOptions="['Start Date', 'Heading', 'Turnover']"
		/>
	</div>
</template>

<script>
import postCardList from "@/components/postCardList.vue";
import noCriteriaSet from "@/components/noCriteriaSet.vue";
import { mapState } from "vuex";

export default {
	name: "PostingInbox",
	metaInfo() {
		return {
			title:
				"IP-BID.com - Your Posting Inbox"
		};
	},
	components: {
		postCardList,
		noCriteriaSet
	},
	mounted() {
		this.buyer.postList.length === 0 &&
			this.$store.dispatch("retrievePostsByCriteria");
		this.$store.dispatch("retrieveShowNoCriteriaSet");
	},
	computed: {
		...mapState(["buyer"])
	},
	beforeDestroy() {
		this.$store.dispatch("clearPostList");
	}
};
</script>
