<template>
	<div class="container">
		<h2>Amend Account Details</h2>
		<v-card class="d-inline-flex flex-wrap pa-2" flat outlined>
			<router-link
				class="mx-3"
				style="color: #0083a6"
				:to="{ name: 'AmendAccountDetails' }"
				>Personal Details</router-link
			>
			<router-link
				:to="{ name: 'AmendAddressDetails' }"
				class="mx-3"
				style="color: lightgrey"
				>Address And Phone Details</router-link
			>
		</v-card>

		<v-row
			style="background: white; borderRadius: 3px"
			class="my-2 mx-1 pa-3"
		>
			<v-col cols="12" md="6">
				<v-select
					:items="labels.labels.titleLabel"
					item-text="description"
					item-value="titleId"
					v-model="form.myAccountDetails.titleId"
					label="Title"
				></v-select>

				<v-text-field
					label="Forenames"
					v-model="form.myAccountDetails.forenames"
				></v-text-field>

				<v-text-field
					label="Surename"
					v-model="form.myAccountDetails.surname"
				></v-text-field>
				<v-text-field
					label="Email"
					type="email"
					disabled
					v-model="form.myAccountDetails.emailAddress1"
				></v-text-field>
			</v-col>
			<v-col cols="12" md="6">
				<v-text-field
					label="Second Email"
					type="email"
					v-model="form.myAccountDetails.emailAddress2"
				></v-text-field>

				<v-text-field
					label="Company Name"
					v-model="form.myAccountDetails.company"
				></v-text-field>

				<v-text-field
					label="Position"
					v-model="form.myAccountDetails.position"
				></v-text-field>
			</v-col>
		</v-row>
		<v-btn
			tile
			color="secondary"
			:loading="loading"
			:disabled="triggerSaveText"
			@click.prevent="saveChanges"
			>Save Changes</v-btn
		>
		<savedAnimation
			:text="successMessage"
			:showTick="true"
			:triggered="triggerSaveText"
		/>
		<p v-if="errorMessage !== null">{{ errorMessage }}</p>
	</div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import savedAnimation from "@/components/savedAnimation.vue";

export default {
	name: "AmendAccountDetails",
	metaInfo() {
		return {
			title: "IP-BID.com - My Account Details"
		};
	},
	components: {
		savedAnimation
	},
	data() {
		return {
			form: {},
			successMessage: null,
			triggerSaveText: false,
			errorMessage: null,
			loading: false
		};
	},

	computed: {
		...mapGetters(["role"]),
		...mapState(["allAuth", "labels"])
	},
	mounted() {
		this.$store.dispatch("retrieveSingleLabelSet", "title");

		this.$store.dispatch("retrieveAccountDetails").then(response => {
			this.form = response;
		});
	},
	methods: {
		async saveChanges() {
			this.loading = true;
			this.errorMessage = null;

			//Email Address Cannot Be Empty String
			if (this.form.myAccountDetails.emailAddress2 === "") this.form.myAccountDetails.emailAddress2 = null;

			await this.$store
				.dispatch(
					"saveAccountDetailChanges",
					this.form.myAccountDetails
				)
				.then(response => {
					response;
					this.successMessage = "Changes Successfully Saved";
					this.triggerSaveText = true;
				})
				.catch(e => {
					e;
					this.errorMessage = "Problem with saving changes";
				});

			await this.$store
				.dispatch("retrieveAccountDetails")
				.then(response => {
					this.form = response;
					//this.beforeForm = JSON.parse(JSON.stringify(response));
				});
			this.loading = false;

			await new Promise(resolve => setTimeout(resolve, 2800)); //Length of Animation
			this.triggerSaveText = false;
		}
	}
};
</script>
