<template>
	<div class="container">
		404 Not Found
		<a
			><router-link :to="{ name: 'ConditionalHome' }"
				>Return Home?</router-link
			></a
		>
	</div>
</template>

<script>
export default {
	name: "NotFound"
};
</script>