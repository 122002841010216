<template>
	<div class="container">
		<h2>Admin Dashboards (Beta)</h2>
		<iframe
			width="100%"
			height="750px"
			src="https://app.powerbi.com/reportEmbed?reportId=7d0e150d-f8a6-42ad-b4fc-8db7df9f8dc9&autoAuth=true&ctid=7e269149-ab30-482b-a02d-5cc2245ba22b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D"
			frameborder="0"
			allowFullScreen="true"
		></iframe>
	</div>
</template>

<script>
export default {
	name: "Dashboards"
};
</script>