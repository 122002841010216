var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[(_vm.isPreExistingDraft)?_c('v-btn',{attrs:{"tile":"","small":"","color":"primary","text":"","to":{
			name: 'PostDetail'
		}}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_vm._v(" to post")],1):_vm._e(),_c('h2',[_vm._v(_vm._s(_vm.isPreExistingDraft ? "Post Draft" : "Post New"))]),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
		var on = ref.on;
		var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formattedDate(_vm.startDate),"label":"Post Start Date","readonly":"","append-icon":'mdi-pencil',"color":"accent"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startMenu),callback:function ($$v) {_vm.startMenu=$$v},expression:"startMenu"}},[_c('v-date-picker',{attrs:{"min":_vm.todaysDate,"max":_vm.endDate},on:{"input":function($event){_vm.startMenu = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
		var on = ref.on;
		var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formattedDate(_vm.endDate),"label":"Post End Date","readonly":"","append-icon":'mdi-pencil',"color":"accent"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endMenu),callback:function ($$v) {_vm.endMenu=$$v},expression:"endMenu"}},[_c('v-date-picker',{attrs:{"min":_vm.startDate,"show-current":_vm.startDate && _vm.date28daysFromStartDate},on:{"input":function($event){_vm.endMenu = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Would you like the invoice to be addressed to a different company name? "),_c('v-btn-toggle',{attrs:{"mandatory":"","color":_vm.useCustomCompanyName
							? 'info'
							: 'var(--v-error-base)'},model:{value:(_vm.useCustomCompanyName),callback:function ($$v) {_vm.useCustomCompanyName=$$v},expression:"useCustomCompanyName"}},[_c('v-btn',{attrs:{"small":"","value":false}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"small":"","value":true}},[_vm._v(" Yes ")])],1)],1),_c('v-text-field',{attrs:{"label":"Company Name on Invoice","disabled":!_vm.useCustomCompanyName,"counter":"","maxlength":"50"},model:{value:(_vm.userDetails.myAccountDetails.company),callback:function ($$v) {_vm.$set(_vm.userDetails.myAccountDetails, "company", $$v)},expression:"userDetails.myAccountDetails.company"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('invoiceCalculator',{attrs:{"dateRange":_vm.dateRange,"price":_vm.userDetails.myAccountDetails.customPriceRate ||
						_vm.standardPriceRate}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_vm._v(" Reminder: Clause 11 of our T's & C's. By confirming a Posting on IP-BID, the User agrees to pay the appropriate fees. ")])],1),(!_vm.isPreExistingDraft)?_c('v-btn',{attrs:{"tile":"","color":"secondary","disabled":!_vm.formValid,"loading":_vm.loading},on:{"click":_vm.postNew}},[_vm._v(_vm._s(_vm.postOverSchedule ? "Post Now" : "Schedule"))]):_vm._e(),(_vm.isPreExistingDraft)?_c('v-btn',{attrs:{"tile":"","color":"secondary","disabled":!_vm.formValid,"loading":_vm.loading},on:{"click":_vm.postDraft}},[_vm._v(_vm._s(_vm.postOverSchedule ? "Post Now" : "Schedule"))]):_vm._e(),_vm._v(" "+_vm._s(_vm.tryAgain)+" "),(_vm.errorMessage !== null)?_c('p',[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }