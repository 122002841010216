<template>
	<div class="container py-12 my-12" v-if="loaded" style="fontSize: 0.9em">
		<v-speed-dial
			fixed
			bottom
			right
			style="bottom: 50px"
			class="hideOnPrint"
		>
			<template v-slot:activator>
				<v-btn color="info" fab onClick="window.print()">
					<v-icon>mdi-printer</v-icon>
				</v-btn>
			</template>
		</v-speed-dial>
		<v-row justify="space-around">
			<v-col cols="auto" style="min-width: 600px">
				<v-row><h1>Certificate</h1></v-row>

				<v-row>
					<v-col md="4" cols="auto">
						<v-row> Posting ID:</v-row>
						<v-row> Reference:</v-row>
					</v-col>

					<v-col md="6" cols="auto">
						<v-row>
							{{ certificateDetail.period.invoice.businessId }}
						</v-row>

						<v-row>
							{{ certificateDetail.period.invoice.reference }}
						</v-row>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="auto">
						<v-row>
							This is to certify, that:
						</v-row>

						<v-row>
							<h2>{{ certificateDetail.period.invoice.forenames }} {{ certificateDetail.period.invoice.surname }} of {{ certificateDetail.period.userCompany }}</h2>
						</v-row>

						<v-row>
							advertised the below opportunity on IP-Bid.com between:
						</v-row>

						<v-row>
							{{ certificateDetail.period.startDate | formatDate }} and {{ certificateDetail.period.endDate | formatDate }}
						</v-row>
					</v-col>
				</v-row>

				<v-divider></v-divider>

				<v-row>
					<v-col md="4" cols="auto">
						<v-row>Appointment:</v-row>
					</v-col>
					
					<v-col md="6" cols="auto">
						<v-row>
							{{ certificateDetail.period.business.businessStatus.description }}
						</v-row>
					</v-col>
				</v-row>

				<v-row>
					<v-col md="4" cols="auto">
						<v-row>Trading:</v-row>
					</v-col>
					
					<v-col md="6" cols="auto">
						<v-row>
							{{ certificateDetail.period.business.tradingStatus.description }}
						</v-row>
					</v-col>
				</v-row>
				
				<v-row>
					<v-col md="4" cols="auto">
						<v-row>Primary Category:</v-row>
					</v-col>
					
					<v-col md="6" cols="auto">
						<v-row v-if="certificateDetail.period.business.businessPostingCategory.filter(c => c.primaryCategory == true).length > 0">
							{{ certificateDetail.period.business.businessPostingCategory.filter(c => c.primaryCategory == true)[0].category.description }}
						</v-row>
					</v-col>
				</v-row>

				<v-row>
					<v-col md="4" cols="auto">
						<v-row>Additional Categories:</v-row>
					</v-col>
					
					<v-col md="6" cols="auto">
						<v-row v-if="certificateDetail.period.business.businessPostingCategory.filter(c => c.primaryCategory == false).length > 0">
							{{ certificateDetail.period.business.businessPostingCategory.filter(c => c.primaryCategory == false).map(c => c.category.description).join(', ') }}
						</v-row>
					</v-col>
				</v-row>

				<v-row>
					<v-col md="4" cols="auto">
						<v-row>Locations:</v-row>
					</v-col>
					
					<v-col md="6" cols="auto">
						<v-row v-if="certificateDetail.period.business.businessPostingLocation.length > 0">
							{{ certificateDetail.period.business.businessPostingLocation.map(c => c.location.description).join(', ') }}
						</v-row>
					</v-col>
				</v-row>

				<v-row>
					<v-col md="4" cols="auto">
						<v-row>Turnover:</v-row>
					</v-col>
					
					<v-col md="6" cols="auto">
						<v-row>
							{{ certificateDetail.period.business.turnover.description }}
						</v-row>
					</v-col>
				</v-row>

				<v-row>
					<v-col md="4" cols="auto">
						<v-row>Employees:</v-row>
					</v-col>
					
					<v-col md="6" cols="auto">
						<v-row>
							{{ certificateDetail.period.business.employees.description }}
						</v-row>
					</v-col>
				</v-row>

				<v-row>
					<v-col md="4" cols="auto">
						<v-row>Assets:</v-row>
					</v-col>
					
					<v-col md="6" cols="auto">
						<v-row v-if="certificateDetail.period.business.businessPostingAsset.length > 0">
							{{ certificateDetail.period.business.businessPostingAsset.map(c => c.asset.description).join(', ') }}
						</v-row>
					</v-col>
				</v-row>
				
				<v-row>
					<v-col cols="auto">
						<v-row>Description:</v-row>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="auto">
						<v-row>
							{{ certificateDetail.period.business.description }}
						</v-row>
					</v-col>
				</v-row>

				<v-spacer></v-spacer>

				<v-divider></v-divider>

				<v-row>
					<v-col md="6" cols="auto">
						<v-row>No of interested parties notified by email: {{ certificateDetail.period.totalNoOfInterestedPartiesEmailed }}</v-row>
					</v-col>
					
					<v-col md="6" cols="auto">
						<v-row>Number of expressions of interest received: {{ certificateDetail.expressedInterestsCount }}</v-row>
					</v-col>
				</v-row>

			</v-col>
			
		</v-row>

		<v-footer
			tile
			outlined
			fixed
			padless
			class="text-center unfixOnPrint"
			style="background; #eaf5fb; color: black; border-top: 0px">
			<v-row style="fontSize: 0.8em">
				<v-col>
					<span>IP-BID Limited</span>
					<v-divider vertical class="mx-3"></v-divider>
					<span>
						The Control Tower, Witchcraft Way, Rackheath, Norwich
						NR13 6GA
					</span>
					<v-divider vertical class="mx-3"></v-divider>
					<span>support@ip-bid.com</span>
				</v-col>
			</v-row>
		</v-footer>
	</div>
</template>

<script>
import Repository from "@/store/Services/Repository.js";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
	name: "CerificateOutput",
	data() {
		return {
			certificateDetail: {},
			loaded: false
		};
	},
	async mounted() {
		await Repository.get("seller/certificate?id=" + this.$route.params.id, {
			headers: { Authorization: `Bearer ${this.token}` }
		}).then(response => {
			this.certificateDetail = response.data;
			this.loaded = true;
		});
	},
	computed: {
		...mapGetters(["token"])
	},
	filters: {
		formatDate(date) {
			return moment(date).format("DD MMMM YYYY");
		}
	}
};
</script>
