<template>
	<div class="container">
		<h2>Amend Account Details</h2>
		<v-card class="d-inline-flex flex-wrap pa-2" flat outlined>
			<router-link
				class="mx-3"
				style="color: lightgrey"
				:to="{ name: 'AmendAccountDetails' }"
				>Personal Details</router-link
			>
			<router-link
				:to="{ name: 'AmendAddressDetails' }"
				class="mx-3"
				style="color: #0083a6"
				>Address And Phone Details</router-link
			>
		</v-card>
		<v-row style="background: white; borderRadius: 3px" class="my-2 mx-1 pa-3">
			<v-col cols="12" md="6">
				<v-text-field
					label="Address Line 1"
					v-model="form.accountAddress.addressLine1"
				></v-text-field>

				<v-text-field
					label="Address Line 2"
					v-model="form.accountAddress.addressLine2"
				></v-text-field>

				<v-text-field
					label="Address Line 3"
					v-model="form.accountAddress.addressLine3"
				></v-text-field>
				<v-text-field
					label="Town / City"
					v-model="form.accountAddress.townCity"
				></v-text-field>
				<v-text-field
					label="County"
					v-model="form.accountAddress.county"
				></v-text-field>
			</v-col>
			<v-col cols="12" md="6">
				<v-text-field
					label="Post Code"
					:error-messages="postcodeErrors"
					v-model="form.accountAddress.postcode"
				></v-text-field>
				<v-select
					:items="labels.labels.countryLabel"
					item-text="name"
					item-value="code"
					v-model="form.accountAddress.countryCode"
					label="Country"
				></v-select>

				<v-text-field
					label="Telephone"
					type="number"
					v-model="form.accountAddress.telephone"
				></v-text-field>
				<v-text-field
					label="Mobile"
					type="number"
					v-model="form.accountAddress.mobile"
				></v-text-field>
			</v-col>
		</v-row>
		<v-btn
			:loading="loading"
			:disabled="triggerSaveText"
			tile
			color="secondary"
			@click.prevent="saveChanges"
			>Save Changes</v-btn
		>
		<savedAnimation
			:text="successMessage"
			:showTick="true"
			:triggered="triggerSaveText"
		/>
		<p v-if="errorMessage !== null">{{ errorMessage }}</p>
	</div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import savedAnimation from "@/components/savedAnimation.vue";

export default {
	name: "AmendAddressDetails",
	metaInfo() {
		return {
			title: "IP-BID.com - My Address Details"
		};
	},
	components: {
		savedAnimation
	},
	data() {
		return {
			form: {},
			successMessage: null,
			triggerSaveText: false,
			errorMessage: null,
			loading: false
		};
	},
	computed: {
		...mapGetters(["role"]),
		...mapState(["allAuth", "labels"]),

		postcodeErrors() {
			const errors = [];
			this.form.accountAddress.postcode.length > 10 &&
				errors.push("Post Code must be less than 10 characters");

			return errors;
		}
	},
	mounted() {
		this.$store.dispatch("retrieveAccountDetails").then(response => {
			this.form = response;
		});

		this.$store.dispatch("retrieveSingleLabelSet", "country");
	},
	methods: {
		async saveChanges() {
			if (this.postcodeErrors.length === 0) {
				this.loading = true;
				this.errorMessage = null;

				await this.$store
					.dispatch(
						"saveAddressDetailChanges",
						this.form.accountAddress
					)
					.then(response => {
						response;
						this.successMessage = "Changes Successfully Saved";
						this.triggerSaveText = true;
					})
					.catch(e => {
						e;
						this.errorMessage = "Problem with saving changes";
					});

				await this.$store
					.dispatch("retrieveAccountDetails")
					.then(response => {
						this.form = response;
					});
				this.loading = false;

				await new Promise(resolve => setTimeout(resolve, 2800)); //Length of Animation
				this.triggerSaveText = false;
			}
		}
	}
};
</script>

<style lang="sass" scoped>
.routerLink:hover
	color: #0083a6
	transition: 0.3s
</style>