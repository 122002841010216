import Repository from "../Services/Repository.js";

export default {
	state: {
		postList: [],
		postsLoading: false,
		expressionsOfInterest: [],
		keywords: null,
		categoryPreferences: [],
		locationSizePreferences: {},
		assetPreferences: [],
		//postListByField: [],
		buyerError: null,
		showNoCriteriaSet: false
	},
	getters: {},
	mutations: {
		SET_POSTS(state, postList) {
			state.postList = postList;
		},
		SET_POST_LOAD(state, value) {
			state.postsLoading = value;
		},
		SET_EXPRESSIONS_OF_INTEREST(state, expressions) {
			state.expressionsOfInterest = expressions;
		},
		SET_KEYWORDS(state, keywords) {
			state.keywords = keywords;
		},
		SET_CATEGORY_PREFERENCES(state, categories) {
			state.categoryPreferences = categories;
		},
		SET_LOCATION_SIZE_PREFERENCES(state, locationSize) {
			state.locationSizePreferences = locationSize;
		},
		SET_ASSET_PREFERENCES(state, assets) {
			state.assetPreferences = assets
		},
		SET_POST_LIST_BY_FIELD(state, posts) {
			state.postListByField = posts;
		},
		SET_SHOW_NO_CRITERIA_SET(state, value) {
			state.showNoCriteriaSet = value;
		},
		SET_BUYER_ERROR(state, e) {
			state.buyerError = e;
		}
	},
	actions: {
		async retrievePostsByCriteria(context) {
			context.commit('SET_POST_LOAD', true);

			const token = localStorage.getItem("access_token");

			await Repository.get('buyer/posts-by-criteria', {
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(
					response => {
						context.commit("SET_POSTS", response.data);
					});

			context.commit('SET_POST_LOAD', false);
		},
		clearPostList(context) {
			context.commit("SET_POSTS", []);
		},
		retrieveShowNoCriteriaSet(context) {
			const showNoCriteria = JSON.parse(localStorage.getItem("showNoCriteria"));
			if (showNoCriteria !== null)
			{
				context.commit("SET_SHOW_NO_CRITERIA_SET", showNoCriteria);
			} 
			else
			{			
				const token = localStorage.getItem("access_token");
				Repository.get('buyer/optional-criteria-set', {
					headers: { Authorization: `Bearer ${token}` }
				})
				.then(
					response => {
						context.commit("SET_SHOW_NO_CRITERIA_SET", !response.data.optionalCriteriaSet);
					});
			}
			
		},
		retrieveInterestedPosts(context) {
			const token = localStorage.getItem("access_token");

			Repository.get('buyer/interested-posts', {
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(
					response => {
						context.commit("SET_EXPRESSIONS_OF_INTEREST", response.data);
					});
		},
		expressInterest: async function (context, payload) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.post('buyer/express-interest?id=' + payload.businessId, { ExpressInterestText: payload.expressionText }, {
					headers: { Authorization: `Bearer ${token}` }
				})
					.then(response => resolve(response.data))
					.catch(e => {
						context.commit('SET_BUYER_ERROR', e);
						reject(e.response.data.response);
					})
			})
		},

		//Retrieve and Amend Preference Endpoints
		/*
retrieveKeywords(context) {
	const token = localStorage.getItem("access_token");

	Repository.get('buyer/keywords', {
		headers: { Authorization: `Bearer ${token}` }
	})
		.then(response => { context.commit('SET_KEYWORDS', response.data) })
		.catch(e => { context.commit('SET_BUYER_ERROR', e) });
},
amendKeywords: async function (context, keywords) {
	const token = localStorage.getItem("access_token");

	return new Promise((resolve, reject) => {
		Repository.put('buyer/keywords', { keywords }, {
			headers: { Authorization: `Bearer ${token}` }
		})
			.then(response => {
				resolve(response.data)
			})
			.catch(e => {
				context.commit('SET_BUYER_ERROR', e);
				reject(e.response.data.response);
			});
	})
},

retrieveCategoryPreferences: async function (context) {
	const token = localStorage.getItem("access_token");

	Repository.get('buyer/category-preferences', {
		headers: { Authorization: `Bearer ${token}` }
	})
		.then(response => {
			context.commit('SET_CATEGORY_PREFERENCES', response.data)

		})
		.catch(e => {
			context.commit('SET_BUYER_ERROR', e);

		});

},
amendCategoryPreferences: async function (context, categories) {
	const token = localStorage.getItem("access_token");

	return new Promise((resolve, reject) => {
		Repository.put('buyer/category-preferences', { 'Categories': categories }, {
			headers: { Authorization: `Bearer ${token}` }
		})
			.then(response => {
				resolve(response.data)
			})
			.catch(e => {
				context.commit('SET_BUYER_ERROR', e);
				reject(e.response.data.response);
			});
	})
},

retrieveLocationSizePreferences: async function (context) {
	const token = localStorage.getItem("access_token");

	Repository.get('buyer/location-size-preferences', {
		headers: { Authorization: `Bearer ${token}` }
	})
		.then(response => { context.commit('SET_LOCATION_SIZE_PREFERENCES', response.data) })
		.catch(e => { context.commit('SET_BUYER_ERROR', e) });
},
amendLocationSizePreferences: async function (context, form) {
	const token = localStorage.getItem("access_token");

	return new Promise((resolve, reject) => {
		Repository.put('buyer/location-size-preferences',
			form, {
			headers: { Authorization: `Bearer ${token}` }
		})
			.then(response => {
				resolve(response.data)
			})
			.catch(e => {
				context.commit('SET_BUYER_ERROR', e);
				reject(e.response.data.response);
			});
	})
},

retrieveAssetPreferences: async function (context) {
	const token = localStorage.getItem("access_token");

	Repository.get('buyer/asset-preferences', {
		headers: { Authorization: `Bearer ${token}` }
	})
		.then(response => { context.commit('SET_ASSET_PREFERENCES', response.data) })
		.catch(e => { context.commit('SET_BUYER_ERROR', e) });
},
amendAssetPreferences: async function (context, assets) {
	const token = localStorage.getItem("access_token");

	return new Promise((resolve, reject) => {
		Repository.put('buyer/asset-preferences', assets, {
			headers: { Authorization: `Bearer ${token}` }
		})
			.then(response => {
				resolve(response.data)
			})
			.catch(e => {
				context.commit('SET_BUYER_ERROR', e);
				reject(e.response.data.response);
			});
	})
},
*/

		//Retrieve Post Endpoints
		retrievePostsByField: async function (context, payload) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				const validFieldNames = ['category', 'turnover', 'employees', 'location']

				if (!validFieldNames.includes(payload.field)) reject('Invalid Field Name');

				Repository.get('buyer/posts-by-' + payload.field + '?id=' + payload.id, {
					headers: { Authorization: `Bearer ${token}` }
				})
					.then(response => {
						context.commit('SET_POST_LIST_BY_FIELD', response.data);
						resolve(response.data);
					})
					.catch(e => {
						context.commit('SET_BUYER_ERROR', e);
						reject(e.response.data.response);
					});
			})
		},

	}
};
