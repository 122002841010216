<template>
	<div>
		<v-hover v-slot:default="{ hover }">
			<v-alert
				border="left"
				color="secondary"
				:elevation="hover ? 6 : 0"
				text
			>
				<v-row class="mx-0 pa-0">
					<v-col cols="12" sm="7" style="padding: 4px">
						<span
							>Certificate for
							<strong>{{ reference }} </strong>
							advertised between 
							<strong>
								{{ certificate.startDate | formatDate }} and {{ certificate.endDate | formatDate }}
							</strong></span
						>
					</v-col>
					<v-spacer></v-spacer>
					<v-col cols="auto" style="padding: 4px">
						<v-btn-toggle>
							<v-btn
								:to="{
									name: 'CertificateOutput',
									params: { id: certificate.periodId }
								}"
								tile
								small
								color="secondary
								"
								>View Certificate</v-btn
							>
							<v-btn
								:to="{
									name: 'PostDetail',
									params: { id: certificate.businessId }
								}"
								tile
								small
								color="secondary
								"
								>View Post</v-btn
							>
						</v-btn-toggle>
					</v-col>
				</v-row>
			</v-alert>
		</v-hover>
	</div>
</template>

<script>
import moment from "moment";

export default {
	name: "certificateCard",
	props: ["certificate", "reference"],
	filters: {
		formatDate(date) {
			return moment(date).format("DD MMMM YYYY");
		}
	}
};
</script>

<style>
</style>