<template>
	<div class="container">
		<v-row>
			<v-col>
				<h2>
					{{ userDetail.userDetails.forenames }}
					{{ userDetail.userDetails.surname }} -
					{{ userDetail.userDetails.emailAddress1 }}
				</h2>
				<v-spacer></v-spacer>
				<v-btn
					tile
					color="secondary"
					@click="dialog = true"
					small
					v-if="userDetail.lockedOutUntil === null"
					>Disable User</v-btn
				>
				<v-btn
					tile
					color="secondary"
					small
					@click="unlockUser"
					v-if="userDetail.lockedOutUntil !== null"
					>Unlock User</v-btn
				>

				<v-dialog v-model="dialog" max-width="300">
					<v-card>
						<v-card-title class="headline"
							>Disable User</v-card-title
						>

						<v-card-text>
							Please enter the number of days this action should
							be in effect.
							<v-text-field
								type="number"
								v-model="banFor"
							></v-text-field>
						</v-card-text>

						<v-card-actions>
							<v-spacer></v-spacer>

							<v-btn
								color="red darken-1"
								text
								@click="dialog = false"
							>
								Cancel
							</v-btn>

							<v-btn text @click="disableUser">
								Disable
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-col>
		</v-row>
		<v-row style="background: white">
			<!-- General Details Section -->
			<v-col cols="12" md="6">
				<v-list-item>
					<strong>General Details</strong>
				</v-list-item>
				<v-divider></v-divider>

				<v-list-item>
					<strong>Role</strong><v-spacer></v-spacer>{{ userRole }}
				</v-list-item>
				<v-list-item>
					<strong>Second Email</strong><v-spacer></v-spacer
					>{{ userDetail.userDetails.emailAddress2 }}
				</v-list-item>
				<v-list-item>
					<strong>Company</strong><v-spacer></v-spacer
					>{{ userDetail.userDetails.company }}
				</v-list-item>
				<v-list-item>
					<strong>Position</strong><v-spacer></v-spacer
					>{{ userDetail.userDetails.position }}
				</v-list-item>
				<v-list-item>
					<strong>Last Logged In</strong><v-spacer></v-spacer
					>{{ userDetail.userDetails.lastLoginDate | formatDate }}
				</v-list-item>
				<v-list-item>
					<strong>Previous Login Date</strong><v-spacer></v-spacer
					>{{
						userDetail.userDetails.previousLoginDate !== null
							? $options.filters.formatDate(
									userDetail.userDetails.previousLoginDate
							  )
							: "None"
					}}
				</v-list-item>
				<v-list-item>
					<strong>Login Count</strong><v-spacer></v-spacer
					>{{ userDetail.userDetails.loginCount }}
				</v-list-item>
				<v-list-item>
					<strong>Registered</strong><v-spacer></v-spacer
					>{{ userDetail.userDetails.createdDate | formatDate }}
				</v-list-item>
				<v-list-item v-if="userDetail.lockedOutUntil !== null">
					<strong>Suspended Until</strong><v-spacer></v-spacer
					>{{ $options.filters.formatDateTime(userDetail.lockedOutUntil) }}
				</v-list-item>
				<v-list-item v-if="userDetail.lockedOutUntil !== null">
					<strong>Lockout Reason</strong><v-spacer></v-spacer
					>{{ userDetail.userDetails.lockoutReason }}
				</v-list-item>

				<!-- price rate field -->
				<v-list-item v-if="userRole === 'Insolvency Practitioner'">
					<strong>Price Rate</strong><v-spacer></v-spacer>
					<span v-if="!editPriceRate"
						><span v-show="!isNaN(priceRate)">£</span
						>{{ priceRate }}</span
					>
					<v-text-field
						v-if="editPriceRate"
						v-model="newCustomPrice"
						:loading="loading"
					></v-text-field>
					<v-icon
						@click="editPriceRate = !editPriceRate"
						v-if="!editPriceRate"
						>mdi-pencil</v-icon
					>
					<v-icon @click="newCustomPrice = standardPriceRate" v-if="editPriceRate"
						>mdi-backup-restore</v-icon
					>
					<v-icon @click="updatePriceRate" v-if="editPriceRate"
						>mdi-check</v-icon
					>
				</v-list-item>
			</v-col>

			<!-- Address Details Section -->
			<v-col cols="12" md="6">
				<v-list-item> <strong>Address Details</strong> </v-list-item>
				<v-divider></v-divider>
				<v-list-item>
					<strong>Address Line 1</strong><v-spacer></v-spacer
					>{{ userDetail.addressDetails.addressLine1 }}
				</v-list-item>
				<v-list-item>
					<strong>Address Line 2</strong><v-spacer></v-spacer
					>{{ userDetail.addressDetails.addressLine2 }}
				</v-list-item>
				<v-list-item>
					<strong>Address Line 3</strong><v-spacer></v-spacer
					>{{ userDetail.addressDetails.addressLine3 }}
				</v-list-item>
				<v-list-item>
					<strong>Town/City</strong><v-spacer></v-spacer
					>{{ userDetail.addressDetails.townCity }}
				</v-list-item>
				<v-list-item>
					<strong>County</strong><v-spacer></v-spacer
					>{{ userDetail.addressDetails.county }}
				</v-list-item>
				<v-list-item>
					<strong>Post Code</strong><v-spacer></v-spacer
					>{{ userDetail.addressDetails.postcode }}
				</v-list-item>
				<v-list-item>
					<strong>Telephone</strong><v-spacer></v-spacer
					>{{ userDetail.addressDetails.telephone }}
				</v-list-item>
				<v-list-item>
					<strong>Mobile</strong><v-spacer></v-spacer
					>{{ userDetail.addressDetails.mobile }}
				</v-list-item>
			</v-col>

			<v-col cols="12" md="6" v-if="userRole === 'Interested Party'">
				<v-list-item>
					<strong>Basic User Preferences</strong>
					<v-spacer></v-spacer>
				</v-list-item>
				<v-divider></v-divider>
				<v-list-item
					v-for="(locationGroup,
					index) in formattedPreferences.chunkedLocations"
					:key="index"
				>
					<strong v-if="index === 0">Location(s)</strong
					><v-spacer></v-spacer
					><v-chip
						v-for="(location, index) in locationGroup"
						:key="index"
						class="mx-1"
						>{{ location }}</v-chip
					>
				</v-list-item>
				<v-list-item
					v-for="(turnoverGroup,
					index) in formattedPreferences.chunkedTurnovers"
					:key="index"
				>
					<strong v-if="index === 0">Turnover(s)</strong
					><v-spacer></v-spacer
					><v-chip
						v-for="(turnover, index) in turnoverGroup"
						:key="index"
						class="mx-1"
						>{{ turnover }}</v-chip
					>
				</v-list-item>
				<v-list-item
					v-for="(employeeGroup,
					index) in formattedPreferences.chunkedEmployees"
					:key="index"
				>
					<strong v-if="index === 0">Employee(s)</strong
					><v-spacer></v-spacer
					><v-chip
						v-for="(employee, index) in employeeGroup"
						:key="index"
						class="mx-1"
						>{{ employee }}</v-chip
					>
				</v-list-item>
			</v-col>

			<v-col cols="12" md="6" v-if="userRole === 'Interested Party'">
				<v-list-item>
					<strong>Additional User Preferences</strong>
					<v-spacer></v-spacer>
				</v-list-item>
				<v-divider></v-divider>

				<v-list-item>
					<strong>Keywords</strong><v-spacer></v-spacer
					>{{ userDetail.miscPreferences.keywords }}
				</v-list-item>

				<v-list-item
					v-for="(assetGroup,
					index) in formattedPreferences.chunkedTurnovers"
					:key="index"
				>
					<strong v-if="index === 0">Asset(s)</strong
					><v-spacer></v-spacer
					><v-chip
						v-for="(asset, index) in assetGroup"
						:key="index"
						class="mx-1"
						>{{ asset }}</v-chip
					>
				</v-list-item>

				<v-list-item>
					<strong>Categories</strong>
					<v-spacer></v-spacer>
					<v-chip v-if="categories.length > 0">
						{{ categories[0] }}
					</v-chip>
				</v-list-item>
				<v-list-item
					v-for="(category, index) in categories"
					:key="index"
					v-show="categories.length > 0 && index !== 0"
					><v-spacer></v-spacer><v-chip>{{ category }}</v-chip>
				</v-list-item>
			</v-col>

			<v-col
				cols="12"
				md="6"
				v-if="userRole === 'Insolvency Practitioner'"
			>
				<v-list-item>
					<strong>Business Postings</strong>
					<v-spacer></v-spacer>
					Total: {{ this.sortedBusinessPosts.length }}
					<v-icon
						@click="showBusinesses = !showBusinesses"
						v-show="sortedBusinessPosts.length > 0"
						>{{
							showBusinesses
								? "mdi-chevron-up"
								: "mdi-chevron-down"
						}}</v-icon
					>
				</v-list-item>
				<v-divider></v-divider>
				<v-list-item
					v-for="business in sortedBusinessPosts"
					:key="business.businessId"
					:to="{
						name: 'PostDetail',
						params: { id: business.businessId }
					}"
					v-show="showBusinesses"
				>
					<strong>{{ business.activityHeading }}</strong
					><v-spacer></v-spacer
					>{{ business.creationDate | formatDate }}
				</v-list-item>
			</v-col>

			<v-col cols="12" md="6" v-if="userRole === 'Interested Party'">
				<v-list-item>
					<strong>Expressions Of Interest</strong>
					<v-spacer></v-spacer>
					Total: {{ sortedExpressions.length }}
					<v-icon
						@click="showExpressions = !showExpressions"
						v-show="sortedExpressions.length > 0"
						>{{
							showExpressions
								? "mdi-chevron-up"
								: "mdi-chevron-down"
						}}</v-icon
					>
				</v-list-item>
				<v-divider></v-divider>
				<v-list-item
					v-for="interest in sortedExpressions"
					:key="interest.expressionId"
					:to="{
						name: 'InterestDetail',
						params: { id: interest.expressionId }
					}"
					v-show="showExpressions"
				>
					<strong>Business ID: {{ interest.businessId }}</strong
					><v-spacer></v-spacer
					>{{ interest.createdDate | formatDate }}
				</v-list-item>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import moment from "moment";
import Repository from "@/store/Services/Repository.js";
import { mapGetters } from "vuex";

//Helper(s)
const chunkArray = (array, size) => {
	let result = [];
	for (let i = 0; i < array.length; i += size) {
		let chunk = array.slice(i, i + size);
		result.push(chunk);
	}
	return result;
};

export default {
	name: "UserDetail",
	data() {
		return {
			userDetail: null,
			loading: false,
			dialog: false,
			banFor: null,
			showExpressions: false,
			showBusinesses: false,
			newCustomPrice: null,
			editPriceRate: false
		};
	},
	async created() {
		await Repository.get("admin/user?id=" + this.$route.params.id, {
			headers: { Authorization: `Bearer ${this.token}` }
		}).then(response => {
			this.userDetail = response.data;
		});

		this.newCustomPrice = this.userDetail.userDetails.customPriceRate || 0;
	},
	computed: {
		...mapGetters(["token", "standardPriceRate"]),
		userRole() {
			if (this.userDetail.userDetails.capacityId !== null) {
				return "Insolvency Practitioner";
			} else {
				return "Interested Party";
			}
		},
		sortedExpressions() {
			const businessInterests = [
				...this.userDetail.userDetails.businessExpressedInterests
			];
			return businessInterests.sort((a, b) =>
				Date.parse(a.createdDate) > Date.parse(b.createdDate) ? -1 : 1
			);
		},
		sortedBusinessPosts() {
			const posts = [...this.userDetail.userDetails.businessPosting];
			return posts.sort((a, b) =>
				Date.parse(a.creationDate) > Date.parse(b.creationDate) ? -1 : 1
			);
		},
		categories() {
			return (
				this.userDetail &&
				this.userDetail.miscPreferences.categoryPreferences
			);
		},
		priceRate() {
			if (this.userDetail.userDetails.customPriceRate) {
				return parseFloat(
					this.userDetail.userDetails.customPriceRate
				).toFixed(2);
			} else {
				return "Standard Rate";
			}
		},
		formattedPreferences() {
			const chunkSize = 3;

			const formattedPreferences = {
				chunkedLocations: chunkArray(
					this.userDetail.userDetails.accountBusinessLocation.map(
						l => l.location.description
					),
					chunkSize
				),
				chunkedTurnovers: chunkArray(
					this.userDetail.userDetails.accountBusinessTurnover.map(
						t => t.turnover.description
					),
					chunkSize
				),
				chunkedEmployees: chunkArray(
					this.userDetail.userDetails.accountBusinessEmployees.map(
						e => e.employees.description
					),
					chunkSize
				),
				chunkedAssets: chunkArray(
					this.userDetail.userDetails.accountBusinessAsset.map(
						a => a.asset.description
					),
					chunkSize
				)
			};

			return formattedPreferences;
		}
	},
	methods: {
		async disableUser() {
			this.loading = true;

			const banForInt = parseInt(this.banFor);

			if (banForInt > 0) {
				const payload = {
					id: this.$route.params.id,
					disableFor: banForInt
				};

				await this.$store.dispatch("disableUser", payload);

				this.$router.go(this.$router.currentRoute);
			} else {
				this.dialog = false;
				this.loading = false;
				return;
			}
		},
		async unlockUser() {
			this.loading = true;

			await this.$store.dispatch("unlockUser", this.$route.params.id);

			this.loading = false;

			this.$router.go(this.$router.currentRoute);
		},
		async updatePriceRate() {
			if (!this.newCustomPrice) {
				this.editPriceRate = false;
				return;
			}

			this.loading = true;

			const payload = {
				userId: this.$route.params.id,
				newPriceRate: this.newCustomPrice
			};

			await this.$store
				.dispatch("updateUserPriceRate", payload)
				.then(
					(this.userDetail.userDetails.customPriceRate = this.newCustomPrice)
				);

			this.loading = false;
			this.editPriceRate = false;
		}
	},
	filters: {
		formatDate(date) {
			return moment(date).format("DD MMMM YYYY");
		},
		formatDateTime(date) {
			return moment(date).format("HH:mm on DD MMMM YYYY");
		}
	}
};
</script>