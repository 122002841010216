var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-alert',{attrs:{"border":"left","color":_vm.data.viewedByInsolvency ? 'dark-grey' : 'secondary',"elevation":hover ? 6 : 0,"dense":!_vm.data.viewedByInsolvency,"text":""}},[_c('v-row',{staticClass:"mx-0 pa-0"},[_c('v-col',{staticStyle:{"padding":"4px"},attrs:{"cols":"12","sm":"7"}},[_c('v-list-item-avatar',{directives:[{name:"show",rawName:"v-show",value:(!_vm.data.viewedByInsolvency),expression:"!data.viewedByInsolvency"}],staticClass:"pa-0 ma-0",attrs:{"size":"40"}},[_c('img',{attrs:{"src":require("@/assets/icons/misc_notifications.svg")}})]),_c('span',[_vm._v("Interest for "),_c('strong',[_vm._v(_vm._s(_vm.data.reference))]),_vm._v(" from "),_c('strong',[_vm._v(_vm._s(_vm.data.forenames + " " + _vm.data.surname))]),_vm._v(", expressed "),_c('strong',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.data.createdDate)))])])],1),_c('v-spacer'),_c('v-col',{staticStyle:{"padding":"4px"},attrs:{"cols":"auto"}},[_c('v-btn-toggle',[_c('v-btn',{attrs:{"to":{
								name: 'InterestDetail',
								params: { id: _vm.data.expressionId }
							},"small":"","tile":"","color":_vm.data.viewedByInsolvency
									? 'dark-grey'
									: 'secondary'}},[_vm._v("View Interest")]),_c('v-btn',{attrs:{"to":{
								name: 'PostDetail',
								params: { id: _vm.data.businessId }
							},"tile":"","small":"","color":_vm.data.viewedByInsolvency
									? 'dark-grey'
									: 'secondary'}},[_vm._v("View Post")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }