<template>
	<div>
		<v-hover>
			<template v-slot:default="{ hover }">
				<v-card
					:max-width="cardWidth"
					class="mx-auto"
					:elevation="hover ? 6 : 0"
					tile
					outlined
				>
					<v-list-item two-line style="background:#1e3354;">
						<v-list-item-avatar tile size="50">
							<img
								src="@/assets/icons/widget.svg"
								alt="Post Widget"
							/>
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title style="color: white">
								<strong>{{
									post.activityHeading
								}}</strong></v-list-item-title
							>
							<v-list-item-subtitle style="color: white">
								{{ post.tradingStatus }}</v-list-item-subtitle
							>
						</v-list-item-content>
					</v-list-item>
					<v-divider></v-divider>
					<v-list-item>
						Category<v-spacer></v-spacer>
						<v-chip
							class="ma-2"
							small
							v-if="post.primaryCategory.length > 0"
							>{{ post.primaryCategory[0].description }}</v-chip
						>
					</v-list-item>
					<v-divider></v-divider>
					<v-list-item>
						Turnover<v-spacer></v-spacer>
						<v-chip class="ma-2" small>{{ post.turnover }}</v-chip>
					</v-list-item>
					<v-divider></v-divider>
					<v-list-item>
						Listed<v-spacer></v-spacer>
						<v-chip class="ma-2" small>{{
							post.activeStartDate[0] | formatDate
						}}</v-chip>
					</v-list-item>
					<v-divider></v-divider>
					<v-card-actions>
						<v-flex>
							<v-btn-toggle tile class="d-flex">
								<v-btn
									width="50%"
									:to="{
										name: 'PostDetail',
										params: { id: post.businessId }
									}"
									small
									color="secondary"
									>View Detail</v-btn
								>
								<v-btn
									width="50%"
									:disabled="post.hasExpressedInterest"
									:to="{
										name: 'ExpressInterest',
										params: { id: post.businessId }
									}"
									small
									color="info"
									>Express Interest</v-btn
								>
							</v-btn-toggle>
						</v-flex>
					</v-card-actions>

					<v-fade-transition v-if="!isLoggedIn">
						<v-overlay v-if="hover" absolute color="grey">
							<v-btn tile :to="{ name: 'Login', query: { name: 'PostDetail', id: post.businessId } }"
								>Login To View</v-btn
							>
						</v-overlay>
					</v-fade-transition>
				</v-card>
			</template>
		</v-hover>
	</div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
	name: "buyerPostCard",
	props: ["post", "cardWidth"],
	data() {
		return {
			overlay: false
		};
	},
	computed: {
		...mapGetters(["isLoggedIn"])
	},
	filters: {
		formatDate(date) {
			return moment(date).format("DD MMMM YYYY");
		}
	}
};
</script>
