var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-alert',{attrs:{"border":"left","color":"secondary","elevation":hover ? 6 : 0,"text":""}},[_c('v-row',{staticClass:"mx-0 pa-0"},[_c('v-col',{staticStyle:{"padding":"4px"},attrs:{"cols":"12","sm":"7"}},[_c('span',[_vm._v("Invoice for "),_c('strong',[_vm._v(_vm._s(_vm.reference)+" ")]),_vm._v(" generated "),_c('strong',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.invoice.createdDate)))])])]),_c('v-spacer'),_c('v-col',{staticStyle:{"padding":"4px"},attrs:{"cols":"auto"}},[_c('v-btn-toggle',[_c('v-btn',{attrs:{"to":{
								name: 'InvoiceOutput',
								params: { id: _vm.invoice.invoiceId }
							},"tile":"","small":"","color":"secondary\n\t\t\t\t\t\t\t"}},[_vm._v("View Invoice")]),_c('v-btn',{attrs:{"to":{
								name: 'PostDetail',
								params: { id: _vm.invoice.businessId }
							},"tile":"","small":"","color":"secondary\n\t\t\t\t\t\t\t"}},[_vm._v("View Post")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }