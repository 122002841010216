<template>
	<div class="container">
		<h2>Amend My Preferences</h2>
		<preferenceNavItems :active="'Categories'" />

		<v-btn
			tile
			color="secondary"
			:loading="saveLoading"
			@click="saveChanges"
			:disabled="triggerSaveText"
			>Save Changes</v-btn
		>
		<savedAnimation
			:text="successMessage"
			:showTick="true"
			:triggered="triggerSaveText"
		/>
		<p v-if="errorMessage !== null">{{ errorMessage }}</p>
		<v-row
			style="background: white; borderRadius: 3px"
			class="my-2 mx-1 pa-4"
		>
			<businessCategoryPicker
				:existing="existingCategories"
				:showSelected="true"
				:radio="false"
				v-model="selected"
				v-if="loadPicker"
			/>
		</v-row>
	</div>
</template>

<script>
import savedAnimation from "@/components/savedAnimation.vue";
import preferenceNavItems from "@/components/Buyer Components/preferenceNavItems.vue";
import businessCategoryPicker from "@/components/businessCategoryPicker.vue";

import Repository from "@/store/Services/Repository.js";
import { mapGetters } from "vuex";

export default {
	name: "AmendCategoryPreferences",
	components: {
		preferenceNavItems,
		businessCategoryPicker,
		savedAnimation
	},
	data() {
		return {
			selected: [],
			existingCategories: [],
			apiError: null,
			successMessage: null,
			errorMessage: null,
			saveLoading: false,
			dataLoad: false,
			loadPicker: false,
			triggerSaveText: false
		};
	},
	async created() {
		this.dataLoad = true;

		await Repository.get("buyer/category-preferences", {
			headers: { Authorization: `Bearer ${this.token}` }
		})
			.then(response => {
				this.existingCategories = response.data.categories;
				this.loadPicker = true;
			})
			.catch(e => {
				this.apiError = e;
			});

		this.dataLoad = false;
	},
	computed: {
		...mapGetters(["token"])
	},
	methods: {
		async saveChanges() {
			this.saveLoading = true;
			this.successMessage = null;
			this.errorMessage = null;

			await Repository.put(
				"buyer/category-preferences",
				{ Categories: this.selected },
				{
					headers: { Authorization: `Bearer ${this.token}` }
				}
			)
				.then(response => {
					this.successMessage = response.data.response;
					this.triggerSaveText = true;
				})
				.catch(e => {
					this.errorMessage = e.response.data.response;
				});

			this.saveLoading = false;

			await new Promise(resolve => setTimeout(resolve, 2800)); //Length of Animation
			this.triggerSaveText = false;
		}
	}
};
</script>