<template>
	<div class="container">
		<h2>Invoices</h2>
		<v-row>
			<v-col>
				<v-card class="d-flex pa-2" flat outlined>
					<span class="mx-2"><strong>Filters</strong></span>
					<v-divider vertical class="mx-2"></v-divider>
					<a
						@click="filter = ''"
						class="mx-2"
						:style="
							filter !== '' ? 'color: grey' : 'color: #86ae41'
						"
					>
						None
					</a>
					<a
						@click="filter = 'Last Week'"
						class="mx-2"
						:style="
							filter !== 'Last Week'
								? 'color: grey'
								: 'color: #86ae41'
						"
					>
						Last Week
					</a>
				</v-card>
			</v-col>
		</v-row>
		<div v-for="(invoice, key) in filteredInvoices" :key="key">
			<invoiceCard :invoice="invoice.invoice" :reference="invoice.reference" />
		</div>
	</div>
</template>


<script>
import { mapGetters } from "vuex";
import Repository from "@/store/Services/Repository.js";
import invoiceCard from "@/components/Seller Components/invoiceCard.vue";

export default {
	name: "UserInvoices",
		metaInfo(){
		return{
			title: "IP-BID.com - My Invoices"
		}
	},
	components: {
		invoiceCard
	},
	data() {
		return {
			loading: false,
			invoices: [],
			filter: ""
		};
	},
	created() {
		this.loading = true;
	},
	async mounted() {
		await Repository.get("seller/invoice-list", {
			headers: { Authorization: `Bearer ${this.token}` }
		}).then(response => {
			this.invoices = response.data;
		});
		this.loading = false;
	},
	computed: {
		...mapGetters(["token"]),
		filteredInvoices() {
			const daysAgo = days => {
				return new Date().getTime() - 1000 * 60 * 60 * 24 * days;
			};

			switch (this.filter) {
				case "":
					return this.invoices;
				case "Last Week":
					return this.invoices.filter(
						i =>
							Date.parse(i.invoice.createdDate) > daysAgo(7)
					);
				default:
					return this.invoices;
			}
		}
	}
};
</script>