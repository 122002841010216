<template>
	<div class="container">
		<h2>Select Role</h2>
		<v-row>
			<v-col md="6">
				<registerBuyerCard />
			</v-col>
			<v-col md="6">
				<registerSellerCard />
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<h3>
					Already Have an Account?
				</h3>
				<v-btn color="secondary" :to="{name: 'Login'}" tile>Login Here</v-btn>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import registerBuyerCard from "@/components/registerBuyerCard.vue";
import registerSellerCard from "@/components/registerSellerCard.vue";

export default {
	name: "RegisterDirectory",
	metaInfo() {
		return {
			title: "IP-BID.com - The UK's Online Insolvency Marketplace"
		};
	},
	components: {
		registerBuyerCard,
		registerSellerCard
	}
};
</script>