<template>
	<div class="container">
		<h2>Administrate Posts</h2>

		<v-pagination
			style="justifyContent: left"
			v-model="paginationSettings.page"
			:length="admin.postList.roundedPageCount"
			color="accent"
			@input="refreshData"
			total-visible="10"
		></v-pagination>
		<v-row>
			<v-col cols="auto" md="4">
				<v-text-field
					class="mx-2"
					append-icon="mdi-magnify"
					label="Search this page"
					v-model="searchTerm"
				></v-text-field>
			</v-col>
			<v-col cols="auto">
				<v-select
					class="mx-2"
					:items="paginationSettings.pageSizeItems"
					v-model="paginationSettings.pageSize"
					label="Posts Per Page"
				></v-select>
			</v-col>
		</v-row>

		<businessTable
			:page="paginationSettings.page"
			:pageSize="paginationSettings.pageSize"
			:data="admin.postList.businessList"
			:searchTerm="searchTerm"
		/>
		<v-pagination
			class="py-8"
			style="justifyContent: left"
			v-model="paginationSettings.page"
			:length="admin.postList.roundedPageCount"
			color="accent"
			@input="refreshData"
			total-visible="10"
		></v-pagination>
	</div>
</template>

<script>
import businessTable from "@/components/Admin Components/businessTable.vue";
import { mapState } from "vuex";

export default {
	name: "AdminPosts",
	components: {
		businessTable
	},
	data() {
		return {
			searchTerm: "",
			paginationSettings: {
				pageSizeItems: [10, 25, 50, 100, 250, 500],
				page: 1,
				pageSize: 50
			},
			loading: false
		};
	},
	created() {
		this.$store.dispatch("retrievePosts", this.paginationSettings);
	},
	computed: {
		...mapState(["admin"]),
		computedPageSize() {
			return this.paginationSettings.pageSize;
		}
	},
	methods: {
		async refreshData() {
			this.loading = true;

			await this.$store.dispatch(
				"retrievePosts",
				this.paginationSettings
			);

			this.loading = false;
		}
	},
	watch: {
		computedPageSize() {
			this.refreshData();
		}
	}
};
</script>