import Repository from "../Services/Repository.js";

export default {
	state: {
		contactUsLabels: {},
		visitorError: null,
		homePosts: [],
		homeStats: []
	},
	getters: {

	},
	mutations: {
		SET_CONTACT_LABELS(state, labels) {
			state.contactUsLabels = labels;
		},
		SET_VISITOR_ERROR(state, e) {
			state.visitorError = e;
		},
		SET_HOME_POSTS(state, posts) {
			state.homePosts = posts;
		},
		SET_HOME_STATS(state, stats) {
			state.homeStats = stats;
		},
	},
	actions: {
		sendContactUsForm(context, form) {
			return new Promise((resolve, reject) => {
				Repository.post('unauth/contactus', {
					From: form.from,
					ReplyToEmail: form.replyToEmail,
					Regarding: form.regarding,
					Message: form.message
				})
					.then(response => {
						resolve(response.data);
					})
					.catch(e => {
						context.commit('SET_VISITOR_ERROR', e);
						reject(e.response.data.response);
					})
			})
		},
		retrieveContactUsLabels(context) {
			Repository.get('label/contactus')
				.then(response => {
					context.commit('SET_CONTACT_LABELS', response.data)
				})
				.catch(e => context.commit('SET_VISITOR_ERROR', e))
		},
		//needs to be wrapped in a promise for loading functionality
		async retrieveHomePosts(context) {
			return new Promise((resolve, reject) => {
				Repository.get('unauth/homeposts')
					.then(
						response => {
							context.commit("SET_HOME_POSTS", response.data);
							resolve(true);
						})
					.catch(e => {
						context.commit('SET_VISITOR_ERROR', e)
						reject(e);
					});
			})
		},
		async retrieveHomeStats(context) {
			Repository.get('unauth/homestats')
				.then(
					response => {
						context.commit("SET_HOME_STATS", response.data);

					}).catch(e => {
						context.commit('SET_VISITOR_ERROR', e)
					});
		},
	}
};
