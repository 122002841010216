<template>
	<div>
		<span class="date">{{ formattedDate }}</span
		><strong><br />{{ formattedTime }}</strong>
	</div>
</template>


<script>
import moment from "moment";

export default {
	name: "clock",
	data() {
		return {
			timer: "",
			liveTime: ""
		};
	},
	created() {
		this.liveTime = Date.now();
		this.timer = setInterval(this.setTime, 1000);
	},
	methods: {
		setTime() {
			this.liveTime = Date.now();
		}
	},
	computed: {
		formattedTime() {
			return moment(this.liveTime).format("HH:mm:ss");
		},
		formattedDate() {
			return moment(Date.now()).format("DD MMMM YYYY");
		}
	}
};
</script>

<style lang="sass" scoped>
.date
	font-size: 0.9em
</style>