<template>
	<div>
		<v-card outlined>
			<v-list dense>
				<v-list-item>
					<strong>Post Start Date</strong>
					<v-spacer></v-spacer>
					{{
						dateRange.length > 0
							? $options.filters.formatDate(dateRange[0])
							: "-"
					}}
				</v-list-item>
				<v-list-item>
					<strong>Post End Date</strong>
					<v-spacer></v-spacer>
					{{
						dateRange.length > 1 && dateRange[1] !== null
							? $options.filters.formatDate(dateRange[1])
							: "-"
					}}
				</v-list-item>
				<v-list-item>
					<strong>Total Days Live</strong>
					<v-spacer></v-spacer>
					{{ isNaN(totalDays) ? "-" : Math.floor(totalDays) }}
				</v-list-item>
				<v-list-item>
					<strong>First 28 Days</strong>
					<v-spacer></v-spacer>
					{{ first28Q }} x £
					{{ first28Amount.toFixed(2) }}
				</v-list-item>
				<v-list-item>
					<strong>Further 28 Days</strong>
					<v-spacer></v-spacer>
					{{ isNaN(further28Q) ? 0 : further28Q }} x £
					{{ first28Amount.toFixed(2) }}
				</v-list-item>
				<v-list-item>
					<strong>VAT @ 20%</strong>
					<v-spacer></v-spacer>
					£ {{ isNaN(vatAmount) ? '-' : vatAmount }}
				</v-list-item>
				<v-list-item>
					<strong>Total Price</strong>
					<v-spacer></v-spacer>
					£ {{ isNaN(totalPrice) ? (first28Amount * 1.2).toFixed(2) : totalPrice }}
				</v-list-item>
			</v-list>
		</v-card>
	</div>
</template>

<script>
import moment from "moment";

export default {
	name: "invoiceCalculator",
	props: ["dateRange", "price"],
	data() {
		return {
			VAT: 0.2
		};
	},
	computed: {
		totalDays() {
			return (
				Math.round(
					Date.parse(this.dateRange[1]) -
						Date.parse(this.dateRange[0])
				) /
					(1000 * 3600 * 24) +
				1
			);
		},
		first28Q() {
			return 1;
		},
		further28Q() {
			return Math.floor((this.totalDays - 1) / 28);
		},
		first28Amount() {
			return this.first28Q * this.price;
		},
		further28Amount() {
			return this.further28Q * this.price;
		},
		vatAmount() {
			return ((this.first28Amount + this.further28Amount) * this.VAT).toFixed(2);
		},
		totalPrice() {
			return (
				(this.first28Amount + this.further28Amount) *
				(1 + this.VAT)
			).toFixed(2); //1.2 for VAT
		}
	},
	filters: {
		formatDate(date) {
			return moment(date).format("DD MMMM YYYY");
		}
	}
};
</script>