<template>
	<div>
		<v-hover v-slot:default="{ hover }">
			<v-alert
				border="left"
				color="secondary"
				:elevation="hover ? 6 : 0"
				text
			>
				<v-row class="mx-0 pa-0">
					<v-col cols="12" sm="7" style="padding: 4px">
						<span
							>Invoice for
							<strong>{{ reference }} </strong>
							generated
							<strong>{{
								invoice.createdDate | formatDate
							}}</strong></span
						>
					</v-col>
					<v-spacer></v-spacer>
					<v-col cols="auto" style="padding: 4px">
						<v-btn-toggle>
							<v-btn
								:to="{
									name: 'InvoiceOutput',
									params: { id: invoice.invoiceId }
								}"
								tile
								small
								color="secondary
								"
								>View Invoice</v-btn
							>
							<v-btn
								:to="{
									name: 'PostDetail',
									params: { id: invoice.businessId }
								}"
								tile
								small
								color="secondary
								"
								>View Post</v-btn
							>
						</v-btn-toggle>
					</v-col>
				</v-row>
			</v-alert>
		</v-hover>
	</div>
</template>

<script>
import moment from "moment";

export default {
	name: "invoiceCard",
	props: ["invoice", "reference"],
	filters: {
		formatDate(date) {
			return moment(date).fromNow();
		}
	}
};
</script>

<style>
</style>