<template>
	<div class="container">
		<h2>Expressions of Interest</h2>
		<expressionOfInterestList :expressions="buyer.expressionsOfInterest" />
		<p v-show="isEmpty">
			Looks like there's nothing here.
		</p>
	</div>
</template>

<script>
import expressionOfInterestList from "@/components/Buyer Components/expressionOfInterestList.vue";
import { mapState } from "vuex";

export default {
	name: "ExpressedInterest",
	metaInfo() {
		return {
			title: "IP-BID.com - Your Expressed Interest"
		};
	},
	components: {
		expressionOfInterestList
	},
	async mounted() {
		await this.$store.dispatch("retrieveInterestedPosts");
	},
	computed: {
		...mapState(["buyer"]),
		isEmpty(){
			return this.buyer.expressionsOfInterest.length === 0 ? true : false;
		}
	},
};
</script>
