import Repository from './Repository.js'

export default {
    validate(params) {
        return new Promise((resolve, reject) => {
            Repository.post('unauth/validate-recaptcha', params)
                .then(response => {
                    if (response.data.hasErrors) {
                        reject(response.data.message)
                    } else {
                        resolve(response.data)
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}