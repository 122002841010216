import Repository from "../Services/Repository.js";
import jwt_decode from "jwt-decode";

export default {
	state: {
		post: null,
		accountDetails: null,
		allAuthError: null,
		buyerItems: [
			{
				title: "Posting Inbox",
				icon: require("@/assets/icons/buyers_posting inbox.svg"),
				routeName: "PostingInbox",
				description: "View Posts Matching Your Preferences. Try editing your preferences to see more posts here."
			},
			{
				title: "Expressed Interest",
				icon: require("@/assets/icons/buyers_expressed interest.svg"),
				routeName: "ExpressedInterest",
				description: "View Your Expressions of Interest"
			}
		],
		sellerItems: [
			{
				title: "Live Posts",
				icon: require("@/assets/icons/seller_live posts.svg"),
				routeName: "LivePosts",
				description:
					"Manage your posts currently visible to Interested Parties"
			},
			{
				title: "Add Post",
				icon: require("@/assets/icons/seller_add post.svg"),
				routeName: "AddPost",
				description:
					"Create a new draft, live or scheduled Post."
			},
			{
				title: "View Interest",
				icon: require("@/assets/icons/seller_view interests.svg"),
				routeName: "ViewInterest",
				description:
					"View any interest for your postings received from Interested Parties."
			},
			{
				title: "Draft Posts",
				icon: require("@/assets/icons/seller_draft postings.svg"),
				routeName: "DraftPosts",
				description: "Manage your Posts currently in draft stage."
			},
			{
				title: "Scheduled Posts",
				icon: require("@/assets/icons/seller_scheduled postings.svg"),
				routeName: "ScheduledPosts",
				description:
					"Manage your Posts currently scheduled to go Live."
			},
			{
				title: "Archived Posts",
				icon: require("@/assets/icons/seller_posting archives.svg"),
				routeName: "PostingArchive",
				description:
					"Manage your Posts which have either been Expired, Sold or Withdrawn."
			},
			{
				title: "Invoices",
				icon: require("@/assets/icons/seller_invoices.svg"),
				routeName: "UserInvoices",
				description: "View invoices generated from your posts."
			},
			{
				title: "Certificates",
				icon: require("@/assets/icons/seller_certificates_2.svg"),
				routeName: "UserCertificates",
				description: "View certificates generated from your posts."
			}
		],
		adminItems: [
			{
				title: "Users",
				icon: require("@/assets/icons/new users.svg"),
				routeName: "AdminUsers",
				description:
					"Disable Users; Undisable Users; View A User's Posts, Expressions of Interest and More"
			},
			{
				title: "Posts",
				icon: require("@/assets/icons/business listings.svg"),
				routeName: "AdminPosts",
				description:
					"View all posts on IPBID. See Posts and the Owning User's Details; Approve/Unapprove Posts"
			},
			{
				title: "Invoices",
				icon: require("@/assets/icons/seller_invoices.svg"),
				routeName: "InvoiceList",
				description:
					"See all Invoice Information for recently created Posts."
			},
			{
				title: "Certificates",
				icon: require("@/assets/icons/seller_certificates_2.svg"),
				routeName: "CertificateList",
				description: "View certificates generated for recently created posts."
			},
			{
				title: "Dashboards",
				icon: require("@/assets/icons/everyone_keywords.svg"),
				routeName: "Dashboards",
				description: "View IPBID Dashboards"
			},
			{
				title: "SendGrid",
				icon: require("@/assets/icons/emails sent.svg"),
				externalLink: "app.sendgrid.com",
				description: "Go to Send Grid to manage Emails"
			}
		]

	},
	getters: {
		loadedPost(state) {
			return state.post !== null ? state.post[0] : null;
		}
	},
	mutations: {
		SET_POST(state, post) {
			state.post = post;
		},
		SET_ACCOUNT_DETAILS(state, details) {
			state.accountDetails = details;
		},
		SET_ALL_AUTH_ERROR(state, e) {
			state.allAuthError = e;
		},
		RESET_ACCOUNT_DETAILS(state) {
			state.accountDetails = null;
		}
	},
	actions: {
		retrievePostByID(context, id) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.get('allauth/post?id=' + id, {
					headers: { Authorization: `Bearer ${token}` }
				})
					.then(
						response => {
							context.commit("SET_POST", response.data);
							resolve(response.data);
						})
					.catch(e => {
						context.commit('SET_ALL_AUTH_ERROR', e);
						reject(e.response.data.response);
					});
			});
		},
		retrieveAccountDetails(context) {
			const token = localStorage.getItem("access_token");

			context.commit('RESET_ACCOUNT_DETAILS');

			return new Promise((resolve, reject) => {
				Repository.get('allauth/account-details', {
					headers: { Authorization: `Bearer ${token}` }
				})
					.then(response => {
						context.commit('SET_ACCOUNT_DETAILS', response.data);
						resolve(response.data);
					})
					.catch(e => {
						context.commit('SET_ALL_AUTH_ERROR', e)
						reject(e.response.data.response)
					})
			});
		},
		saveAccountDetailChanges: async function (context, form) {
			const token = localStorage.getItem("access_token");

			const isBuyer = jwt_decode(token)[
				"http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
			] === "Interested Party";

			return new Promise((resolve, reject) => {
				Repository.patch('allauth/account?isBuyer=' + isBuyer,
					form,
					{
						headers: { Authorization: `Bearer ${token}` }
					})
					.then(response => resolve(response))
					.catch(e => reject(e))
			});
		},
		saveAddressDetailChanges: async function (context, form) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.patch('allauth/account-address',
					form,
					{
						headers: { Authorization: `Bearer ${token}` }
					})
					.then(response => resolve(response))
					.catch(e => reject(e))
			});

		}
	}
};
