<template>
	<div class="container">
		<v-btn
			tile
			small
			color="primary"
			text
			:to="{
				name: 'PostDetail'
			}"
			><v-icon>mdi-chevron-left</v-icon> to post</v-btn
		>
		<h2>Withdraw Post</h2>
		<v-col cols="12" lg="9">
			<p class="copy">
				We hope you have achieved a successful outcome for this
				business. To withdraw this posting, please click on Withdraw
				Post. The posting will be withdrawn from current listings
				available to interested parties, however it will still appear in
				your Postings archive and you can revive the posting at a later
				date should you choose to remarket the business.
			</p>
		</v-col>
		<v-btn :disabled="triggerSaveText" tile color="secondary" @click="withdrawPost" :loading="loading"
			>Withdraw Post</v-btn
		>

		<savedAnimation
			:text="successMessage"
			:showTick="true"
			:triggered="triggerSaveText"
		/>
		<p v-if="errorMessage !== null">{{ errorMessage }}</p>
	</div>
</template>

<script>
import savedAnimation from "@/components/savedAnimation.vue";

export default {
	name: "WithdrawPost",
	data() {
		return {
			successMessage: null,
			errorMessage: null,
			loading: false,
			triggerSaveText: false
		};
	},
	components: {
		savedAnimation
	},
	methods: {
		async withdrawPost() {
			this.loading = true;
			await this.$store
				.dispatch("withdrawPost", this.$route.params.id)
				.then(response => {
					this.successMessage = response.response;
					this.triggerSaveText = true;
				})
				.catch(e => {
					this.errorMessage = e;
				});
			this.loading = false;

			await new Promise(resolve => setTimeout(resolve, 2800)); //Length of Animation
			this.triggerSaveText = false;
		}
	}
};
</script>

<style lang="sass" scoped>
.copy
	text-align: justify
</style>