<template>
    <div class="container">
        <h2>Register as an Interested Party</h2>
        <v-stepper v-model="step">
            <v-stepper-header>
                <v-stepper-step :complete="step > 1" step="1" editable>Personal Details</v-stepper-step>

                <v-stepper-step :complete="step > 2"
                                step="2"
                                :editable="!$v.formData.stepOne.$invalid">Address Details</v-stepper-step>

                <v-stepper-step :complete="step > 3"
                                step="3"
                                :editable="
						!$v.formData.stepOne.$invalid &&
							!$v.formData.stepTwo.$invalid
					">Location Size Preferences</v-stepper-step>

                <v-stepper-step :complete="step > 4"
                                step="4"
                                :editable="
						!$v.formData.stepOne.$invalid &&
							!$v.formData.stepTwo.$invalid &&
							!$v.formData.stepThree.$invalid
					">
                    Business Category Preferences<small>Optional</small>
                </v-stepper-step>

                <v-stepper-step :complete="step > 5"
                                step="5"
                                :editable="
						!$v.formData.stepOne.$invalid &&
							!$v.formData.stepTwo.$invalid &&
							!$v.formData.stepThree.$invalid
					">Keywords<small>Optional</small></v-stepper-step>

                <v-stepper-step :complete="step > 6"
                                step="6"
                                :editable="
						!$v.formData.stepOne.$invalid &&
							!$v.formData.stepTwo.$invalid &&
							!$v.formData.stepThree.$invalid
					">Asset Preferences<small>Optional</small></v-stepper-step>
                <v-stepper-step step="7"
                                :editable="
						!$v.formData.stepOne.$invalid &&
							!$v.formData.stepTwo.$invalid &&
							!$v.formData.stepThree.$invalid
					">Password</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1">
  					<div style="height:40px;"></div>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-select :items="labels.registerLabels.titleLabels"
                                      item-text="description"
                                      item-value="titleId"
                                      v-model="formData.stepOne.titleID"
                                      :error-messages="titleErrors"
                                      label="Title"
                                      required
                                      clearable></v-select>
                            <v-text-field label="Forenames"
                                          v-model="formData.stepOne.forenames"
                                          :error-messages="forenameErrors"
                                          @input="$v.formData.stepOne.forenames.$touch()"
                                          @blur="$v.formData.stepOne.forenames.$touch()"
                                          required></v-text-field>
                            <v-text-field label="Surname"
                                          v-model="formData.stepOne.surname"
                                          :error-messages="nameErrors"
                                          @input="$v.formData.stepOne.surname.$touch()"
                                          @blur="$v.formData.stepOne.surname.$touch()"
                                          required></v-text-field>
                            <v-text-field label="Email"
                                          v-model="formData.stepOne.email"
                                          :error-messages="emailErrors"
                                          @input="$v.formData.stepOne.email.$touch()"
                                          @blur="$v.formData.stepOne.email.$touch()"
                                          required></v-text-field>
                            <v-text-field label="Second Email"
                                          v-model="formData.stepOne.secondaryEmail"
                                          :error-messages="secondEmailErrors"
                                          @input="
									$v.formData.stepOne.secondaryEmail.$touch()
								"
                                          @blur="
									$v.formData.stepOne.secondaryEmail.$touch()
								"
                                          required></v-text-field>

                            <v-text-field v-model="formData.stepOne.position"
                                          :error-messages="positionErrors"
                                          label="Position"
                                          @input="$v.formData.stepOne.position.$touch()"
                                          @blur="$v.formData.stepOne.position.$touch()"
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="formData.stepOne.company"
                                          :error-messages="companyErrors"
                                          label="Company Name"
                                          @input="$v.formData.stepOne.company.$touch()"
                                          @blur="$v.formData.stepOne.company.$touch()"
                                          required></v-text-field>
                            <v-select :items="
									labels.registerLabels.interestedStatusLabels
								"
                                      item-text="description"
                                      item-value="interestedStatusId"
                                      v-model="formData.stepOne.functionID"
                                      label="Function"
                                      :error-messages="functionErrors"
                                      required
                                      clearable></v-select>
                            <v-text-field v-model="formData.stepOne.functionOther"
                                          label="Function (Other)"></v-text-field>
                            <v-text-field v-model="formData.stepOne.telephone"
                                          :error-messages="telephoneErrors"
                                          label="Telephone"
                                          @input="$v.formData.stepOne.telephone.$touch()"
                                          @blur="$v.formData.stepOne.telephone.$touch()"
                                          required></v-text-field>
                            <v-text-field v-model="formData.stepOne.mobile"
                                          label="Mobile"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="px-2 mx-2 d-flex">

                        <v-spacer></v-spacer>

                        <v-btn fab
                               small
                               color="secondary"
                               @click="validateStep"
                               class="ma-1"><v-icon>mdi-chevron-right</v-icon></v-btn>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="2">
  					<div style="height:40px;"></div>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="formData.stepTwo.addressLine1"
                                          label="Address Line 1"
                                          :error-messages="addressLine1Errors"
                                          @input="
									$v.formData.stepTwo.addressLine1.$touch()
								"
                                          @blur="
									$v.formData.stepTwo.addressLine1.$touch()
								"
                                          required></v-text-field>
                            <v-text-field v-model="formData.stepTwo.addressLine2"
                                          label="Address Line 2"></v-text-field>
                            <v-text-field v-model="formData.stepTwo.addressLine3"
                                          label="Address Line 3"></v-text-field>
                            <v-text-field v-model="formData.stepTwo.townCity"
                                          :error-messages="townCityErrors"
                                          @input="$v.formData.stepTwo.townCity.$touch()"
                                          @blur="$v.formData.stepTwo.townCity.$touch()"
                                          label="Town/City"
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="formData.stepTwo.county"
                                          label="County"></v-text-field>
                            <v-text-field v-model="formData.stepTwo.postcode"
                                          label="Postcode"
                                          @input="$v.formData.stepTwo.postcode.$touch()"
                                          @blur="$v.formData.stepTwo.postcode.$touch()"
                                          :error-messages="postcodeErrors"
                                          required></v-text-field>
                            <v-select :items="labels.registerLabels.countryLabels"
                                      item-text="name"
                                      item-value="code"
                                      @input="$v.formData.stepTwo.country.$touch()"
                                      @blur="$v.formData.stepTwo.country.$touch()"
                                      v-model="formData.stepTwo.country"
                                      :error-messages="countryErrors"
                                      label="Country"
                                      required
                                      clearable></v-select>
                        </v-col>
                    </v-row>
                    <v-row class="px-2 mx-2">
                        <v-btn fab
                               small
                               color="secondary"
                               @click="step--"
                               class="ma-1"><v-icon>mdi-chevron-left</v-icon></v-btn>
                        <v-spacer></v-spacer>

                        <v-btn fab
                               small
                               color="secondary"
                               @click="validateStep"
                               class="ma-1"><v-icon>mdi-chevron-right</v-icon></v-btn>
                    </v-row>
                </v-stepper-content>
                <v-stepper-content step="3">
  					<div style="height:40px;"></div>
                    <p style="color: grey; fontSize: 0.85em">
                        Tell us exactly what you are looking for. Please
                        indicate the geographical area and the size of business
                        you are seeking by checking the appropriate boxes. Feel
                        free to make multiple selections or check the boxes for
                        'Any Location','Any Turnover' and 'Any Size' if you are
                        searching for a broad range of opportunities.
                    </p>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-select :items="labels.registerLabels.locationLabels"
                                      item-text="description"
                                      item-value="locationId"
                                      v-model="formData.stepThree.locations"
                                      label="Location"
                                      multiple
                                      chips
                                      :error-messages="locationErrors"
                                      required>
                                <template v-slot:prepend-item>
                                    <v-list-item @click="anyLocation">
                                        <v-list-item-action>
                                            <v-icon>
                                                {{
												allLocationsSelected
													? "mdi-close-box"
													: "mdi-checkbox-blank-outline"
                                                }}
                                            </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>Any Location</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                            </v-select>
                        </v-col> 
                        <v-col cols="12" md="6">
                            <v-select :items="labels.registerLabels.employeeFields"
                                      item-text="description"
                                      item-value="employeesId"
                                      v-model="formData.stepThree.employees"
                                      label="Employees"
                                      multiple
                                      chips
                                      :error-messages="employeeErrors"
                                      required>
                                <template v-slot:prepend-item>
                                    <v-list-item @click="anyEmployee">
                                        <v-list-item-action>
                                            <v-icon>
                                                {{
												allEmployeesSelected
													? "mdi-close-box"
													: "mdi-checkbox-blank-outline"
                                                }}
                                            </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Any
                                                Employees
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select :items="labels.registerLabels.turnoverLabels"
                                      item-text="description"
                                      item-value="turnoverId"
                                      v-model="formData.stepThree.turnovers"
                                      label="Turnover"
                                      multiple
                                      chips
                                      :error-messages="turnoverErrors"
                                      required>
                                <template v-slot:prepend-item>
                                    <v-list-item @click="anyTurnover">
                                        <v-list-item-action>
                                            <v-icon>
                                                {{
												allTurnoversSelected
													? "mdi-close-box"
													: "mdi-checkbox-blank-outline"
                                                }}
                                            </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>Any Turnover</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row class="px-2 mx-2">
                        <v-btn fab
                               small
                               color="secondary"
                               @click="step--"
                               class="ma-1"
                               v-on="on"><v-icon>mdi-chevron-left</v-icon></v-btn>
                        <v-spacer></v-spacer>
                        <v-btn fab
                               small
                               color="secondary"
                               @click="validateStep"
                               class="ma-1"
                               v-on="on"><v-icon>mdi-chevron-right</v-icon></v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary"
                               text
                               tile
                               :disabled="$v.formData.stepThree.$invalid"
                               @click="step = 7">Skip To End</v-btn>
                    </v-row>
                </v-stepper-content>
                <v-stepper-content step="4">
  					<div style="height:40px;"></div>
                        <p style="color: grey; fontSize: 0.85em">
                            Use this facility to narrow your search criteria to business categories you are particularly interested in. Click on a category heading to expand the choice of categories.
                        </p>
                    <businessCategoryPicker :showSelected="true"
                                            :showSearch="true"
                                            :radio="false"
                                            :existing="[]"
                                            v-model="formData.stepFour.categories" />
                    <v-row class="px-2 mx-2">
                        <v-btn fab
                               small
                               color="secondary"
                               @click="step--"
                               class="ma-1"><v-icon>mdi-chevron-left</v-icon></v-btn>
                        <v-spacer></v-spacer>

                        <v-btn fab
                               small
                               color="secondary"
                               @click="validateStep"
                               class="ma-1"><v-icon>mdi-chevron-right</v-icon></v-btn>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="5">
						<div style="height:40px;"></div>
                    <v-textarea hint="You can add a keyword search to drill down into the outline description of a business provided by the seller. Simply list words that represent significant features that you might be interested in. Examples could be: warehouse biotech retail textiles software"
                                filled
                                label="Keywords"
                                v-model="formData.stepFive.keywords" :maxlength="TextCriteriaLimit"></v-textarea>
                    <v-row class="px-2 mx-2">
                        <v-btn fab
                               small
                               color="secondary"
                               @click="step--"
                               class="ma-1"><v-icon>mdi-chevron-left</v-icon></v-btn>
                        <v-spacer></v-spacer>

                        <v-btn fab
                               small
                               color="secondary"
                               @click="validateStep"
                               class="ma-1"><v-icon>mdi-chevron-right</v-icon></v-btn>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="6">
  					<div style="height:40px;"></div>
                    <p style="color: grey; fontSize: 0.85em">
                        You can search for particular assets for sale, regardless of business categories.
                    </p>
                    <v-select :items="labels.registerLabels.assetLabels"
                              item-text="description"
                              item-value="assetId"
                              v-model="formData.stepSix.assets"
                              label="Key Assets"
                              multiple
                              chips
                              required
                              clearable></v-select>
                    <v-row class="px-2 mx-2">
                        <v-btn fab
                               small
                               color="secondary"
                               @click="step--"
                               class="ma-1"><v-icon>mdi-chevron-left</v-icon></v-btn>
                        <v-spacer></v-spacer>

                        <v-btn fab
                               small
                               color="secondary"
                               @click="validateStep"
                               class="ma-1"><v-icon>mdi-chevron-right</v-icon></v-btn>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="7">
  					<div style="height:40px;"></div>
                    <v-row>
                        <v-col cols="12" md="6" class="py-1">
                            <v-text-field v-model="formData.stepSeven.password"
                                          label="Password"
                                          :error-messages="passwordErrors"
                                          password
                                          @input="$v.formData.stepSeven.password.$touch()"
                                          @blur="$v.formData.stepSeven.confirmPassword.$touch()"
                                          :type="showPass ? 'text' : 'password'"
                                          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                          @click:append="showPass = !showPass"
                                          required></v-text-field>
                            <v-text-field v-model="formData.stepSeven.confirmPassword"
                                          label="Confirm Password"
                                          :error-messages="confirmPasswordErrors"
                                          password
                                          :type="showConfirmPass ? 'text' : 'password'"
                                          :append-icon="
							showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'
						"
                                          @click:append="showConfirmPass = !showConfirmPass"
                                          required></v-text-field>
                        </v-col>
                        <p style="color: grey; fontSize: 0.85em; padding-left: 12px">
                            By registering, you agree with the
                            <router-link :to="{ name: 'TCs' }">terms and conditions</router-link>, and
                            <router-link :to="{ name: 'PrivacyPolicy' }">privacy policy</router-link>.
                        </p>
                    </v-row>
                    <v-row class="px-2 mx-2">
                        <v-btn fab
                               small
                               color="secondary"
                               @click="step--"
                               class="ma-1"
                               v-on="on"><v-icon>mdi-chevron-left</v-icon></v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile
                               color="secondary"
                               @click.prevent="submit"
                               :loading="loading">submit</v-btn>
                    </v-row>
                    <p v-if="errorMessage !== null">{{ errorMessage }}</p>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </div>
</template>

<script>
    import { validationMixin } from "vuelidate";
    import {
        required,
        email,
        minLength,
        sameAs,
        maxLength
    } from "vuelidate/lib/validators";
    import { mapState } from "vuex";
    import businessCategoryPicker from "@/components/businessCategoryPicker.vue";

    //Form Validation Helpers
    const createRequiredErrors = (field, fieldString) => {
        const errors = [];
        if (!field.$dirty) return errors;
        !field.required && errors.push(fieldString + " is required");
        return errors;
    };

    const containsDigit = value => /\d/.test(value);
    const containsUppercase = value => /[A-Z]/.test(value);
    const containsLowercase = value => /[a-z]/.test(value);

    export default {
        name: "RegisterBuyer",
        metaInfo() {
            return {
                title: "IP-BID.com - Interested Party Registration"
            };
        },
        components: {
            businessCategoryPicker
        },
        mixins: [validationMixin],
        data() {
            return {
                //Meta
                showPass: false,
                showConfirmPass: false,
                step: 1,
                steps: 7,
                successMessage: null,
                errorMessage: null,
                loading: false,
                TextCriteriaLimit: 1000,

                formData: {
                    stepOne: {
                        titleID: null,
                        forenames: null,
                        surname: null,
                        email: null,
                        secondaryEmail: null,
                        company: null,
                        functionID: null,
                        functionOther: null,
                        position: null,
                        telephone: null,
                        mobile: null
                    },
                    stepTwo: {
                        addressLine1: null,
                        addressLine2: null,
                        addressLine3: null,
                        county: null,
                        townCity: null,
                        country: null,
                        postcode: null
                    },
                    stepThree: {
                        locations: [],
                        turnovers: [],
                        employees: []
                    },
                    stepFour: {
                        categories: []
                    },
                    stepFive: {
                        keywords: null
                    },
                    stepSix: {
                        assets: null
                    },
                    stepSeven: {
                        password: null,
                        confirmPassword: null
                    }
                }
            };
        },
        created() {
            this.$store.dispatch("retrieveRegisterLabels", true);
        },
        validations: {
            formData: {
                stepOne: {
                    titleID: { required },
                    surname: { required },
                    forenames: { required },
                    email: { email, required },
                    secondaryEmail: { email },
                    company: { required },
                    position: { required },
                    telephone: { required },
                    functionID: { required }
                },
                stepTwo: {
                    postcode: { required, maxLength: maxLength(10) },
                    townCity: { required },
                    country: { required },
                    addressLine1: { required }
                },
                stepThree: {
                    locations: { required },
                    turnovers: { required },
                    employees: { required }
                },
                stepSeven: {
                    password: {
                        required,
                        minLength: minLength(7),
                        containsDigit,
                        containsLowercase,
                        containsUppercase
                    },
                    confirmPassword: {
                        required,
                        minLength: minLength(7),
                        containsDigit,
                        containsLowercase,
                        containsUppercase,
                        sameAsPassword: sameAs("password")
                    }
                }
            }
        },
        methods: {
            async submit() {
                this.errorMessage = null;

                if (!this.$v.$invalid) {
                    this.loading = true;

                    if (this.formData.stepOne.secondaryEmail === "") {
                        this.formData.stepOne.secondaryEmail = null;
                    }

                    await this.$store
                        .dispatch("registerBuyer", this.formData)
                        .then(response => {
                            response;
                            this.$router.push({
                                name: "RegistrationSuccess"
                            });
                        })
                        .catch(e => {
                            this.errorMessage = e.response.data.response;
                        });
                    this.loading = false;
                } else {
                    this.$v.formData.$touch();
                }
            },
            validateStep() {
                var step = parseInt(this.step);
                const evalStep = section => {
                    if (!section.$invalid) {
                        this.step++;
                    } else {
                        section.$touch();
                    }
                };
                switch (step) {
                    case 1:
                        evalStep(this.$v.formData.stepOne);
                        break;
                    case 2:
                        evalStep(this.$v.formData.stepTwo);
                        break;
                    case 3:
                        evalStep(this.$v.formData.stepThree);
                        break;
                    case 4:
                        this.step++;
                        break;
                    case 5:
                        this.step++;
                        break;
                    case 6:
                        this.step++;
                        break;
                }
            },
            updateCategories(categories) {
                this.formData.stepFour.categories = categories;
            },
            //methods for selecting any field
            anyLocation() {
                if (!this.allLocationsSelected) {
                    this.formData.stepThree.locations = this.labels.registerLabels.locationLabels.map(
                        x => x.locationId
                    );
                } else {
                    this.formData.stepThree.locations = [];
                }
            },
            anyTurnover() {
                if (!this.allTurnoversSelected) {
                    this.formData.stepThree.turnovers = this.labels.registerLabels.turnoverLabels.map(
                        x => x.turnoverId
                    );
                } else {
                    this.formData.stepThree.turnovers = [];
                }
            },
            anyEmployee() {
                if (!this.allEmployeesSelected) {
                    this.formData.stepThree.employees = this.labels.registerLabels.employeeFields.map(
                        x => x.employeesId
                    );
                } else {
                    this.formData.stepThree.employees = [];
                }
            }
        },
        computed: {
            ...mapState(["labels"]),

            allLocationsSelected() {
                return (
                    this.formData.stepThree.locations.length ===
                    this.labels.registerLabels.locationLabels.length
                );
            },
            allTurnoversSelected() {
                return (
                    this.formData.stepThree.turnovers.length ===
                    this.labels.registerLabels.turnoverLabels.length
                );
            },
            allEmployeesSelected() {
                return (
                    this.formData.stepThree.employees.length ===
                    this.labels.registerLabels.employeeFields.length
                );
            },

            //Step One Errors
            titleErrors() {
                return createRequiredErrors(
                    this.$v.formData.stepOne.titleID,
                    "Title"
                );
            },
            nameErrors() {
                return createRequiredErrors(
                    this.$v.formData.stepOne.surname,
                    "Surname"
                );
            },
            forenameErrors() {
                return createRequiredErrors(
                    this.$v.formData.stepOne.forenames,
                    "Forename"
                );
            },
            emailErrors() {
                const errors = [];
                if (!this.$v.formData.stepOne.email.$dirty) return errors;
                !this.$v.formData.stepOne.email.required &&
                    errors.push("Please Enter an Email");
                !this.$v.formData.stepOne.email.email &&
                    errors.push("Email should be valid");
                return errors;
            },
            secondEmailErrors() {
                const errors = [];
                if (!this.$v.formData.stepOne.secondaryEmail.$dirty) return errors;
                !this.$v.formData.stepOne.secondaryEmail.email &&
                    errors.push("Second Email should be valid");
                return errors;
            },
            positionErrors() {
                return createRequiredErrors(
                    this.$v.formData.stepOne.position,
                    "Position"
                );
            },
            companyErrors() {
                return createRequiredErrors(
                    this.$v.formData.stepOne.company,
                    "Company Name"
                );
            },
            functionErrors() {
                return createRequiredErrors(
                    this.$v.formData.stepOne.functionID,
                    "Function"
                );
            },
            telephoneErrors() {
                return createRequiredErrors(
                    this.$v.formData.stepOne.telephone,
                    "Telephone"
                );
            },

            //Step Two Errors
            addressLine1Errors() {
                return createRequiredErrors(
                    this.$v.formData.stepTwo.addressLine1,
                    "Address Line 1"
                );
            },
            townCityErrors() {
                return createRequiredErrors(
                    this.$v.formData.stepTwo.townCity,
                    "Town/City"
                );
            },
            postcodeErrors() {
                const errors = [];
                if (!this.$v.formData.stepTwo.postcode.$dirty) return errors;
                !this.$v.formData.stepTwo.postcode.required &&
                    errors.push("Postcode is required");

                !this.$v.formData.stepTwo.postcode.maxLength &&
                    errors.push("Postcode cannot be longer than 10 characters");

                return errors;
            },
            countryErrors() {
                return createRequiredErrors(
                    this.$v.formData.stepTwo.country,
                    "Country"
                );
            },

            locationErrors() {
                return createRequiredErrors(
                    this.$v.formData.stepThree.locations,
                    "Location"
                );
            },

            employeeErrors() {
                return createRequiredErrors(
                    this.$v.formData.stepThree.employees,
                    "Employees"
                );
            },

            turnoverErrors() {
                return createRequiredErrors(
                    this.$v.formData.stepThree.turnovers,
                    "Turnover"
                );
            },

            //Step Seven Errors
            passwordErrors() {
                const errors = [];
                if (!this.$v.formData.stepSeven.password.$dirty) return errors;
                !this.$v.formData.stepSeven.password.required &&
                    errors.push("Please Enter a Password");
                !this.$v.formData.stepSeven.password.containsDigit &&
                    errors.push("Password Must Contain a Digit");
                !this.$v.formData.stepSeven.password.containsUppercase &&
                    errors.push("Password Must Contain an Uppercase Character");
                !this.$v.formData.stepSeven.password.containsLowercase &&
                    errors.push("Password Must Contain an Lowercase Character");
                !this.$v.formData.stepSeven.password.minLength &&
                    errors.push("Password must contain at least 7 characters");
                return errors;
            },
            confirmPasswordErrors() {
                const errors = [];
                if (!this.$v.formData.stepSeven.confirmPassword.$dirty)
                    return errors;
                !this.$v.formData.stepSeven.confirmPassword.required &&
                    errors.push("Please Confirm Password");
                !this.$v.formData.stepSeven.confirmPassword.sameAsPassword &&
                    errors.push("Passwords must match");
                return errors;
            }
        }
    };
</script>


<style lang="sass" scoped>
    //Reduce spacing between fields
.v-input
    padding: 4px 0 4px 0
    margin: 4px 0 4px 0

.v-stepper_header
    overflow: auto
    display: flex
    flex-wrap: no-wrap
    justify-content: left
</style>