<template>
	<div>
		<h3 :style="!triggered && 'opacity: 0'" :class="triggered && 'animated'">
			<span style="color: #1e3354">{{ text }}</span>
			<span v-show="showTick" style="color: #86ae41">&#10004;</span>
		</h3>
	</div>
</template>


<script>
export default {
	name: "savedAnimation",
	props: ["triggered", "text", "showTick"],
};
</script>

<style lang="sass" scoped>
@keyframes fadeInUp 
	from 
		transform: translate3d(0, 40px, 0)
		opacity: 0
	

	to 
		transform: translate3d(0, 0, 0)
		opacity: 1

@keyframes fadeOutUp
	from 
		opacity: 1	

	to 
		transform: translate3d(0, -40px, 0)
		opacity: 0

@-webkit-keyframes fadeOutUp1
	from 
		transform: translate3d(0, 40px, 0)
		opacity: 0
	

	to 
		transform: translate3d(0, 0, 0)
		opacity: 1
		
@-webkit-keyframes fadeOutUp1
	from 
		opacity: 1	

	to 
		transform: translate3d(0, -40px, 0)
		opacity: 0

.animated
	animation: fadeInUp 0.8s 1 ease, fadeOutUp 0.8s 2s ease
	animation-fill-mode: both, forwards

	-webkit-animation: fadeInUp 0.8s 1 ease, fadeOutUp 0.8s 2s ease
	-webkit-animation-fill-mode: both, forwards



</style>