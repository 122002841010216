<template>
	<div class="container">
		<v-btn
			tile
			small
			color="primary"
			text
			:to="{
				name: 'PostDetail'
			}"
			><v-icon>mdi-chevron-left</v-icon>to post</v-btn
		>
		<h2>Express an Interest in this Posting</h2>
		<p style="textAlign: justify">
			When you Express an Interest, IP-BID.com will send your contact
			details to the relevant Insolvency Practitioner, who will then
			consider the expressions of interest he has received.
		</p>
		<v-row style="background: white">
			<v-col cols="12" md="7" order="1" order-md="2">
				<v-textarea
					:loading="setupLoad"
					filled
					color="accent"
					label="My Profile"
					rows="3"
					hint="It can help to speed up the process if the Insolvency Practitioner knows a little more about you and your particular areas of interest. Use the text box above if you would like to include a brief profile of yourself and/or your company"
					persistent-hint
					:error-messages="
						this.expressionText === ''
							? 'Message is required'
							: null
					"
					v-model="expressionText"
				></v-textarea>
				<v-btn
					tile
					color="secondary"
					@click="sendInterest"
					:loading="loading"
					v-show="!successMessage"
					:disabled="triggerSaveText || setupLoad"
					>Send Interest</v-btn
				>
				<savedAnimation
					:text="successMessage"
					:showTick="true"
					:triggered="triggerSaveText"
				/>
				<p v-if="errorMessage !== null">{{ errorMessage }}</p>
			</v-col>
			<v-col cols="12" md="5" order="2" order-md="1">
				<v-list-item>
					<strong>Post Overview</strong>
				</v-list-item>
				<v-divider class="my-1 py-1"></v-divider>
				<v-list-item>
					<strong>Activity Heading</strong><v-spacer></v-spacer
					>{{ loadedPost.activityHeading }}
				</v-list-item>

				<v-list-item>
					<strong>Appointment</strong><v-spacer></v-spacer
					>{{ loadedPost.businessStatus }}
				</v-list-item>
				<v-list-item>
					<strong>Trading</strong><v-spacer></v-spacer
					>{{ loadedPost.tradingStatus }}
				</v-list-item>
				<v-list-item
					v-for="(locationGroup, index) in chunkedLocations"
					:key="index"
				>
					<strong v-if="index === 0">Location(s)</strong
					><v-spacer></v-spacer
					><v-chip
						v-for="location in locationGroup"
						:key="location.locationId"
						class="mx-1"
						>{{ location.description }}</v-chip
					>
				</v-list-item>
				<v-list-item>
					<strong>Turnover</strong><v-spacer></v-spacer
					><v-chip>{{ loadedPost.turnover }}</v-chip>
				</v-list-item>
				<v-list-item>
					<strong>Employees</strong><v-spacer></v-spacer
					><v-chip>{{ loadedPost.employee }}</v-chip>
				</v-list-item>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import savedAnimation from "@/components/savedAnimation.vue";
import Repository from "@/store/Services/Repository.js";
import { mapGetters } from "vuex";

//Helper(s)
const chunkArray = (array, size) => {
	let result = [];
	for (let i = 0; i < array.length; i += size) {
		let chunk = array.slice(i, i + size);
		result.push(chunk);
	}
	return result;
};

export default {
	name: "ExpressInterest",
	components: {
		savedAnimation
	},
	props: ["postID"],
	data() {
		return {
			expressionText: "",
			setupLoad: false,
			loading: false,
			successMessage: null,
			errorMessage: null,
			triggerSaveText: false
		};
	},
	async created() {
		this.setupLoad = true;
		await Repository.get("buyer/expression-text", {
			headers: { Authorization: `Bearer ${this.token}` }
		}).then(response => {
			this.expressionText = response.data;
		});

		if (
			this.loadedPost === null ||
			this.loadedPost.businessId !== this.$route.params.id
		) {
			await this.$store.dispatch(
				"retrievePostByID",
				this.$route.params.id
			);
		}
		this.setupLoad = false;
	},
	computed: {
		...mapGetters(["loadedPost", "token"]),
		chunkedLocations() {
			return chunkArray(this.loadedPost.locations, 4);
		}
	},
	methods: {
		async sendInterest() {
			this.loading = true;

			const payload = {
				expressionText: this.expressionText,
				businessId:
					this.postID == null ? this.$route.params.id : this.postID
			};

			await this.$store
				.dispatch("expressInterest", payload)
				.then(response => {
					this.successMessage = response.response;
					this.triggerSaveText = true;
				})
				.catch(e => (this.errorMessage = e));

			this.loading = false;

			await new Promise(resolve => setTimeout(resolve, 2800)); //Length of Animation
			this.triggerSaveText = false;
		}
	}
};
</script>

<style lang="sass" scoped>
.detail
	border: 1px solid black
	padding: 10px
	margin: 20px 15% 20px 15%
	text-align: center
</style>
