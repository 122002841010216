<template>
	<div>
		<v-card class="mx-auto" outlined>
			<v-list-item three-line>
				<v-list-item-content>
					<v-list-item-title class="headline mb-1"
						>Interested Party</v-list-item-title
					>
					<v-list-item-subtitle
						>Specify your criteria and find business
						opportunities</v-list-item-subtitle
					>
				</v-list-item-content>

				<v-list-item-avatar tile size="50">
					<img
						src="@/assets/icons/buyers_expressed interest cropped.svg"
						alt="Interested Party"
					/>
				</v-list-item-avatar>
			</v-list-item>

			<v-card-actions>
				<v-btn small color="info" :to="{ name: 'RegisterBuyer' }"
					>Register</v-btn
				>
			</v-card-actions>
		</v-card>
	</div>
</template>


<script>
export default {
	name: "registerBuyerCard"
};
</script>