import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
	directives: {
		Ripple
	}
})

export default new Vuetify({
	theme: {
		options: {
			customProperties: true
		},
		themes: {
			light: {
				primary: "#1e3354",
				secondary: "#0083a6",
				accent: "#eeba36",
				error: "#C65C5C",
				info: "#86ae41",
				success: "#A1C53A",
				warning: "#C65C5C",
				other: '#f54e14'
			}
		}
	}
});