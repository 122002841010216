<template>
	<div class="container">
		<v-row>
			<v-col cols="12" md="6">
				{{ successMessage }}
				{{ errorMessage }}
				<v-btn v-show="successMessage" tile color="secondary" :to="{ name: 'Login' }"
					>Go to Login</v-btn
				>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: "ConfirmEmail",
	data() {
		return {
			successMessage: null,
			errorMessage: null
		};
	},
	mounted() {
		const payload = {
			userId: this.$route.query.userId,
			token: this.$route.query.token
		};

		this.$store
			.dispatch("confirmEmail", payload)
			.then(response => {
				this.successMessage = response.response;
			})
			.catch(e => {
				this.errorMessage = e;
			});
	}
};
</script>