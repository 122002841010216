var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('h2',[_vm._v("Live Postings")]),_c('postCardList',{attrs:{"posts":_vm.posts,"seller":true,"loading":_vm.loading,"showSort":true,"defaultSort":"Start Date","sortByOptions":[
			'End Date',
			'Start Date',
			'Created Date',
			'Reference',
			'Heading',
			'Turnover'
		]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }