<template>
	<div class="container" style="textAlign: justify">
		<h2>Terms and Conditions</h2>
		<p>
			IP-BID.com – The UK’s Online Insolvency Marketplace – aims to match
			active buyers and qualified sellers of distressed businesses and
			assets in a dedicated online marketplace. These Terms and Conditions
			govern your use of the IP-BID website (‘IP-BID’) located at
			www.IP-BID.com and its services, including the Posting of business
			opportunities (‘Postings’). The submission of information to IP-BID
			is also covered by our Privacy Policy. By registering with IP-BID
			(as a ‘User’) you are agreeing to abide by the Terms and Conditions
			set out herein. If you object to any aspects of these Terms and
			Conditions, you should not use the website, nor any of the services
			or information offered by IP-BID.
		</p>
		<ol>
			<li>
				An essential component of the IP-BID service is the notification
				by email of information such as new Postings and expressions of
				interest in Postings. By registering with IP-BID, Users
				explicitly agree to receive such email notifications, plus
				occasional newsletters containing information about IP-BID and
				its services, always according to the terms of our Privacy
				Policy.
			</li>
			<li>
				The information set out within IP-BID and its services is
				provided in good faith. Users of IP-BID submitting information,
				including Postings, warrant that such information is accurate to
				the best of their knowledge and IP-BID reserves the absolute
				right to remove information it believes is inaccurate or has
				been submitted in bad faith.
			</li>
			<li>
				In particular, those submitting Postings accept that they do so
				entirely at their own risk and warrant that: they have the
				right, power and authority to submit the Posting; the Posting is
				not false, inaccurate, misleading; nor does it contain
				fraudulent information; the Posting is submitted in good faith,
				is not in any way illegal or defamatory, nor is it an
				infringement of any other party's rights.
			</li>
			<li>
				IP-BID (including affiliates, officers, directors, agents and
				employees) shall not be liable for any loss or damage including
				expenses or costs suffered by Users submitting information or
				any third party whether arising from the acts or omissions of
				IP-BID or any party made in connection with the information.
			</li>
			<li>
				Nothing published on the website or otherwise provided by IP-BID
				constitutes an offer and cannot be construed as a recommendation
				by IP-BID, which is not authorised to sell investment products.
				Parties considering information provided via IP-BID should
				always undertake their own due diligence before entering into
				any agreement.
			</li>
			<li>
				For the avoidance of doubt, in no circumstances shall IP-BID be
				liable for any economic losses (including, without limitation,
				loss of revenues, profits, contracts, business or anticipated
				savings), any loss of goodwill or reputation, or any special,
				indirect or consequential damages (however arising, including
				negligence).
			</li>
			<li>
				To the extent permissible by law IP-BID excludes all implied
				warranties, conditions or other terms, whether implied by statue
				or otherwise.
			</li>
			<li>
				Users shall promptly notify IP-BID of any actual, threatened or
				suspected claim made by a third party or parties against the
				User and/or IP-BID in relation to information submitted. IP-BID
				reserves the right, following a claim or threatened claim, to
				immediately remove such information. Any reinstatement of the
				information shall be at the sole discretion of IP-BID.
			</li>
			<li>
				The User acknowledges that IP-BID may re-use Postings or other
				information submitted to IP-BID, subject to the provisions of
				our Privacy Policy, and hereby assigns to IP-BID all rights,
				including but not limited to, copyright in all Postings, and
				warrants that any such activity by IP-BID does not and will not
				infringe any legal right of the User or any third party.
			</li>
			<li>
				The User accepts that the purpose of IP-BID is to disseminate
				information to as many active buyers as possible and that the
				information contained in the Postings published by IP-BID may be
				used by third parties after Posting for any purpose. In such
				instance, IP-BID accepts no liability whatsoever.
			</li>
			<li>
				By confirming a Posting on IP-BID, the User agrees to pay the
				appropriate fees as specified on the site at the time of Posting
				and detailed in an invoice, according to the payment terms
				specified on the invoice.
			</li>
			<li>
				The User accepts that charges for Postings may be amended from
				time to time and will be payable at the rate in force at the
				time of invoicing unless otherwise agreed by IP-BID. As Users
				will not be invoiced until the termination of a Posting the
				charges must be paid by the User within 14 days of receipt of
				invoice unless other requirements of IP-BID (as determined from
				time to time) are notified to the User.
			</li>
			<li>
				Users should ensure that any information supplied is correct
				before proceeding to payment. IP-BID does not accept
				responsibility or offer refunds if you provide incorrect
				information. IP-BID cannot be held responsible for any errors
				made by your entering incorrect information.
			</li>
			<li>
				Although our systems are programmed to deliver information in a
				timely manner, IP-BID will not be held liable for technical
				failures preventing this from occurring. If such an incident
				arises, we will make all reasonable efforts to ensure the
				appropriate information is delivered as quickly as possible.
			</li>
			<li>
				The IP-BID website may contain hyperlinks to websites operated
				by other parties. We do not control such websites and we take no
				responsibility for, and will not incur any liability in respect
				of, their content. Our inclusion of hyperlinks to such websites
				does not imply any endorsement of views, statements or
				information contained in such websites.
			</li>
			<li>
				A primary purpose of IP-BID is to match sellers and potential
				buyers of distressed businesses and assets. Users of IP-BID
				registering as potential buyers warrant that when they express
				an interest in a Posting, it is solely for the purpose of making
				a good faith assessment of the opportunity posted with a view to
				possible acquisition from the selling User. Any other use of
				expressions of interest is prohibited and may lead to suspension
				or termination of a User account. In any case, IP-BID reserves
				the right to withdraw access to IP-BID services at any time for
				any reason and to suspend or terminate a User account as it sees
				fit.
			</li>
			<li>
				If a User wishes to make a complaint, then please use the
				Contact Us facility.
			</li>
			<li>
				A person who is not a party to these Terms and Conditions has no
				right under the Contracts (Rights of Third Parties) Act 1999 to
				enforce any term of these Terms and Conditions but this does not
				affect any right or remedy of a third party specified in these
				Terms and Conditions or which exists or is available apart from
				that Act.
			</li>
			<li>
				These Terms and Conditions and all other express terms of the
				contract shall be governed and construed in accordance with the
				Laws of England and the parties hereby submit to the exclusive
				jurisdiction of the English courts.
			</li>
			<li>
				If any provision of these Terms and Conditions is held to be
				invalid or unenforceable, such provision shall be struck out and
				the remaining provisions shall remain in force.
			</li>
			<li>
				Your statutory rights are not affected.
			</li>
		</ol>
		<p>
			If you have any questions or comments on these Terms and Conditions,
			please Contact Us.
		</p>
	</div>
</template>


<script>
export default {
	name: "TCs",
	metaInfo() {
		return {
			title: "IP-BID.com - Terms and Conditions"
		};
	}
};
</script>