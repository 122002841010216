<template>
	<div class="container">
		<h2>Amend My Preferences</h2>
		<preferenceNavItems :active="'Assets'" />
		<v-row
			style="background: white; borderRadius: 3px"
			class="my-2 mx-1 pa-4"
		>
			<v-select
				:items="assetLabels"
				item-text="description"
				item-value="assetId"
				label="Assets"
				multiple
				v-model="selectedAssets"
				chips
				clearable
			></v-select>
		</v-row>
		<v-btn
			tile
			:disabled="triggerSaveText"
			color="secondary"
			:loading="loading"
			@click.prevent="saveChanges"
			>Save Changes</v-btn
		>
		<savedAnimation
			:text="successMessage"
			:showTick="true"
			:triggered="triggerSaveText"
		/>
		<p v-if="errorMessage !== null">{{ errorMessage }}</p>
	</div>
</template>

<script>
import savedAnimation from "@/components/savedAnimation.vue";
import preferenceNavItems from "@/components/Buyer Components/preferenceNavItems.vue";
import Repository from "@/store/Services/Repository.js";
import { mapGetters } from "vuex";

export default {
	name: "AmendAssetPreferences",
	data() {
		return {
			selectedAssets: [],
			apiError: null,
			assetLabels: {},
			successMessage: null,
			errorMessage: null,
			loading: false,
			triggerSaveText: false
		};
	},
	components: {
		preferenceNavItems,
		savedAnimation
	},
	async created() {
		await Repository.get("label/assets")
			.then(response => (this.assetLabels = response.data.assetLabels))
			.catch(e => e);

		Repository.get("buyer/asset-preferences", {
			headers: { Authorization: `Bearer ${this.token}` }
		})
			.then(response => {
				this.selectedAssets = response.data;
			})
			.catch(e => {
				this.apiError = e;
			});
	},
	computed: {
		...mapGetters(["token"])
	},
	methods: {
		async saveChanges() {
			this.loading = true;

			await Repository.put(
				"buyer/asset-preferences",
				{ KeyAssets: this.selectedAssets },
				{
					headers: { Authorization: `Bearer ${this.token}` }
				}
			)
				.then(response => {
					this.successMessage = response.data.response;
					this.triggerSaveText = true;
				})
				.catch(e => {
					this.errorMessage = e.response.data.response;
				});

			this.loading = false;

			await new Promise(resolve => setTimeout(resolve, 2800)); //Length of Animation
			this.triggerSaveText = false;
		}
	}
};
</script>