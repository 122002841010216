<template>
	<div class="ma-1 pa-2">
		<h4>{{ parentName }}</h4>
		<a @click="selectAll" v-if="!radio">Select All</a>
		<span v-if="!radio">
			<v-checkbox
				v-for="category in childCategories"
				:key="category.categoryId"
				class="my-0 py-0"
				:disabled="exclude === category.categoryId"
				color="accent shrink mr-2"
				v-model="selectedChildren"
				:label="category.description"
				:value="category.categoryId"
				v-on="listeners"
				v-bind="$attrs"
				style=""
				small
			></v-checkbox>
		</span>

		<v-radio-group v-model="radioSelected" v-if="radio" v-bind="$attrs">
			<div v-for="category in childCategories" :key="category.categoryId">
				<v-radio
					color="accent"
					:label="category.description"
					:value="category.categoryId"
					v-on="radioListeners"
				></v-radio>
			</div>
		</v-radio-group>
	</div>
</template>

<script>
export default {
	name: "childCategory",
	inheritAttrs: false,
	props: [
		"childCategories",
		"preSelected",
		"parentName",
		"radio",
		"exclude",
		"excludeList"
	],
	data() {
		return {
			selectedChildren: [],
			radioSelected: null
		};
	},
	created() {
		this.selectedChildren = this.preSelected;
	},
	methods: {
		selectAll() {
			let categoriesToAppend;
			if (this.exclude !== null) {
				categoriesToAppend = this.childCategories
					.filter(
						category =>
							!this.selectedChildren.includes(
								category.categoryId
							) && category.categoryId !== this.exclude
					)
					.map(category => category.categoryId);
			} else {
				categoriesToAppend = this.childCategories
					.filter(
						category =>
							!this.selectedChildren.includes(category.categoryId)
					)
					.map(category => category.categoryId);
			}

			this.selectedChildren.push(...categoriesToAppend);
			this.$emit("input", this.selectedChildren);
		}
	},
	computed: {
		//To Enable Binding With V-Model
		listeners() {
			return {
				...this.$listeners,
				input: this.$emit("input", this.selectedChildren)
			};
		},
		radioListeners() {
			return {
				...this.$listeners,
				input: this.$emit("input", this.radioSelected)
			};
		}
	}
};
</script>
