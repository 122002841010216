var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":_vm.cardWidth,"elevation":hover ? 6 : 0,"tile":"","outlined":""}},[_c('v-list-item',{staticStyle:{"background":"#1e3354"},attrs:{"two-line":""}},[_c('v-list-item-avatar',{attrs:{"tile":"","size":"50"}},[_c('img',{attrs:{"src":require("@/assets/icons/widget.svg"),"alt":"Post Widget"}})]),_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"color":"white"}},[_c('strong',[_vm._v(_vm._s(_vm.post.activityHeading))])]),_c('v-list-item-subtitle',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.post.tradingStatus))])],1)],1),_c('v-divider'),_c('v-list-item',[_vm._v(" Category"),_c('v-spacer'),(_vm.post.primaryCategory.length > 0)?_c('v-chip',{staticClass:"ma-2",attrs:{"small":""}},[_vm._v(_vm._s(_vm.post.primaryCategory[0].description))]):_vm._e()],1),_c('v-divider'),_c('v-list-item',[_vm._v(" Turnover"),_c('v-spacer'),_c('v-chip',{staticClass:"ma-2",attrs:{"small":""}},[_vm._v(_vm._s(_vm.post.turnover))])],1),_c('v-divider'),_c('v-list-item',[_vm._v(" Listed"),_c('v-spacer'),_c('v-chip',{staticClass:"ma-2",attrs:{"small":""}},[_vm._v(_vm._s(_vm._f("formatDate")(_vm.post.activeStartDate[0])))])],1),_c('v-divider'),_c('v-card-actions',[_c('v-flex',[_c('v-btn-toggle',{staticClass:"d-flex",attrs:{"tile":""}},[_c('v-btn',{attrs:{"width":"50%","to":{
									name: 'PostDetail',
									params: { id: _vm.post.businessId }
								},"small":"","color":"secondary"}},[_vm._v("View Detail")]),_c('v-btn',{attrs:{"width":"50%","disabled":_vm.post.hasExpressedInterest,"to":{
									name: 'ExpressInterest',
									params: { id: _vm.post.businessId }
								},"small":"","color":"info"}},[_vm._v("Express Interest")])],1)],1)],1),(!_vm.isLoggedIn)?_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"grey"}},[_c('v-btn',{attrs:{"tile":"","to":{ name: 'Login', query: { name: 'PostDetail', id: _vm.post.businessId } }}},[_vm._v("Login To View")])],1):_vm._e()],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }